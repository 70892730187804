import React, {useEffect, useRef, useStatem, useState, useContext} from 'react';
import 'antd/dist/antd.css';
import {Table, Popconfirm, Button, Form, Input, Tag} from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import {useHistory, useLocation} from "react-router-dom";
import {authService} from "../../middleware/fb";
import axios from "axios";
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import LoadingSpin from "../layout/LoadingSpin";
import BannerUpload from "./BannerUpload";
import ThumbUpload from "../program/ThumbUpload";
import BannerUploadMobile from "./BannerUploadMobile";
const EditableContext = React.createContext(null);

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props}/>);

const _type = 'banner';     // 배너 타입
const _cate = 'main';       // 이달의 책 배너(book)    <-->    메인 배너(main)
const _title = '메인 배너용';      // DB title에 들어가는 데이터 인식용 문구
const _ratio = 1380/450;           // 리사이즈 이미지 비율
const _ratioM = 375/450;            // 모바일 배너용 비율

// 현 시점 예제용 DB정보(Boards.info-해당하는 데이터중 최신값)
// [{"age": 33, "key": "1", "name": "John Brown1", "index": 0, "address": "New York No. 1 Lake Park"}, {"age": 32, "key": "2", "name": "John Brown2", "index": 1, "address": "New York No. 1 Lake Park"}, {"age": 32, "key": "3", "name": "John Brown3", "index": 2, "address": "New York No. 1 Lake Park"}]

const MainBanner = (props) => {
    const {userObj} = props;
    const [dataSource, setDataSource] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [bannerId, setBannerId] = useState(null);     // 해당 배너 id값
    const location = useLocation();

    // 업로드된 썸네일 url 가져오는거.
    const [thumbUrlData, setThumbUrlData] = useState(null);
    const getThumbData = (media) => {
        setThumbUrlData(media);
        // console.log(media);
    }
    const [dataLoad, setDataLoad] = useState(false);
    const [dataLoadM, setDataLoadM] = useState(false);
    const setDataLoadFn = (bool) => {
        setDataLoad(bool);
    }
    const setDataLoadMFn = (bool) => {
        setDataLoadM(bool);
    }

    const EditableCell = ({
                              title,
                              editable,
                              children,
                              dataIndex,
                              record,
                              handleSave,
                              ...restProps
                          }) => {
        let childNode = children;

        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        // console.log('form-',form);

        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);
        if (!!dataSource) {

            // 만약 editable이 아니면 면저 빠져나가도록
            if (!editable) {
                return <td {...restProps}>{childNode}</td>;
            }

            const toggleEdit = () => {
                // console.log('test-',record, ['link'+record.index]);
                setEditing(!editing);
                form.setFieldsValue({[dataIndex]: record.link});
            };

            const save = async () => {
                try {
                    // console.log('testSave-',form.getFieldsValue([dataIndex]).link);
                    const values = await form.validateFields();
                    const linkUrl = form.getFieldsValue([dataIndex]).link;
                    let _dataSource = [...dataSource];
                    // _dataSource[].link = linkUrl;
                    // setDataSource(_dataSource);
                    if (dataSource[record.index].link == linkUrl) {
                        console.log('변화가 업다 - skip');
                    } else {
                        _dataSource[record.index].link = linkUrl;

                        setDataSource(Object.values(_dataSource));
                        refreshToken().then((_idToken)=>{updateDataAPI(Object.values(_dataSource), _idToken).then(r => {setLoading(false)});});

                        handleSave({ ...record, ...values });
                    }
                    toggleEdit();
                } catch (errInfo) {
                    // console.log('Save failed:', errInfo);
                }
            };

            if (!!editable) {
                // console.log('test---',form);
                childNode = editing ? (
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        form={form}
                        name={dataIndex}
                        rules={[
                            {
                                required: false,
                                message: `${title} is required.`,
                            },
                        ]}
                    >
                        <Input ref={inputRef} value={!!record.index ? record.url : ''} onPressEnter={save} onBlur={save} />
                    </Form.Item>
                ) : (
                    <div
                        className="editable-cell-value-wrap"
                        style={{
                            padding: '8px 12px',
                            paddingRight: 24,
                            minHeight: 40,
                            border: '1px solid lightgray',
                            borderBox: 'box-sizing'
                        }}
                        onClick={toggleEdit}
                    >
                        {children}
                    </div>
                );
            }
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const [ imgErr, setImgErr ] = useState(false);
    // useEffect(() => {
    //     if(thumbUrlData == null){
    //         setImgErr(true);
    //     }else{
    //         setImgErr(false);
    //     }
    // }, [thumbUrlData]);

    const columns = [
        {
            title: '정렬',
            dataIndex: 'sort',
            width: '5%',
            className: 'drag-visible',
            align: 'center',
            render: () => <DragHandle />,
        },
        {
            title: '순서',
            dataIndex: 'sort',
            width: '5%',
            // className: 'drag-visible',
            align: 'center',
            render: (_, target) => target.index+1,
        },
        {
            title: '배너 이미지',
            dataIndex: 'url',
            width: '235px',
            className: '',
            align: 'center',
            render: (_, target) =>
                <>
                    <BannerUpload
                        thumbUrlData={target.url}
                        value={target.url}
                        getThumbData={getThumbData}
                        detailData={target}
                        idx={target.index}
                        onChangeImg={onChangeImg}
                        dataLoad={dataLoad}
                        setDataLoadFn={setDataLoadFn}
                        ratio={_ratio}
                    />
                </>
        },
        {
            title: '모바일 배너 이미지',
            dataIndex: 'url',
            width: '235px',
            className: '',
            align: 'center',
            render: (_, target) =>
                <>
                    <BannerUploadMobile
                        thumbUrlData={target.urlM}
                        value={target.urlM}
                        getThumbData={getThumbData}
                        detailData={target}
                        idx={target.index}
                        onChangeImg={onChangeImgM}
                        dataLoad={dataLoadM}
                        setDataLoadFn={setDataLoadMFn}
                        ratio={_ratioM}
                    />
                </>
        },
        {
            title: '링크',
            dataIndex: 'link',
            className: '',
            align: 'left',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'delete',
            width: 100,
            className: '',
            align: 'center',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm
                        placement="right"
                        className="status-popcorn"
                        title="배너를 지우시겠습니까?"
                        icon={false}
                    >
                        <a onClick={() => handleDelete(record.index)}><Tag className="status-tag" color="red">삭제</Tag></a>
                    </Popconfirm>
                ) : null,
        },
    ];

    const getDataAPI = async () => {
        axios.get(`${defaultApiAddress}/boards?type=${_type}&cate=${_cate}`)        // 배너 타입, 메인 배너
            .then(res => {
                // console.log(res.data.results[0]);
                setDataSource(res.data.results[0].info);
                setBannerId(res.data.results[0].id);
                /*setDataSource(res.data.results[0]);
                const result = res.data.results;
                const total = res.data.totalCount;
                let newData = [];

                const reverseArray = result.reverse();
                console.log(reverseArray);

                reverseArray.map((i, index) => {
                    let listData;
                    return listData = Object.assign({...i, 'listNo': index + 1}),
                        newData.push(listData)
                })
                const dataSort = newData.reverse();
                if(dataSort !== null){
                    setData(dataSort);
                }*/
                setLoading(false);
            }).catch((error)=>{
            setBannerId(null);
            if (error.response) {
                if (error.response.data.errorCode === 'NOT_EXIST_TYPE_CATEGORY') {
                    //배너용 자료가 없을 때.
                    setLoading(false);
                    setDataSource(null);
                    setBannerId(null);
                }
            }
        });
    }

    const createDataAPI = async(values, _idToken) => {
        // console.log('updateAPi-',values);
        axios.post(`${defaultApiAddress}/boards`, {title:_title,info:values,category:_cate.toUpperCase(),type:_type.toUpperCase()}, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(function (response){
                console.log('추가완료!',response);
                setBannerId(response.data.id);
            })
            .catch(function (err) {
                if(err.response){
                    console.log(err.response.data);
                }
            })
    }

    const updateDataAPI = async(values, _idToken) => {
        // console.log('updateAPi-',values);
        const _updatedId = bannerId ? '/'+bannerId : '';
        axios.put(`${defaultApiAddress}/boards`+_updatedId, {title:_title,info:values}, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(function (response){
                console.log('수정완료!',response);
            })
            .catch(function (err) {
                if(err.response){
                    console.log(err.response.data);
                }
            })
    }

    const handleDelete = (idx) => {
        setLoading(true);
        if (dataSource.length === 1) {      // 1개만 있는데 지웠다. = 다 지움
            setDataSource(null);
            refreshToken().then((_idToken)=>{updateDataAPI([], _idToken).then(r => {setLoading(false)});});
        } else {
            const _dataSource = dataSource?.filter((item) => item.index !== idx);
            if (_dataSource.length !== 0) {
                let _newIndex = 0;
                _dataSource.forEach((e)=>{
                    e.index=_newIndex;
                    _newIndex++;
                });
                setDataSource(_dataSource);
                refreshToken().then((_idToken)=>{updateDataAPI(_dataSource, _idToken).then(r => {setLoading(false)});});
            } else if (_dataSource.length === 0) {
                setLoading(false);
            }
        }
    };

    const onChangeImg = (imgUrl, idx) => {
        // console.log('imgUrl-',imgUrl,'/idx-',idx);
        if (!!imgUrl) {
            // console.log('imgUrl-',imgUrl,'/idx-',idx);
            let _dataSource = [...dataSource];
            _dataSource[idx].url = imgUrl;
            // console.log('_dataSource-',_dataSource);
            // setDataSource(_dataSource);

            if (!bannerId) {    //배너id가 없으면, 신규.
                console.log('신규모드');
                refreshToken().then((_idToken)=>{createDataAPI(_dataSource, _idToken).then(r => {setLoading(false)});});
            } else {
                refreshToken().then((_idToken)=>{updateDataAPI(_dataSource, _idToken).then(r => {setLoading(false)});});
            }
            // console.log('imgUrl-',imgUrl,'/idx-',idx);
        }
    }

    const onChangeImgM = (imgUrl, idx) => {
        // console.log('imgUrl-',imgUrl,'/idx-',idx);
        if (!!imgUrl) {
            // console.log('imgUrl-',imgUrl,'/idx-',idx);
            let _dataSource = [...dataSource];
            _dataSource[idx].urlM = imgUrl;
            console.log('_dataSource-',_dataSource);
            // setDataSource(_dataSource);

            if (!bannerId) {    //배너id가 없으면, 신규.
                console.log('신규모드');
                refreshToken().then((_idToken)=>{createDataAPI(_dataSource, _idToken).then(r => {setLoading(false)});});
            } else {
                refreshToken().then((_idToken)=>{updateDataAPI(_dataSource, _idToken).then(r => {setLoading(false)});});
            }
            // console.log('imgUrl-',imgUrl,'/idx-',idx);
        }
    }

    useEffect(() => {
        getDataAPI().then(r => {console.log('배너 정보 획득');setLoading(false)});
    }, [location.pathname])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setLoading(true);
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            // console.log('Sorted items: ', newData);
            let _newIndex = 0;
            newData.forEach((e)=>{
                e.index=_newIndex;
                _newIndex++;
            });
            setDataSource(newData);

            if (!bannerId) {    //배너id가 없으면, 신규.    그런데 신규때는
                console.log('신규모드');
                refreshToken().then((_idToken)=>{createDataAPI(newData, _idToken).then(r => {setLoading(false)});});
            } else {
                refreshToken().then((_idToken)=>{updateDataAPI(newData, _idToken).then(r => {setLoading(false)});});
            }
        } else if (oldIndex === newIndex) {
            // 원래 위치
            setLoading(false);
        }
    };

    const onLinkChanged = (linkUrl, idx) => {
        // console.log('linkUrl-',linkUrl,'/idx-',idx);
    }

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource ? dataSource.findIndex(x => x.index === restProps['data-row-key']) : 0;
        const [form] = Form.useForm();
        return <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <SortableItem index={index} {...restProps} />
            </EditableContext.Provider>
        </Form>
    };

    const addBanner = () => {
        let _dataSource;
        let _index;
        if (!dataSource) {
            _dataSource = [];
            _index=0;
        } else if (dataSource.length > 0) {
            _dataSource = [...dataSource];
            _index=dataSource.length;
        } else {
            _dataSource = [];
            _index=0;
        }
        // console.log('length',dataSource.length, dataSource);
        _dataSource.push({
            index: _index,
            url: null,
            link: '',
        });
        // console.log(_dataSource);
        setDataSource(_dataSource);
    }

    const _columns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: ()=>{onLinkChanged(record.link, record.index)},   // linkUrl = save 통해서 받아온 새 url
            }),
        };
    });

    return (
        <>
            { loading && <LoadingSpin /> }
            <div align='right'>
                <Button size='large' onClick={addBanner}>배너 추가</Button>
            </div>
            <Table
                pagination={false}
                dataSource={dataSource}
                columns={_columns}
                rowKey="index"
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                        cell: EditableCell,
                    },
                }}
            />
        </>
    );
}

export default MainBanner;
