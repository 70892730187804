import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import { Input, Form, Select, Radio, Row, Col, Spin, Alert } from 'antd';
import { authService } from "../../middleware/fb";
import StatusTable from "./UserStatusTable";

const EditableContext = React.createContext(null);
const { Search } = Input;
const { Option } = Select;

export default function ProgramStatus(props) {
    const {userObj} = props;
    const history = useHistory();
    const [ page, setPage ] = useState(0);
    // const [ totalCnt, setTotalCnt ] = useState(0);
    const [ data, setData ] = useState(null);
    const [ totalData, setTotalData] = useState(null);
    const [ reLoad, setReLoad ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ loadingSpin, setLoadingSpin ] = useState(false);        // 로딩화면
    const [ radioValue, setRadioValue ] = useState('ALL');

    const [ search , setSearch ] = useState(null);
    const onSearch = search => {
        console.log(search);
        setSearch(search);
    };

    const apiAllNoneSearch = `${defaultApiAddress}/users`;
    const apiAllSearch = `${defaultApiAddress}/users?search=${search}`;

    // console.log(userObj)
    let ignore = false;
    let roleChk = false;

    // const getUserAll = async(address, _idToken) => {
    //     axios.get(address, {headers: {
    //             "Access-Control-Allow-Origin" : "*",
    //             "Content-Type" : "application/json",
    //             "Authorization" : _idToken,
    //         }})
    //         .then((res) => {
    //             if(!ignore){
    //                 const resultData = res?.data?.results;
    //                 setTotalData(resultData);
    //                 setData(resultData);
    //                 // console.log(res.data.results);
    //             }
    //         });
    // }

    const getUserAll = async(_idToken) => {
        setLoadingSpin(true);
        let address;

        if (search === null){
            address = apiAllNoneSearch;
        } else{
            address = apiAllSearch;
        }
        console.log('test-',address);

        const instance = await axios.create({
            baseURL: address,
            headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 5000,
        });
        const response = await instance.get(
            ''
        ).then((res) => {
            setLoadingSpin(false);
            setLoading(false);
            const resultData = res?.data?.results;
            setTotalData(resultData);
            // console.log(res.data.results);
            radioFilter(radioValue, resultData);
        }).catch((error)=>{
            if (error.response) {
                if (error.response.data.errorCode === 'NOT_FOUND_USER_PERMISSION_ON_ROLES') {
                    //유저 권한이 없을때
                    return (
                        history.push('/'),
                        authService.signOut()
                    )
                }
            }
        });
    }
    const getUserAllFn = (_idToken)=>{
        getUserAll(_idToken);
    }

    const roleCheck = async(_idToken) => {
        let userId = userObj?.uid;

        axios.get(`${defaultApiAddress}/users/${userId}/roles`, {headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-Type" : "application/json",
                "Authorization" : _idToken,
            }})
            .then(res => {
                console.log('사용자 권한 : '+ res.data.length);
                if(res.data.length !== 2){
                    return (
                        history.push('/'),
                        authService.signOut()
                    )
                }
            });
    }

    useEffect(() =>{
        if(userObj != null){
            refreshToken().then(async (_idToken)=>{
                getUserAll(_idToken).then(r => (console.log('출력-1')));

                if (roleChk == false) {
                    roleCheck(_idToken).then(r => {roleChk=true});
                }
            });
        }
      return () => {ignore = true;}
    }, [search])

    // const [ radioValue, setRadioValue ] = useState('ALL');
    const onRadioChange = e => {
      console.log(e.target.value)
      setRadioValue(e.target.value);
      radioFilter(e.target.value, totalData);
      // radioFilter(e.target.value);
    };

    const radioFilter = (value, resultData) => {
      console.log(value);
      if( value === 'ALL') {
        return setData(resultData);
      }
      const filteredEvents = resultData.filter(
        ({ role }) => role === value
      );
      console.log(filteredEvents)
      setData(
        filteredEvents
      );
      console.log(data);
    };

    // const searchUser = (value) => {
    //   if(value != null){
    //       refreshToken().then((_idToken)=>{
    //           axios.get(`${defaultApiAddress}/users?search=${value}`, {headers: {
    //                   "Authorization" : _idToken,
    //                   "Content-Type" : "application/json",
    //                   "Access-Control-Allow-Origin" : "*",
    //               }})
    //               .then((res) => {
    //                   setData(res?.data?.results);
    //                   console.log(res.data.results);
    //                   setLoading(false);
    //               });
    //       });
    //   }
    // };

    const [ roleChg, setRoleChg ] = useState(false);
    const setRoleChange = ({value, roleChangeUid}) => {
      console.log(value, roleChangeUid)
      putUserRole({value, roleChangeUid})
    };

    const setYmChange = ({value, roleChangeUid}) => {
      console.log(value, roleChangeUid)
      putUserYm({value, roleChangeUid})
    }

    const putUserYm = async({value, roleChangeUid}) => {
      if( value, roleChangeUid != null){
        let data ;
        data = JSON.stringify({ym: value});
        console.log(data);
          await refreshToken().then((_idToken)=>{
              axios.put(`${defaultApiAddress}/users/${roleChangeUid}`, data, {headers: {
                      "Authorization" : _idToken,
                      "Content-Type" : "application/json",
                      "Access-Control-Allow-Origin" : "*",
                  }})
                  .then((res) => {
                      const resultData = res?.data;
                      console.log('수정완료!', resultData);
                      setRoleChg(true);
                      // setReLoad(true);
                      success();
                      getUserAll(_idToken).then(r => {});
                  });
          });
        }
      };

    const putUserRole = async({value, roleChangeUid}) => {
      if( value, roleChangeUid != null){
        let data ;
       if(value === 'ADMIN'){
          data = JSON.stringify([{id: "USER"},{id: value}]);
        }else{
          data = JSON.stringify([{id: value}]);
        }
        console.log(data);
          await refreshToken().then((_idToken)=>{
              axios.put(`${defaultApiAddress}/users/${roleChangeUid}/roles`, data, {headers: {
                      "Authorization" : _idToken,
                      "Content-Type" : "application/json",
                      "Access-Control-Allow-Origin" : "*",
                  }})
                  .then((res) => {
                      const resultData = res?.data;
                      console.log('수정완료!', resultData);
                      setRoleChg(true);
                      // setReLoad(true);
                      success();
                      getUserAll(_idToken).then(r => {});
                  });
          });
        }
      };

      const success = () => {
        setTimeout(() => {
          setRoleChg(false);
          setReLoad(true);
        },[4000])
          return (
            <Alert
                className="alert-result-true"
                message="수정 완료"
                description="회원 정보가 정상적으로 수정되었습니다."
                type="success"
                showIcon
                closable={true}
                />
            )
      };


    return(
     <>
      { data == null ?
         <Spin size="large" tip="로딩중..." delay="300"/>
      :
      <>
      { roleChg ? success() : null }
      <div className="table-board">
       <Row className="table-board-row">
            <Col span={10} style={{padding:'1em 1em 0'}}>
              <Form.Item
                label="회원 권한 선택ㅤ"
                className="table-board-form"
                >
                <Radio.Group
                  onChange={onRadioChange}
                  defaultValue='ALL'
                  value={radioValue}
                >
                  <Radio value={'ALL'}>전체</Radio>
                  <Radio value={'USER'}>사용자</Radio>
                  <Radio value={'ADMIN'}>관리자</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={14} className="searchBoxWrap">
              <Search
                className="searchBox"
                placeholder="사용자의 이름 또는 이메일을 입력하세요."
                allowClear
                loading={loading}
                onSearch={onSearch}
                defaultValue={search}
                />
            </Col>
          </Row>
          </div>
      <StatusTable
          userData={data}
          setRoleChange={setRoleChange}
          setYmChange={setYmChange}
          loadingSpin={loadingSpin}
          // totalCnt={totalCnt}
          getUserAllFn={getUserAllFn}
      />
      </>
      }
    </>
    )
};
