import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom'
import { Space, Button, Typography, Row, Col, Modal, Form, Divider, List, Spin, Skeleton } from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined, MessageOutlined, LikeOutlined, StarOutlined, EyeOutlined } from '@ant-design/icons';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import moment from 'moment';
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";

const { Title, Text } = Typography;

export default function WithoutSkeleton(props) {
    const { userObj, history, cate, type, bbsId, detailData, isLoading } = props;
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [localToken, setLocalToken] = useState(null);

    const [ prevLink, setprevLink] = useState({
        link:null,
        title:null,
    });

    const [ nextLink, setnextLink] = useState({
        link:null,
        title:null,
    });

    const curpathpoint = location.pathname.indexOf('/detail/');
    const curpath = location.pathname.slice(0, curpathpoint + 8);

    const prevFunction = () => {
        if(detailData.nearRow[0] !== null){
            setprevLink({
                link:curpath + detailData.nearRow[0].id,
                title:detailData.nearRow[0].title
            })
            }
        if(detailData.nearRow[0] === null){
            setprevLink({
                link:null,
                title:'이전 게시물이 존재하지 않습니다.'
            })
        }
    }


    const nextFunction = () => {
        if(detailData.nearRow[1] !== null){
            setnextLink({
               link:curpath + detailData.nearRow[1].id,
               title:detailData.nearRow[1].title
           })
       }else{
           setnextLink({
               link:null,
               title:'다음 게시물이 존재하지 않습니다.'
           })
       }
    }

    const showModal = () => {
      setIsModalVisible(true);
    };

    const boardDeleteConfirm = () => {
        axios.delete(defaultApiAddress+`/boards/${bbsId}`, {headers: {
                "Authorization" : localToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(() => {
                console.log('board deleted');
            });
        alert('글이 삭제되었습니다!');
        history.push(`../${type}/${cate}/`);
    };

    const boardDeleteCancled = () => {
        setIsModalVisible(false);
    };


    const updateBoard = () => {
        history.push(`/${type}/${cate}/write/${bbsId}`);
    };

    useEffect(() => {
        prevFunction()
        nextFunction()
        return () => null;
        },
    [detailData.nearRow],
    );

    useEffect(() => {
        refreshToken().then((_idToken)=>{setLocalToken(_idToken)});
    },[]);

    const onHandleBack = () => {
        history.push(`/${type}/${cate}/`);
    };

    const IconText = ({ icon, text }) => (
        <Space>
          {React.createElement(icon)}
          {text}
        </Space>
      );

    return(
    <>
    <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
        <Row className="detailMainInfoWrap">
            <Col span={2}>
                <Button onClick={onHandleBack} type="text">
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
            </Col>
            <Col span={2} offset={18}>
                <Button onClick={updateBoard} className="editBt" type="text">수정하기</Button>
            </Col>
            <Col span={2} offset={0}>
                <Button onClick={showModal} danger type="text">삭제하기</Button>
            </Col>
            <Col span={24} style={{margin:"50px 0 25px", padding:"0 20px"}}>
                <Title className="titleText">
                    { detailData.title.slice(0, 100) }
                </Title>
                <Text type="secondary" className="secondaryText">
                     <Space>
                       { detailData.writer }
                       { moment(detailData?.createdAt).format('YY/MM/DD') }
                     </Space>  
                </Text>
                <Divider className="titleDivider" />
                
            </Col>
        </Row>
        <Row align="middle" justify="center" style={{padding:"0 24px 24px"}} >
            {detailData ? (
                <>
                    <ReactQuill
                        value={detailData.description}
                        readOnly={true}
                        theme={"bubble"}
                    />
                    <Col span={24} style={{margin:"25px 0"}}>
                        { detailData.body }
                    </Col>
                </>
            ) : (
                <>
                        <div>로딩중...</div>
                </>
            )}
            {/*아래가 게시글 내용 보여주는 방식*/}
        </Row>
        <Row align="middle" justify="center" className="detailFooterWrap">
            <Divider className="titleDivider" />
            <div className="detailIconWrap">
                <Space>
                    <IconText icon={EyeOutlined} text={detailData.readcount ? detailData.readcount : '0'} key="list-vertical-star-o" />
                </Space>
            </div>
            <Col span={24} style={{margin:"50px 0 25px"}}>
                <List
                    className="prevnextList"
                    itemLayout="horizontal"
                >
                    <List.Item
                    actions={[<a key="list-loadmore-edit" href={nextLink.link}><UpOutlined />ㅤ다음글</a>, <a key="list-loadmore-more" href={nextLink.link}>{nextLink.title}</a>]}
                    />
                    <List.Item
                    actions={[<a key="list-loadmore-edit" href={prevLink.link}><DownOutlined />ㅤ이전글</a>, <a key="list-loadmore-more" href={prevLink.link}>{prevLink.title}</a>]}
                    />
                </List>
            </Col>
        </Row>
          <Form.Item>
              <Modal title="🚫ㅤ주의ㅤ🚫" visible={isModalVisible} onOk={boardDeleteConfirm} onCancel={boardDeleteCancled}>
                  <p>게시글이 삭제됩니다.</p>
                  <p>삭제하시겠습니까?</p>
              </Modal>
          </Form.Item>
      </Row>
    </>
    );
}
