// 로그인 페이지 컴포넌트(임시)
// src/components/common/Auth.jsx
import { createBrowserHistory } from "history";
import React, { useState } from "react";
import { Router, NavLink, useHistory, Link, Route, Redirect, Switch, BrowserRouter} from "react-router-dom";
import { authService, firebaseInstance } from "../../middleware/fb";
import axios from "axios";
import { defaultApiAddress } from "../../services/api.helpers"
import KakaoSignin from "./KakaoSignin";
import NaverSignin from "./NaverSignin";
import "../../assets/styles/Loginpage.css";
import { Button, Typography, Modal, Result } from "antd";
const { Text, Title } = Typography; 
const Auth = ({userObj, setUserObjFn, setErrorModalFn}) => {

    const history = useHistory();
    const [isLogined, setIsLogined] = useState(false);
    const setIsLoginedFn = function (e) {
        setIsLogined(e);
    };
    const [errorModal, setErrorModal] = useState(false);

    const onSocialClick = async (event) => {
        const {
            target: { name },
        } = event;
        let provider;
        if (name === "google") {
            provider = new firebaseInstance.auth.GoogleAuthProvider();
            await authService.signInWithPopup(provider).then(async (res)=>{
                setIsLogined(true);     // 로그인 됨
                const instance = await axios.create({
                    baseURL: defaultApiAddress,
                    headers: {
                        "Authorization" : res.user._lat,
                        "Content-Type" : "application/json",
                        "Access-Control-Allow-Origin" : "*",
                    },
                    timeout: 5000,
                });
                const response = await instance.get(
                    'users/' + res.user.uid
                ).then(async response => {
                    if (response.data.userNameReal && response.data.phoneNumber) {
                        setUserObjFn(() => {
                            let _userObj = {...userObj};
                            _userObj.userNameReal = response.data.userNameReal;
                            _userObj.phoneNumber = response.data.phoneNumber;
                            _userObj.provider = response.data.provider;
                            _userObj.uid = response.data.id;
                            _userObj.email = response.data.email;
                            _userObj.displayName = response.data.userName;
                            _userObj.photoURL = response.data.photoUrl;
                            return _userObj;
                        });
                    } else {
                        setUserObjFn(() => {
                            let _userObj = {...userObj};
                            _userObj.provider = response.data.provider;
                            _userObj.uid = response.data.id;
                            _userObj.email = response.data.email;
                            _userObj.displayName = response.data.userName;
                            _userObj.photoURL = response.data.photoUrl;
                            return _userObj;
                        });
                    }
                }).catch(async (error) => {
                    setErrorModalFn(true);
                });
            });
        } else if (name === "facebook") {
            provider = new firebaseInstance.auth.FacebookAuthProvider();
            await authService.signInWithPopup(provider).then(async (res)=>{
                setIsLogined(true);     // 로그인 됨
                const instance = await axios.create({
                    baseURL: defaultApiAddress,
                    headers: {
                        "Authorization" : res.user._lat,
                        "Content-Type" : "application/json",
                        "Access-Control-Allow-Origin" : "*",
                    },
                    timeout: 5000,
                });
                const response = await instance.get(
                    'users/' + res.user.uid
                ).then(async response => {
                    if (response.data.userNameReal && response.data.phoneNumber) {
                        setUserObjFn(() => {
                            let _userObj = {...userObj};
                            _userObj.userNameReal = response.data.userNameReal;
                            _userObj.phoneNumber = response.data.phoneNumber;
                            _userObj.provider = response.data.provider;
                            _userObj.uid = response.data.id;
                            _userObj.email = response.data.email;
                            _userObj.displayName = response.data.userName;
                            _userObj.photoURL = response.data.photoUrl;
                            return _userObj;
                        });
                    } else {
                        setUserObjFn(() => {
                            let _userObj = {...userObj};
                            _userObj.provider = response.data.provider;
                            _userObj.uid = response.data.id;
                            _userObj.email = response.data.email;
                            _userObj.displayName = response.data.userName;
                            _userObj.photoURL = response.data.photoUrl;
                            return _userObj;
                        });
                    }
                }).catch(async (error) => {
                    setErrorModalFn(true);
                });
            });
        }
    };
    const logout = () => {
        setErrorModal(false);
        setUserObjFn(null);
        authService.signOut();
    };
    const goBack = (e) => {
        e.preventDefault();
        history.goBack()
    };
    const userInfoUpdate = async (e) => {
        e.preventDefault();
        let _userNameReal = document.getElementById("userNameReal").value;
        let _phoneNumber = document.getElementById("phoneNumber").value;
        // console.log(_userNameReal);
        const instance = await axios.create({
            baseURL: defaultApiAddress,
            // baseURL: 'http://localhost:8081/',
            headers: {
                "Authorization" : userObj.idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 10000,
        });
        try {
            const response = await instance.put(
                'users/' + userObj.uid,
                {'userNameReal' : _userNameReal, 'phoneNumber' : _phoneNumber}
            ).then(response => {
                setUserObjFn((userObj) => {
                    let _userObj = {...userObj};
                    _userObj.userNameReal = _userNameReal;      // 유저 진짜 이름 없을때
                    _userObj.phoneNumber = _phoneNumber;
                    return _userObj;
                });
                alert('이름/전화번호 등록 완료!');
            });
        } catch (error) {
            if (error.response) {
                console.log(error.response.data);
            }
        }
    };

    let signInCheck;
    if (!userObj) {
        signInCheck =<>
            {/*<AuthForm />*/}
            <div className='logcontainer'>
                <div className='loginTabBox'>
                    <Title level={4} style={{paddingTop:50}}>관리자 로그인</Title>
                    <div type="card" style={{margin:'5vh auto'}} >
                        <div className='loginTab' key="1" disabled>
                            <div className='btnBox'>
                                <NaverSignin userObj={userObj} setIsLoginedFn={setIsLoginedFn} setUserObjFn={setUserObjFn} setErrorModalFn={setErrorModalFn}/>
                                <button className='before googleBtn' onClick={onSocialClick} name="google" data-mode="login">구글 간편 로그인</button>
                                <KakaoSignin userObj={userObj} setIsLoginedFn={setIsLoginedFn} setUserObjFn={setUserObjFn} setErrorModalFn={setErrorModalFn} isLogin='login'/>
                                {/*<button className='before facebBtn' onClick={onSocialClick} name="facebook" data-mode="login">페이스북 간편 로그인</button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    } else  {
        signInCheck =<></>;
    }
    return (
        <div>
            {signInCheck}
        </div>
    );
};

export default Auth;
