import React, { useState, useEffect }  from 'react';
import axios from "axios";
import { Input, Modal, Button, Typography, Form, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";

const { Field, useForm } = Form;
const { Text, Title } = Typography;

const UserMemo = (props) =>{
    const { rowRecord, getNewData, memovalue } = props;
    const [ editLimitModal, setEditLimitModal ] = useState(false);
    const [ editLoading, setEditLoading] = useState(false);

    const [form]= useForm();

    useEffect(() => {
        form.setFieldsValue(rowRecord);
    }, [rowRecord])


const onEditLimit = () => {
    form.resetFields();
    setEditLimitModal(true);
    };

const editLimitCancel = () => {
    form.resetFields();
    setEditLimitModal(false);
};

const onEditSubmit = (value) => {
    console.log(value);
    setEditLoading(true);
    refreshToken().then((_idToken)=>{submitEvent(value, _idToken)});
  }
const submitEvent = (value, _idToken) => {
    console.log(value)
    return axios.put(`${defaultApiAddress}/courseEntry/${rowRecord?.id}`, value, {headers: {
            "Authorization" : _idToken,
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        }})
        .then(res => {
            setEditLoading(false);
            console.log(res);
            editLimitCancel();
            getNewData(res);
        })
        .catch(err => {
            console.error(err);
            setEditLoading(false);
            editLimitCancel();
        });
    };
  
  const EditFinishFaild = (err) => {
      console.log(err)
  };

  const iconToggle = () => {
      if(memovalue == null || memovalue == ""){
          return <><span>메모 작성</span> <EditOutlined /></>
      } else {
        return memovalue?.length > 30 ? <span>{ memovalue.slice(0, 30) }...</span> : memovalue
      }
  }





    return (
        <>
        <a className="memoText" onClick={onEditLimit}>
            { iconToggle() }
        </a>
        <Modal
            visible={editLimitModal}
            footer={null}    
        >
        <Title level={3} style={{textAlign: 'center', marginBottom:25}}>메모 작성</Title>
            <Form
              name="memoForm"
              form={form}
              className="memoForm"
              onFinish={onEditSubmit}
              onFinishFailed={EditFinishFaild}
              initialValues={rowRecord}
              >
                <Row style={{margin: "25px auto", minWidth: "200px"}}>
                    <Col className="memoModalCol" span={24} style={{paddingBottom:'15px'}}> 
                        <Form.Item
                            name="memo"
                            >
                            <Input.TextArea
                                className="memo-textarea"
                                
                                // value={state.userLimitOff}
                            />
                        </Form.Item>
                    </Col>
                    <Form.Item style={{margin:'20px auto 0', textAlign:'center'}}>
                        <Button className="applyModalBtn" key="back" onClick={editLimitCancel}>
                        취ㅤ소
                        </Button>
                        <Button 
                            className="applyModalBtn" 
                            key="submit" 
                            htmlType="submit" 
                            style={{backgroundColor:"#FFD560", marginLeft:20}}
                            type="primary" loading={editLoading}>
                        저ㅤ장
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Modal>
        </>
    )
};

export default UserMemo;
