import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { defaultApiAddress } from "../../services/api.helpers";
import { atom, selector, useRecoilState } from 'recoil';
import axios from "axios";
import moment from 'moment';
import LoadingSpin from "../layout/LoadingSpin";
import { Button, Space, Table, Tag, Alert, message } from "antd";

export default function ProgramList(props) {

  const history = useHistory();
  const [ locationState, setLocationState ] = useState(null);
  const [ data, setData ] = useState(null);
    const [ page, setPage ] = useState(1);
    const [ totalCnt, setTotalCnt ] = useState(0);

    const columns = [
        {
            title: '번호',
            width:'7.5%',
            dataIndex:'listNo',
            align: 'center',
            key:'listNo',
            render: number => ((parseInt(totalCnt/12) > page-1) ? totalCnt - 12*(page) + number : number),
        },
        {
            title: '분류',
            dataIndex:'category',
            width:'12.5%',
            align: 'center',
            key:'category',
            render: cate => {
                switch(cate) {
                    case 'LIST01': return '미술.서예'; break;
                    case 'LIST02': return '악기·음악'; break;
                    case 'LIST03': return '댄스·무용'; break;
                    case 'LIST04': return '생활교양'; break;
                    case 'LIST05': return '어린이'; break;
                    case 'LIST06': return '야간(삭제됨)'; break;
                }
            },
        },
        {
            title: '제목',
            dataIndex:'title',
            width:'55%',
            key:'title',
            render: text => text.length > 20 ? <a style={{color: '#000000'}}>{text.slice(0,30)}...</a> : text,
        },
        {
            title: '상태',
            dataIndex:'state',
            width:'10%',
            align: 'center',
            render: i => {
                if(i === 'OPEN'){
                    return <Tag color="green">접수중</Tag>
                }else if(i === "CLOSE"){
                    return <Tag color="volcano">접수마감</Tag>
                }
            },
            key:'state',
        },
        {
            title: '작성일시',
            dataIndex:'createdAt',
            width:"15%",
            align: 'center',
            render: item => moment(item).format('YYYY/MM/DD'),
            key:'createdAt',
        },
    ];

  const success = () => {
    setTimeout(() => {
      setLocationState(null);
    },[4000])
      return (
        <Alert
            className="alert-result-true"
            message="등록 완료"
            description="강의가 정상적으로 등록되었습니다."
            type="success"
            showIcon
            closable={true}
            />
        )
  };

  const successDelete = () => {
    setTimeout(() => {
      setLocationState(null);
    },[4000])
      return (
        <Alert
            className="alert-result-true"
            message="삭제 완료"
            description="강의가 정상적으로 삭제되었습니다."
            type="success"
            showIcon
            closable={true}
            />
        )
  };

    async function getDataAPI() {
        const result = await axios.get(`${defaultApiAddress}/courses?showlist=true&rpp=12&page=${page}`);     // showlist query = 연산 부분 제외한 순수 리스트만 출력(210609 by Sarc)

        setData(result?.data?.results);
        setTotalCnt(result?.data?.totalCount);

        const resultdata = result?.data?.results;
        let newData = [];

        const reverseArray = resultdata.reverse();
        // console.log(reverseArray);

        reverseArray.map((i, index) => {
            let listData;
            return listData = Object.assign({...i, 'listNo': index + 1}),
                newData.push(listData)
        })
        const dataSort = newData.reverse();

        if(dataSort !== null){
            setData(dataSort);
        }
    }

    const onChange = (current) => {
        // let crr = (current-1);
        let crr = current;
        return (
            setPage(crr)
        );
    }

  useEffect(() => {

    getDataAPI();

    setLocationState(history?.location?.state);
    return () => {}
  }, [page]);


  const onRow = (rowData, rowIndex) => {
      return {
          onClick: () => {
              history.push({
                pathname: `/program/detail/${rowData.id}`,
              });
          }
      }
  }

  const onClickWrite = () => {
    history.push("/program/write/");
  };

      return (
        <>
        { data == null ? <LoadingSpin /> :
          <div style={{margin:"0 auto", maxWidth:"100%"}}>
            { locationState?.update ? success() : locationState?.delete ? successDelete() : null }
                  <div className="List" style={{ maxWidth:"1200px", margin:"24px auto 0", padding:"0 24px 24px"}}>
                    <Space align="end" size="small" direction="vertical">
                        <Button onClick={onClickWrite}>강의추가</Button>
                              <Table
                                  tableLayout="fixed"
                                  expandRowByClick={true}
                                  columns={columns}
                                  dataSource={data}
                                  onRow={onRow}
                                  page={page}
                                  pagination={{
                                      defaultCurrent:1,
                                      current: page,
                                    pageSize:12,
                                      total: totalCnt,
                                      onChange: onChange,
                                    size:'small',
                                      showSizeChanger: false,
                                      showQuickJumper: false
                                }}
                              />
                      </Space>
                  </div>
          </div>
        }
        </>
          );
      };
