import React from 'react';
import { Spin } from 'antd';

const LoadingSpin = () => {

return (
	<div style={{ position:"fixed", top:0, right:0, left:0, bottom:0, backgroundColor:'rgba(255, 255, 255, 0.7)', zIndex:1000, overflow:'hidden' }}>
        <Spin size="large" tip="로딩중..." delay="300"/>
    </div>
	)
};

export default LoadingSpin;