import React, { createElement, useEffect, useState } from 'react';
import { Comment, Tooltip, Avatar, Form, Button, List, Input, Col, Row, Modal, Empty, Result } from 'antd';
import moment from 'moment';
import axios from "axios";
import { DeleteTwoTone, setTwoToneColor } from '@ant-design/icons';
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import { useRecoilState } from "recoil";
import { replyState, replyId } from "../../recoil-state/inquiryState";

const { TextArea } = Input;

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item >
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} 
        onClick={onSubmit}
        type="primary">
        답변 작성
      </Button>
    </Form.Item>
  </>
);

const EditEditor = ({ onChange, onSubmit, submitting, value, defaultValue }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} defaultValue={defaultValue} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} 
        onClick={onSubmit}
        type="primary">
        답변 수정
      </Button>
    </Form.Item>
  </>
);

const InquiryComment = (props) => {
    const { userObj, bbsId, history, getNewComment, status } = props;
    const [ reply, setReply] = useRecoilState(replyState);
    const [ replyidState, setreplyId ] = useRecoilState(replyId);
    const [ replyData, setreplyData ] = useState(null);
    const [ edit, setEdit] = useState(false);
    const [ state, setState ] = useState({
      comments: [],
      submitting: false,
      value: '',
      inquiryId: null,
      description:null,
    });

    const getReplApi = async(_idToken) => {
        axios.get(`${defaultApiAddress}/inquiries/${bbsId}/answers`, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(res => {
                console.log('get 결과 :'+res?.data);
                setreplyData(res?.data);
                setreplyId(res?.data?.id)
                setReply(true);
            })
    }

  const [form] = Form.useForm();

  useEffect(() => {
    console.log(replyData);
    if(status === true){
      if(edit === false){
          refreshToken().then((_idToken)=>{getReplApi(_idToken)});
      }
    }else return () => {};
    }, [status]);

  const handleSubmit = () => {
      if (!state.value) {
        return;
      }
      console.log(state.value)
    setState({
      submitting: true,
    });

    if(state?.description === null){
        setState({...state,
        inquiryId: bbsId,
        description: state.value,
      })

    }else if(edit === false){
        refreshToken().then((_idToken)=>{
            console.log('state-',state);
            axios.post(defaultApiAddress + `/inquiries/${bbsId}/answers`, {description: state.value}, {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then((response) => {
                    console.log('response-',response);
                    setreplyId(response?.data?.id);
                    getNewComment(true);
                    setState({...state,
                        submitting: false, });
                    setReply(true);
                    setreplyData(response.data);
                    setTimeout(()=>{
                        getReplApi(_idToken);
                    },1000);
                })
                .catch(err => {
                    console.log(err)
                })
        });
          }else{
            console.log(state)
        refreshToken().then((_idToken)=>{
            axios.put(defaultApiAddress + `/answers/${replyData.id}/`, {description: state.value}, {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then(res => {
                    // form.setFieldsValue(res.data.description);
                    console.log('board edited');
                    getNewComment(true);
                    setState({...state,
                        submitting: false,
                    })
                    setEdit(false);
                    setReply(true);
                    setreplyData(res.data);

                    getReplApi(_idToken);
                });
        });
            }
            setTimeout (() => {
                setState({
                  submitting: false,
                  value:replyData?.description,
                });
              }, 1000);
        };

  console.log(state)
  const updateBoard = () => {
    setEdit(true);
    setState({...state,
      submitting: false,
        })
    console.log(state)
    // setreplyData(null);
};

const handleChange = (event) => {
  // console.log(event.target.value)
  setState({...state,
  value:event.target.value,
    })
}

  // 삭제 모달
  const [ isModalVisible, setIsModalVisible ] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const boardDeleteConfirm = () => {
    console.log(replyData.id);
    if(replyData.id != null){
        refreshToken().then((_idToken)=>{
            axios.delete(`${defaultApiAddress}/answers/${replyData.id}`, {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then(() => {
                    getNewComment(false);
                    console.log('게시글 삭제');
                    setreplyData(null);
                    setEdit(false);
                });
        });
    setIsModalVisible(false);
    setState({
      submitting: false,
    });
    // setTimeout(() => {
    //   getReplApi();
    // },3000)
    }
};

    const boardDeleteCancled = () => {
      setIsModalVisible(false);
    };


    const actions = [
      <>
          <span onClick={updateBoard} className="editBt">수정</span>
          <span onClick={showModal}>삭제</span>
      </>
    ];


    return(
      <Form
        name="replyform"
        form={form}
        style={{border: '1px solid #e0e0e0', padding:"24px"}}
        onFinish={handleSubmit}
      >
        <Col span={24}>
          { replyData ?
            edit ?
            <Comment
              avatar={
                <Avatar
                  src="/images/NoneTextLogo.png"
                  alt="성북문화원"
                />
              }
              content={
                <EditEditor
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  submitting={state.submitting}
                  defaultValue={replyData ? replyData.description : ''}
                />
              }
            />
            :
             <Comment
                style={{ textAlign: 'center' }}
                actions={actions}
                author={<span style={{fontSize:'1rem', fontWeight:'bold'}}>성북문화원</span>}
                avatar={
                <Avatar
                    src="/images/NoneTextLogo.png"
                    alt="성북문화원"
                />
                }
                content={
                  <>
                    <p style={{textAlign: 'left' }}>
                      {replyData?.description}
                    </p>
                  </>
                }
                datetime={
                <Tooltip>
                    <span style={{fontSize:'0.9rem'}}>{ moment().format('YYYY/MM/DD HH:mm:ss')}</span>
                </Tooltip>
                }
            />
            : 
            <Comment
            avatar={
              <Avatar
                src="/images/NoneTextLogo.png"
                alt="성북문화원"
              />
            }
            content={
              <Editor
                onSubmit={handleSubmit}
                onChange={handleChange}
                submitting={state.submitting}
                // value={state.value}
              />
            }
          />
          }
          
        </Col>
        <Form.Item>
          <Modal 
            visible={isModalVisible} 
            onOk={boardDeleteConfirm} 
            onCancel={boardDeleteCancled}
            okText="확인"
            cancelText="취소"
            okButtonProps={{size:'large', danger:'true' }}
            cancelButtonProps={{ size: 'large' }}
            >
              <Result
              icon={<DeleteTwoTone />}
              title="해당 답변이 삭제됩니다."
              subTitle="답변을 삭제하겠습니까?" />
          </Modal>
        </Form.Item>
    </Form>
    );
}

export default InquiryComment;
