import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { defaultApiAddress } from "../../services/api.helpers";
import axios from "axios";
import StatusTable from "./ProgramStatusTable";
import { Form, Pagination, Select, Radio, Row, Col, Spin, Input } from 'antd';

const { Option } = Select;
const { Search } = Input;

export default function ProgramStatus() {
    const [ page, setPage ] = useState(1);
    const [ data, setData ] = useState(null);
    const [ totalCnt, setTotalCnt ] = useState(0);
    const [ value, setValue ] = useState('program-all');
    const [ loading, setLoading ] = useState(true);
    const [ radioValue, setRadioValue ] = useState('ALL');

    const [ search , setSearch ] = useState(null);
    const onSearch = search => {
      console.log(search);
      setSearch(search);
    };

    const basicUrl = `${defaultApiAddress}/courses?count=true&rpp=12&page=${page}`;

    const getPrograms = (query) => {
      console.log(basicUrl + query);
      axios.get(basicUrl + query)
        .then(res => {
           console.log(res?.data?.results);
           setData(res?.data?.results);
           if(res?.data?.totalCount === 0 ){
            setTotalCnt(1);
          }else{
            setTotalCnt(res?.data?.totalCount);
          }
           console.log('1번 effect');
           setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setData(null);
        })
    };

  // 강좌 전체 && 접수 상태 전체
  const getAllPrograms = () => {
    axios.get(basicUrl)
      .then(res => {
         console.log(res?.data?.results);
         setData(res?.data?.results);
         if(res?.data?.totalCount === 0 ){
          setTotalCnt(1);
        }else{
          setTotalCnt(res?.data?.totalCount);
        }
         console.log('1번 effect');
         setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setData(null);
      })
  };

  const [ limitData, setLimitData ] = useState(null);

  const getNewData = (data) => {
  let obj = Object.assign(data);
  return setLimitData(obj);
  }

  useEffect(() => {
    setLimitData(null);
    setData(null);
    setLoading(true);
        if(value === 'program-all'){
            // 프로그램 all인 상태.
            if(radioValue === 'ALL'){
              // 프로그램 all && radioValue all인 상태.
              if(search == null){
                // 프로그램 all && radioValue all이면서 검색값이 없는 상태.
                return getAllPrograms();
              }else{
                // 프로그램 all && radioValue all이면서 검색값이 있는 상태.
                return getPrograms(`&search=${search}`);
              }
            }else if(search == null){
              // 프로그램 all && radioValue all이 아닌 상태 && 검색값 없는 상태.
              return getPrograms(`&state=${radioValue}`);
            }else{
              // 프로그램 all && radioValue all이 아닌 상태 && 검색값 있는 상태.
              return getPrograms(`&state=${radioValue}&search=${search}`);
            }
        }else {
          if(radioValue === 'ALL'){
            if(search == null){
              // 프로그램 카테고리 있음 && radioValue all이면서 검색값이 없는 상태.
              return getPrograms(`&cate=${value}`);
            }else{
              // 프로그램 카테고리 있음 && radioValue all이면서 검색값이 있는 상태.
              return getPrograms(`&cate=${value}&search=${search}`);
            }
          }else if(search == null){
            // 프로그램 카테고리 있음 && radioValue all아닌 검색값이 없는 상태.
            return getPrograms(`&cate=${value}&state=${radioValue}`);
          }else{
            // 프로그램 카테고리 있음 && radioValue all아닌 검색값이 없는 상태.
            return getPrograms(`&cate=${value}&state=${radioValue}&search=${search}`);
          }
      }
  }, [ value, page, radioValue, limitData, search ]);


  useEffect(() => {
    if( page !== 1 ){
      setPage(1);
    }
  },[value, radioValue]);

    const onRadioChange = e => {
      setLoading(true);
      setRadioValue(e.target.value);
      console.log(e.target.value);
    };

    const [ selectLoading, setSelectLoading ] = useState(false);
    function selectChange(key) {
      console.log(key);
      setSelectLoading(true);
      setValue(key);
      setTimeout(() => {
        setSelectLoading(false);
      }, 800)
    }

    useEffect(() => {
      if(data !== null){
        setData(data);
      }
    }, [data])

    return(
     <>
      {/* { loading === true ?  */}
         {/* <Spin size="large" tip="로딩중..." delay="300"/> */}
      {/* :  */}
      <>
      <div className="table-board">
       <Row className="table-board-row">
            <Col span={10}>
              <Form.Item
                label="강좌 접수 상태ㅤ"
                className="table-board-form"
                >
                <Radio.Group onChange={onRadioChange} value={radioValue} defaultValue='ALL'>
                  <Radio value={'ALL'}>전체</Radio>
                  <Radio value={'OPEN'}>접수중</Radio>
                  <Radio value={'CLOSE'}>접수마감</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={14}>

            </Col>
          </Row>
          <Row className="table-board-row">
            <Col span={10}>
            <Form.Item
                label="강좌 과목 선택ㅤ"
                className="table-board-form"
                style={{ height:40}}
                >
              <Select defaultValue="program-all" onChange={selectChange} style={{ width: 200 }} value={value} loading={selectLoading}>
                  <Option value="program-all">전체</Option>
                  <Option value="LIST01">미술·서예</Option>
                  <Option value="LIST02">악기·음악</Option>
                  <Option value="LIST03">댄스·무용</Option>
                  <Option value="LIST04">생활교양</Option>
                  <Option value="LIST05">어린이</Option>
                  <Option value="LIST06">야12간</Option>
              </Select>
              </Form.Item>
            </Col>
            <Col span={14} className="searchBoxWrapProgram">
              <Search
                id="search-box"
                className="searchBox"
                placeholder="강의명 또는 강사명을 입력하세요."
                allowClear
                loading={loading}
                onSearch={onSearch}
                />
            </Col>
          </Row>
          </div>
      <StatusTable programData={data} totalCnt={totalCnt} getNewData={getNewData} page={page} loading={loading} />
        <Pagination
              defaultCurrent={page === 0 ? 1 : page }
              current={page}
              pageSize={12}
              defaultPageSize={12}
              total={totalCnt}
              onChange={(current) => setPage(current)}
              size='small'
              style={{marginTop:50}}
            />
      </>
      {/* } */}
    </>
    )
};
