import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams} from 'react-router-dom'
import axios from "axios";
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import LoadingSpin from "../layout/LoadingSpin"
import WarningModalBtn from "../layout/WarningModalBtn";
import { Button, Row, Col, Form, Input, Select, Modal, Typography } from 'antd';
import ThumbUpload from './ThumbUpload';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;


export default function GalleryWriteText(props) {
    const { userObj, type, name } = props;
    const { cate, bbsId } = useParams();
    const [ mode, setMode ] = useState({
        type: null
    });
    const [ loading, setLoading ] = useState(true);
    const history = useHistory();
    const [ detailData, setDetailData ] = useState({
        id: null,
        type: type,
        title: null,
        category:null,
        mediaUrl:null,
        fileUrl:null,
        url:null,
        createdAt:null,
        updatedAt:null,
        deletedAt:null,
        writer:null,
        depth1:null,
        depth2:null,
        info:{
            eventName: null,
            eventDate: null,
            eventLocation: null,
            eventDescription:null
            }
    });

    const [form] = Form.useForm();

    useEffect(() => {
        if (!bbsId) {
            setLoading(false);
            console.log('param 없다 - 신규 작성 모드');
            return setMode({type:'new'});
        } else {
           getDataAPI();
           console.log(`수정모드 No.${bbsId}`);
           return setMode({type:bbsId});
        }
    }, []);
    // 업로드된 썸네일 url 가져오는거.
    const [thumbUrlData, setthumbUrlData] = useState(null);
    const getThumbData = (media) => {
            setthumbUrlData(media);
            console.log(media);
        }

    const getDataAPI = () => {
        axios.get(`${defaultApiAddress}/boards/${bbsId}`)
                .then((response) => {
                    console.log(response.data);
                    response.data.info.eventName = response.data.info.eventName || null;
                    response.data.info.eventDate = response.data.info.eventDate || null;
                    response.data.info.eventLocation = response.data.info.eventLocation || null;
                    response.data.info.eventDescription = response.data.info.eventDescription || null;
                    form.setFieldsValue(response.data);
                    form.setFieldsValue(response.data.info);
                    setDetailData(response.data, response.data.info);
                })
                .catch((error) => console.log(error))
            };

  const handleGoBack = () => {
    history.push(`/${type}/${cate}/`);
  };

  const onChange = ({ target: { value } }) => {
    console.log({ value });
  };

useEffect(() => {
    bbsId&&form.setFieldsValue({
        id: detailData.id,
        type:type,
        category:cate,
        title: detailData.title,
        writer: '성북문화원',
        createdAt: detailData.createdAt,
        info:{...detailData.info}
    });
        // loading spinner setting
    if(mode.type !== 'new'){
        setTimeout(() => {
            setLoading(false);
        }, 1200);
    }
}, [detailData]);
  
    const [ imgErr, setImgErr ] = useState(false);

    useEffect(() => {
        if(thumbUrlData == null ){
            setImgErr(true);
        }else{
            setImgErr(false);
        }
    }, [thumbUrlData]);

    const onSubmit = (values) => {
    if(imgErr == false){
        
        const infoWrap = {
            eventName: values.eventName,
            eventDate: values.eventDate,
            eventLocation: values.eventLocation,
            eventDescription: values.eventDescription
        }

        values.mediaUrl = thumbUrlData;
        values.info = infoWrap;
        values.type = type;
        values.category = cate;

    if(mode.type === 'new'){
        refreshToken().then((_idToken)=>{postDataApi(values, _idToken)});
      } else if(mode.type !== 'new') {
        refreshToken().then((_idToken)=>{putDataApi(values, _idToken)});
      }
    }
  };

    const postDataApi = (values, _idToken)=>{
        axios.post(`${defaultApiAddress}/boards/`, values, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then((res) => {
                form.setFieldsValue(res.data.id);
                console.log('board created');
                console.log(values);
                history.push({
                    pathname:`/${type}/${cate}/`,
                    state: {update: true}
                });
            })
            .catch((err) => console.log(err))
    }

    const putDataApi = (values, _idToken)=>{
        axios.put(`${defaultApiAddress}/boards/${mode.type}`, values, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(function (response){
                form.setFieldsValue(response.data.id);
                console.log('board updated');
                console.log(values);
                history.push({
                    pathname:`/${type}/${cate}/`,
                    state: {update: true}
                });
            })
            .catch(function (err) {
                if(err.response){
                    console.log(err.response.data);
                }
            })
    }

  const HandleFinishFaild = (errorInfo) => {
      console.log(errorInfo);
  }

  return (
    <>
    { loading && <LoadingSpin /> }
    <Row justify="center" className="writeWrap">
        <Col span={3}>
            <Button onClick={handleGoBack} type="text">
                <ArrowLeftOutlined
                    style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                />
                <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
            </Button>
        </Col>
        <Col span={18}>
            <Text keyboard style={{fontSize:'1.2rem'}}>{name}</Text>
            <Title level={3} style={{margin:'10px auto 50px'}}>{ (mode.type === 'new') ? '새로운 행사 등록' : '기존 행사 수정' }</Title>
        </Col>
        <Col span={3}>
        </Col>
        <Form
            labelCol={{ span: 12 }}
            name="writeForm"
            form={form}
            layout="vertical"
            className="writeForm"
            onFinish={onSubmit}
            onFinishFailed={HandleFinishFaild}
            >

            <div className="mainInfo">
                <Row justify="start" className="galleryWrite">
                    <Col className="thumbCol" span={24} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item
                            label="포스터 이미지"
                            name="mediaUrl"
                            valuePropName="mediaUrl"
                            value={detailData.mediaUrl}
                            >
                            <ThumbUpload
                                mode={mode}
                                thumbUrlData={thumbUrlData}
                                value={detailData.mediaUrl} 
                                getThumbData={getThumbData}
                                detailData={detailData}
                                />
                        </Form.Item>
                        { imgErr ? <Text type="danger" className="imgErrMsg">이미지를 등록해 주세요.</Text> : null }
                    </Col>
                    <Col className="thumbCol" span={24} xs={24} sm={12} md={12} lg={16} xl={16} xxl={16}>
                        <Row gutter={[24, 13]}>
                            <Col span={24}>
                                <Form.Item
                                    label="제목"
                                    name="title"
                                    placeholder="제목을 입력해 주세요."
                                    rules={[{ required: true, message: '제목을 입력하지 않았습니다.' }]}
                                    >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="행사명"
                                    name="eventName"
                                    placeholder="행사명을 입력해 주세요."
                                    rules={[{ required: true, message: '행사명을 입력하지 않았습니다.' }]}
                                    >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="일시"
                                    name="eventDate"
                                    placeholder="일시를 입력해 주세요."
                                    rules={[{ required: true, message: '일시를 입력하지 않았습니다.' }]}
                                    >
                                    <Input />
                                    </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                label="장소"
                                name="eventLocation"
                                placeholder="장소를 입력해 주세요."
                                rules={[{ required: true, message: '장소를 입력하지 않았습니다.' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row style={{marginBottom:40}}>
                <Col span={24}>
                    <Form.Item
                        label="공연정보"
                        name="eventDescription"
                        placeholder="공연 정보를 입력해 주세요."
                        rules={[{ required: true, message: '공연 정보를 입력하지 않았습니다.' }]}
                    >
                    <TextArea
                        // value={value}
                        onChange={onChange}
                        placeholder="사이즈가 자동으로 조절됩니다."
                        autoSize={{ minRows: 1, maxRows: 7 }}
                    />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <WarningModalBtn handleGoBack={handleGoBack} />
                <Button type="primary" htmlType="submit" className="write-submit-button">
                    { (mode.type === 'new') ? '등록' : '저장' }
                </Button>
            </Form.Item>
        </Form>
      </Row>
    </>
  );
}
