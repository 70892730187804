import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams} from 'react-router-dom'
import axios from "axios";
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import WriteEditor from "../board/WriteEditor";
import LoadingSpin from "../layout/LoadingSpin";
import WarningModalBtn from "../layout/WarningModalBtn";
import { Button, Row, Col, Form, Input, Select, Checkbox, Typography, Alert } from 'antd';
import ThumbUpload from './ThumbUpload';
import DatePickerAd from './DatePickerAd';
import TimePickerAd from './TimePickerAd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Text, Title } = Typography;

export default function ProgramWrite(props) {
    const { userObj } = props;
    const { bbsId } = useParams();
    const [ mode, setMode ] = useState({
        type:null
    });
    const [ loading, setLoading ] = useState(true);
    const history = useHistory();
    const [ detailData, setDetailData ] = useState({
        id: null,
        title: null,
        mediaUrl:null,
        category:null,
        startDate:null,
        endDate:null,
        time:null,
        endTime:null,
        userLimitAll:null,
        userLimitOff:null,
        description: null,
        instructor: null,
        info: {
            day:null,
            startTime:null,
            endTime:null,
            lectureRoom: null,
            programFee:null,
            }
    });

    useEffect(() => {
        if (!bbsId) {
            setLoading(false);
            console.log('신규 작성 모드');
            return setMode({type:'new'});
        }else if(bbsId != null){
            setMode({type:bbsId});
            return getDataAPI();        // post put 시 refresh 넣어서 굳이 필요없음
        }
    }, [history.location]);



    const options = [
        { label: '월', value:'월' },
        { label: '화', value:'화' },
        { label: '수', value:'수' },
        { label: '목', value:'목' },
        { label: '금', value:'금' },
        { label: '토', value:'토' },
        { label: '일', value:'일' },
      ];

    const [form] = Form.useForm();
    const [errMsg, setErrMsg] = useState(null);


    // 업로드된 썸네일 url 가져오는거.
    const [thumbUrlData, setthumbUrlData] = useState(null);
    const getThumbData = (media) => {
            setthumbUrlData(media);
            console.log(media);
        }

    // 체크한 시간 value값 가져오는거.
    const [ timePick, setTimePick ] = useState({
        startTime: null,
        endTime: null
    });

    const getTime = (time) => {
        console.log(time)
        setTimePick({startTime:time[0], endTime:time[1]});
    }

    // 체크한 날짜 value값 가져오는거.
    const [ datePick, setDatePick ] = useState({
        startDate: null,
        endDate: null
    });

    const getDate = (date) => {
        console.log(date)
        setDatePick({startDate:date[0], endDate:date[1]});
        console.log(datePick);
    }


    const getDataAPI = () => {
        axios.get(`${defaultApiAddress}/courses/${bbsId}`)
            .then((response) => {
                console.log(response.data);
                setLoading(false);
                // date 재포장(info)
                response.data.info.lectureRoom = response.data.info.lectureRoom || null;
                response.data.info.programFee = response.data.info.programFee || null;
                response.data.info.day = response.data.info.day || null;
                form.setFieldsValue(response.data);
                form.setFieldsValue(response.data.info);
                setDetailData(response.data);
                setTimePick({
                    startTime:response?.data?.info?.startTime,
                    endTime:response?.data?.info?.endTime});
                setDatePick({
                    startDate:response?.data?.startDate,
                    endDate:response?.data?.endDate});
                    if(detailData != null){
                        switch(response.data.category){
                            case 'LIST01': return form.setFieldsValue({category:'미술.서예'}); break;
                            case 'LIST02': return form.setFieldsValue({category:'악기·음악'}); break;
                            case 'LIST03': return form.setFieldsValue({category:'댄스·무용'}); break;
                            case 'LIST04': return form.setFieldsValue({category:'생활교양'}); break;
                            case 'LIST05': return form.setFieldsValue({category:'어린이'}); break;
                            case 'LIST06': return form.setFieldsValue({category:'야간(삭제됨)'}); break;
                            }
                    }
                })
            .catch((error) => console.log(error));

        };

    function handleChange(value) {
    console.log(`Selected: ${value}`);
    }

    function onChangeCheck(e) {
        console.log(e);
      }

  const handleGoBack = () => {
    history.push('/program/');
  };

  const [desc, setDesc] = useState('');

  function onEditorChange(value) {
      setDesc(value)
  }

  const [ imgErr, setImgErr ] = useState(false);
  useEffect(() => {
    if(thumbUrlData == null){
        setImgErr(true);
    }else{
        setImgErr(false);
    }
  }, [thumbUrlData]);

  const [ timeErr, setTimeErr ] = useState(false);
  useEffect(() => {
    if(timePick.startTime == "" && timePick.endTime == ""){
        setTimeErr(true);
    }else{
        setTimeErr(false);
    }
  }, [timePick]);


  const [ dateErr, setDateErr ] = useState(false);

  useEffect(() => {
    if(datePick.startDate == "" && datePick.endDate == ""){
        setDateErr(true);
    }else{
        setDateErr(false);
    }
  }, [datePick]);

 //   POST, PUT Submit Event Start
  const onSubmit = (values) => {

    console.log(`values는 ${values}`)
    console.log(imgErr, timeErr, dateErr );
    console.log(thumbUrlData);

    if(!imgErr && !timeErr && !dateErr){
        console.log('성공')

        const infoWrap = {
          startTime: timePick.startTime,
          endTime: timePick.endTime,
          lectureRoom: values.lectureRoom,
          programFee: values.programFee,
          day:values.day
        }
          values.info = infoWrap;
          values.mediaUrl = thumbUrlData;
          values.startDate = datePick.startDate;
          values.endDate = datePick.endDate;

          switch(values.category){
            case '미술.서예': values.category = 'LIST01'; break;
            case '악기·음악': values.category = 'LIST02'; break;
            case '댄스·무용': values.category = 'LIST03'; break;
            case '생활교양': values.category = 'LIST04'; break;
            case '어린이': values.category = 'LIST05'; break;
            case '야간(삭제됨)': values.category = 'LIST06'; break;
            }

            console.log(values.category);

      if( mode.type === 'new' ) {
            console.log(values.mediaUrl);
            return refreshToken().then((_idToken)=>{postApi(values, _idToken)});
      }else if(mode.type !== 'new'){
            console.log(values.mediaUrl);
            return refreshToken().then((_idToken)=>{putApi(values, _idToken)});
      }
    }
};

  const postApi = (values, _idToken) => {
    axios.post(`${defaultApiAddress}/courses/`, values, {headers: {
            "Authorization" : _idToken,
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        }})
    .then(response => {
        form.setFieldsValue(response.data.id)
          console.log('board created');
          console.log(values);
          history.push({
              pathname:"/program/",
              state: {
                  update: true }
          });
      })
    .catch((err) => {
        console.log(err);
        setErrMsg(err.response.data.message);
    });
  }

const putApi = (values, _idToken) => {
    axios.put(`${defaultApiAddress}/courses/${mode.type}`, values, {headers: {
            "Authorization" : _idToken,
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        }})
        .then(function (response){
            form.setFieldsValue(response.data.id);
                console.log('board updated');
                console.log(values);
                history.push({
                    pathname:"/program/",
                    state: {
                        update: true,
                        id: detailData.id
                    }
                });
        })
        .catch(function (err) {
            if(err.response){
                console.log(err.response.data);
                setErrMsg(err.response.data.message);
            }
        })
}


  const HandleFinishFaild = (errorInfo) => {
      console.log(errorInfo);
  }

  return (
      <>
    { loading && <LoadingSpin /> }
    <Row justify="center" className="writeWrap">
        <Col span={3}>
            <Button onClick={handleGoBack} type="text">
                <ArrowLeftOutlined
                    style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                />
                <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
            </Button>
        </Col>
        <Col span={18}>
            <h2 className="writeTitle">{ (mode.type === 'new') ? '강의 추가' : '강의 수정' }</h2>
        </Col>
        <Col span={3}>
        </Col>
        <Form
            labelCol={{ span: 12 }}
            name="writeForm"
            form={form}
            layout="vertical"
            className="writeForm"
            onFinish={onSubmit}
            onFinishFailed={HandleFinishFaild}
            >

            <div className="mainInfo">
            <Form.Item
                label="강좌명"
                name="title"
                placeholder="강좌명을 입력해 주세요."
                rules={[{ required: true, message: '강좌명을 입력하지 않았습니다.' }]}
                >
                <Input />
            </Form.Item>
            <Row justify="start">
                <Col className="thumbCol" span={12} lg={8} xl={7} xxl={7}>
                    <Form.Item
                        label="카테고리"
                        name="category"
                        rules={[{ required: true, message: '카테고리를 선택하지 않았습니다.' }]}>
                        <Select
                            defaultValue='카테고리'
                            onChange={handleChange}
                            style={{ width: "235px", float: 'left' }}>
                            <Option value="list01">미술·서예</Option>
                            <Option value="list02">악기·음악</Option>
                            <Option value="list03">댄스·무용</Option>
                            <Option value="list04">생활교양</Option>
                            <Option value="list05">어린이</Option>
                            {/*<Option value="list06">야간(삭제됨)</Option>*/}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="썸네일 이미지"
                        name="mediaUrl"
                        valuePropName="mediaUrl"
                        value={detailData.mediaUrl}
                        >
                        <ThumbUpload
                            mode={mode}
                            thumbUrlData={thumbUrlData}
                            value={detailData.mediaUrl}
                            getThumbData={getThumbData}
                            detailData={detailData}
                            />
                    </Form.Item>
                    { imgErr ? <Text type="danger" className="imgErrMsg">이미지를 등록해 주세요.</Text> : null }
                </Col>
                <Col className="cateCol" span={12} lg={16} xl={8} xxl={8}>
                    <Form.Item
                        label="강사명"
                        name="instructor"
                        placeholder="강사명을 입력해 주세요."
                        rules={[{ required: true, message: '강사명을 입력하지 않았습니다.' }]}>
                        <Input className="shortInput" />
                    </Form.Item>
                    <Form.Item
                        label="강의실"
                        name="lectureRoom"
                        placeholder="강의실을 입력해 주세요."
                        rules={[{ required: true, message: '강의실을 입력하지 않았습니다.' }]}>
                        <Input className="shortInput" />
                    </Form.Item>
                    <Form.Item
                         label="모집인원(전체)"
                         name="userLimitAll"
                         rules={[
                             { required: true, message: '전체 모집인원을 입력하지 않았습니다.' }
                            ]}
                         >
                       <Input className="userLimitAll" placeholder="전체 모집인원을 입력해 주세요." />
                    </Form.Item>
                       <Text type="danger" style={{position: 'absolute', display: 'inline-block', maxWidth: '300px', width: '100%', bottom:'-25px', left:20}}>{ errMsg !== null ? errMsg : null }</Text>
                    <Form.Item
                         label="모집인원(오프라인)"
                         name="userLimitOff"
                         rules={[{ required: true, message: '오프라인 모집인원을 입력하지 않았습니다.' }]}
                         >
                       <Input className="userLimitOff" placeholder="오프라인 모집인원을 입력해 주세요." />
                    </Form.Item>
                </Col>
                <Col className="dateCol" span={24} xl={9} xxl={9}>
                <Form.Item
                        label="수강료"
                        name="programFee"
                        placeholder="수강료를 입력해 주세요."
                        rules={[
                            {
                                pattern: /^[0-9]/,
                                message: '숫자만 입력 가능합니다.'
                            },
                            { required: true, message: '수강료를 입력하지 않았습니다.' }
                            ]}>
                        <Input className="mediumInput" />
                    </Form.Item>
                    <Form.Item
                        label="수업일"
                        name="day"
                        placeholder="수업 일을 선택해 주세요."
                        style={{ height:'97px', marginBottom:0}}
                        rules={[{ required: true, message: '수업 일을 선택하지 않았습니다.' }]}
                        >
                        <Checkbox.Group
                        defaultValue={detailData && detailData.info && detailData.info.day}
                        // value={{...detailData.info, day}}
                        style={{width: '100%', paddingLeft: 30, marginTop: '-25px'}}
                        onChange={onChangeCheck}
                        options={options}
                        />
                    </Form.Item>
                     <Form.Item
                        label="교육시간"
                        name="time"
                        placeholder="교육시간을 입력해 주세요."
                        value={detailData?.info?.startTime}
                        // rules={[{ required: true, message: '교육시간을 입력하지 않았습니다.' }]}
                        >
                        <TimePickerAd
                            loading={loading}
                            mode={mode}
                            timePick={timePick}
                            getTime={getTime}
                            value={detailData?.info?.startTime}
                            />
                        { timeErr ? <Text type="danger" className="texErrMsg">교육 시간을 입력하지 않았습니다.</Text> : null }
                    </Form.Item>
                    <Form.Item
                         label="시작일/종료일"
                         name="dateTime"
                         value={detailData.startDate}
                        //  rules={[{ required: true, message: '시작/종료일을 선택하지 않았습니다.' }]}
                         >
                        <DatePickerAd
                            loading={loading}
                            mode={mode}
                            datePick={datePick}
                            getDate={getDate}
                            value={detailData.startDate}
                            />
                        { dateErr ? <Text type="danger" className="texErrMsg">시작/종료일을 선택하지 않았습니다.</Text> : null }
                    </Form.Item>
                </Col>
            </Row>
            </div>
            <Form.Item
                style={{marginBottom:40}}
                label="내용"
                name="description"
                placeholder="내용을 입력해 주세요."
                rules={[{ required: true, message: '내용을 입력하지 않았습니다.' }]}
                >
                <WriteEditor value={desc} onChange={onEditorChange} />
            </Form.Item>
            <Form.Item>
                <WarningModalBtn handleGoBack={handleGoBack} />
                <Button type="primary" htmlType="submit" className="write-submit-button">
                    { mode.type === 'new' ? '등록' : '저장' }
                </Button>
            </Form.Item>
        </Form>
      </Row>
    </>
  );
}
