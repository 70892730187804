import React, { useState , useEffect, useRef } from "react";
import { Route, Switch, Link, useHistory, useRouteMatch } from "react-router-dom";
import ProgramList from "../components/program/ProgramList";
import ProgramWrite from "../components/program/ProgramWrite";
import ProgramDetail from "../components/program/ProgramDetail";


export default function Program(props){
  const { userObj } = props;
      return (
          <Switch>
            <Route path="/program/detail/:bbsId">
              <ProgramDetail userObj={userObj} />
            </Route>
            <Route exact path="/program/write/">
              <ProgramWrite 
                  userObj={userObj} />
            </Route>
            <Route path="/program/write/:bbsId">
              <ProgramWrite userObj={userObj} />
            </Route>
            <Route path="/program/">
              <ProgramList />
            </Route>
            
          </Switch>
        );
  };