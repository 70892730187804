import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, Route, Switch, useParams } from "react-router-dom";
import axios from "axios";
import { Space, Button, Input, Table, Tag  } from 'antd';
import AttachList from "../components/attach/AttachList";
import AttachDetail from "../components/attach/AttachDetail";
import AttachWrite from "../components/attach/AttachWrite";


export default function Attach(props) {
const {userObj, setUserObj} = props;
const type = 'attach'
    return(
      <>
        <Switch>
            <Route exact path="/attach/:cate/write/">
                <AttachWrite userObj={userObj} type={type} />
            </Route>
            <Route path="/attach/:cate/write/:bbsId">
                <AttachWrite userObj={userObj} type={type} />
            </Route>
            <Route path="/attach/:cate/detail/:bbsId">
                <AttachDetail userObj={userObj} type={type} />
            </Route>
            <Route path="/attach/:cate/">
                <AttachList userObj={userObj} type={type} />
            </Route>
        </Switch>
      </>
    );
}
