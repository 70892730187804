import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Layout, Modal, Result, Button } from 'antd';
import "antd/dist/antd.css";
import "./assets/styles/App.css";
import HeaderComponent from "./components/layout/HeaderComponent";
import Main from "./routes/Main";
import Routes from "./routes/Routes";
import NotFound from "./routes/NotFound";
import SiderNavigation from "./components/layout/SiderNavigation";
import {authService} from "./middleware/fb";
import axios from "axios";
import {defaultApiAddress, defaultHeaders} from "./services/api.helpers";
import LoadingSpin from "./components/layout/LoadingSpin"

const { Header, Content, Footer } = Layout;

function App() {

  const [userObj, setUserObj] = useState(null);
  const setUserObjFn = function (user) {
    setUserObj(user);
  };
  const [errorModal, setErrorModal] = useState(false);
  const setErrorModalFn = (bool)=>{
    setErrorModal(bool)
  }
  const [isAdmin, setIsAdmin] = useState(false);
  let _isAdmin;
  const setIsAdminFn = (bool)=>{
    setIsAdmin(bool)
  }
  const [init, setInit] = useState(false);
  const setInitFn = (bool)=>{
    setInit(bool)
  }

  const logout = ()=>{
    setIsAdmin(false);
    setUserObj(null);
    setInit(false);
    authService.signOut();
    setErrorModal(false);
  }

  const loginUser = async ()=>{
    authService.onAuthStateChanged((user) => {
      if (user) {
        setUserObj({
          uid: user.uid,
          displayName: user.displayName,
          idToken: user._lat,
          email: user.email,
          photoURL: user.photoURL,
          updateProfile: (args) => user.updateProfile(args),
          userNameReal: '',
          phoneNumber: '',
        });
        if (!user.displayName || !user.providerData.displayName) {     // user.displayName이 있다는것은 이미 로그인/가입 했다는거니 없을때 호출
          const instance = axios.create({
            baseURL: defaultApiAddress,
            headers: defaultHeaders,
            timeout: 10000,
          });

          const response = instance.get(
              'users/' + user.uid + '?rckbp=true'
          ).then(response => {
            const _data = response.data;
            setInit(true);

            // 어드민 체크 로직
            if (!!response.data){
              let roleCheck = response.data.roles.filter(it => it.name.includes('관리자'));
              console.log('have admin role - ',(!!roleCheck.length));

              if (!roleCheck.length) {
                setErrorModal(true);
              } else if (roleCheck.length > 0) {
                setIsAdmin(true);
                _isAdmin = true;
              }
            } else if (response.data == null) {
              setErrorModal(true);
            }

            if (_data) {
              setUserObj({
                uid: user.uid || _data.id,
                // displayName: _data.userName || user.displayName,
                displayName: user.displayName,
                idToken: user._lat || userObj.idToken,
                email: user.email || _data.email,
                photoURL: user.photoURL || _data.photoUrl,
                updateProfile: (args) => user.updateProfile(args),
                userNameReal: _data.userNameReal || '',
                phoneNumber: _data.phoneNumber || '',
                provider: _data.provider
              });
            }
          }).catch(async (error) => {
            setErrorModal(true);
          });
        }

      } else {
        setUserObj(null);
      }
    });
  }

  useEffect(() => {
    loginUser().then(()=>{/*console.log('로그인 성공!')*/});
  }, []);

  const refreshToken = ()=>{   //일단 미사용(api.helpers 에 있는걸 우선사용 = props사용 최소화)
    return new Promise(async (resolve,reject) => {
      if (!!authService.currentUser) {
        authService.currentUser.getIdToken(true).then(function (idToken) {
          // setToken(idToken);
          // window.localStorage.setItem("accessToken", JSON.stringify(idToken));
          // console.log('localStorage 저장(api helper) - ', window.localStorage.getItem("accessToken"));
          resolve(idToken);
        }).catch(function (error) {
          reject("만료됨");
        });
      }
    });
  };

  return (
    <>
      <Router>
        <div className="App">
            {init || userObj ? (
                <> 
                <Layout style={{ minHeight: "100vh"}}>
                  {!!isAdmin ? <SiderNavigation /> : ''}
                  <Layout className="site-layout" style={{ marginLeft: 240, backgroundColor:"#ffffff", position:"relative" }}>
                    <HeaderComponent />
                    <Content style={{ backgroundColor:"#ffffff", width:"100%", maxWidth:'1440px', margin:"0 auto", padding: "24px 48px", position:"relative" }}>
                      <div>
                        <Route render={() => <Routes userObj={userObj} />}/>
                        <Route path="/" exact><Main userObj={userObj} setUserObjFn={setUserObjFn} setIsAdminFn={setIsAdminFn} setInitFn={setInitFn} isAdmin={isAdmin} setErrorModalFn={setErrorModalFn}/></Route>
                      </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>성북문화원 ©2021 Created by NTCON</Footer>
                  </Layout>
                  </Layout>
                </>
            ) : (
                <>
                  <Switch>
                    <Route path="/" exact><Main userObj={userObj} setUserObjFn={setUserObjFn} setIsAdminFn={setIsAdminFn} setInitFn={setInitFn} isAdmin={isAdmin} setErrorModalFn={setErrorModalFn}/></Route>
                    {init
                      ? <Route path="*" component={NotFound}/>
                      : <>{/*<LoadingSpin />*/}</>
                    }
                  </Switch>
                </>
            )}

        </div>
        </Router>
        <Modal
          visible={errorModal}
          className="applyModal"
          width="100%"
          footer={null}>
            <Result
                status="error"
                title="권한오류"
                subTitle="관리자 권한이 없습니다"
                extra={[
                  <Button className="btnApply" type="primary" key="확인" onClick={logout}>
                    확인
                  </Button>,
                ]}
            />
        </Modal>
        <script src="/node_modules/quill-image-drop-and-paste/quill-image-drop-and-paste.min.js"></script>
  </>
  );
}

export default App;
