import React, { useState, useEffect } from 'react';
import axios from "axios";
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import {useLocation} from "react-router-dom";
import { authService, firebaseInstance } from "../../middleware/fb";
import { Upload, message, Form } from 'antd';
import ImgCrop from 'antd-img-crop';

const ThumbUpload = (props) => {
  const { getThumbData, detailData, thumbUrlData} = props;

  const location = useLocation();
  const [fileList, setFileList] = useState([]);
  const [thumbUrl, setThumbUrl] = useState(null);
  const [localToken, setLocalToken] = useState(null);

  // 수정할때 fileList가 없다면 기존의 썸네일 url 가져오는 이벤트.
  useEffect(() => {
    if(detailData.mediaUrl !== null){
        if(Array.isArray(fileList) && fileList.length == 0) {
            if(thumbUrl == null){
                  setFileList([{
                  uid: '1',
                  name: 'image',
                  status: 'done',
                  url:defaultApiAddress+detailData.mediaUrl,
                }]);
                setThumbUrl(detailData.mediaUrl);
              }
          }
    }
  
  }, [detailData]);

  useEffect(() => {
      getThumbData(thumbUrl);
      refreshToken().then((_idToken)=>{setLocalToken(_idToken)});
  },[thumbUrl]);

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'image/gif';
  if (!isJpgOrPng) {
    message.error('JPG/PNG/GIF 파일만 등록할 수 있습니다.');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('이미지 파일의 크기가 2MB가 넘습니다.');
  }
  return isJpgOrPng && isLt2M;
}

const handleChange = info => {
    setFileList(info.fileList);
    let mediaUrl = info.url
  if (mediaUrl) {
    let formData = new FormData()
      formData.append('image', info.file)
      formData.append('type', 'event')
      axios.post(`${defaultApiAddress}/images/`, formData)
            .then(res => {
                console.log(res);
            })
    return;
  }
  if (info.file.status === 'done') {
      setThumbUrl(info.file.response.url)
      console.log('상태가 done 이여~')
  }
};

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  
  const config = {
    name: 'image',
    method: 'POST',
    action: `${defaultApiAddress}/images/`,
    headers: {
        "Authorization" : localToken,
        "Access-Control-Allow-Origin" : "*",
    },
    listType: 'picture-card',

  }

  return (
    <ImgCrop
      modalTitle='이미지 편집'
      grid
      aspect={3/2}
      rotate>
        <Upload
          {...config}
          fileList={fileList}
          thumbUrlData={thumbUrlData}
          onChange={handleChange}
          onPreview={onPreview}
          beforeUpload={beforeUpload}
          data={detailData.mediaUrl}
          onRemove={() => setThumbUrl(null)}
        >
        {fileList.length < 1 && '+ Upload' }
      </Upload>
    </ImgCrop>
  );
};
export default ThumbUpload;
