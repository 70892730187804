import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { defaultApiAddress, refreshToken } from '../../services/api.helpers';
import moment from "moment";
import axios from "axios";
import { Table, Popconfirm, Tag, Select, Alert } from 'antd';
import EditCountBt from './EditCountBt';
import OffUserAddBt from './OffUserAddBt';
import UserListPrint from './UserListPrint';
import UserApplyPrint from './UserApplyPrint';

const { Option } = Select;

const StatusTable = (props) => {
  const { programData, totalCnt, getNewData, loading } = props;
  const [ rowRecord, setRowRecord ] = useState(null);
  const [ offuserData, setOffuserData ] = useState(null);
  const [ changeLimit, setChangeLimit] = useState(null);
  const [ changeStatus, setChangeStatus ] = useState(null);
  const [ courseId, setCourseId ] = useState(null);
  const [ popOpen, setPopOpen ] = useState({
    display: ''
  });
  const getoffuser = (result) => {
      setOffuserData(result);
  };


  const getChangeLimit = (result) => {
      setChangeLimit(result);
  }

  const success = () => {
    setTimeout(() => {
      setOffuserData(null);
    },[4000])
      return <Alert
            className="alert-result-true"
            message="신청 완료"
            description="강의가 정상적으로 신청되었습니다."
            type="success"
            showIcon
            closable={true}
            /> 
  };

  const successLimit = () => {
    setTimeout(() => {
      setChangeLimit(null);
    },[4000])
       return <Alert
            className="alert-result-true"
            message="변경 완료"
            description="모집 인원이 정상적으로 변경되었습니다."
            type="success"
            showIcon
            closable={true}
            />
  };

  useEffect(() => {
    let ignore = false;
    const putStatus = async (_idToken) => {
      console.log(`변경할 ID값은 : ${courseId} 입니다.`);
      console.log(`변경할 상태값은 : ${changeStatus} 입니다.`);
      if(changeStatus !== null){
        let data = {};
        data.state = changeStatus;
        console.log(data);
         await axios.put(`${defaultApiAddress}/courses/${courseId}`, data, {headers: {
                 "Authorization" : _idToken,
                 "Content-Type" : "application/json",
                 "Access-Control-Allow-Origin" : "*",
             }})
      .then(res => {
       if(!ignore){
        console.log(res.data);
        getNewData(res?.data);
        success();
        setPopOpen({
          display: 'none'
        });
        setChangeStatus(null);
       }
      })
      .catch(err => {
        console.log(err);
      })
      }
    }
    refreshToken().then((_idToken)=>{putStatus(_idToken)});
    return () => { ignore = true }
  }, [changeStatus]);

  const popcornDiv = () => {
    return (
      <div className="popcorn-content">
          <a className="status-chgbt" onClick={(e) => setChangeStatus('open')}><Tag className="popcorn-bt" color="green">접수중</Tag></a>
          <a className="status-chgbt" onClick={(e) => setChangeStatus('close')}><Tag className="popcorn-bt" color="orange">접수마감</Tag></a>
      </div>
    )
  }
   const columns = [
      {
        title: '상태',
        dataIndex: '',
        width: '5%',
        align: 'center',
        render: i => {
          return(
            <Popconfirm
              placement="right"
              className="status-popcorn"
              title={popcornDiv}
              icon={false}
              overlayStyle={popOpen}
              >
                <a onClick={(e) => { 
                    setCourseId(i?.id);
                    setPopOpen({
                      display: ''
                    });
                    }}>
                  { i?.state === 'OPEN' ? 
                        <Tag color="green" className="statusTag">접수중</Tag> 
                        : i?.state === "CLOSE" ?  
                        <Tag color="orange" className="statusTag">접수마감</Tag>
                        : null
                      }
                </a>
              </Popconfirm>
          )
        },
      },
      {
        title: '강좌명',
        dataIndex: 'title',
        width: '20%',
        render: text => text.length > 20 ? text.slice(0, 20)+'...' : text,
      },
      {
        title: '강사명',
        dataIndex: 'instructor',
        width: '5%',
        align: 'center',
      },
      {
        title: '기간',
        dataIndex: '',
        align: 'center',
        width: '7%',
        render: (i) => <span>{moment(i.startDate).format('YY/MM/DD')} ~<br />{moment(i.endDate).format('YY/MM/DD')}ㅤ</span>,
      },
      {
        title:'모집 인원',
        children: [
          {
            title: '총',
            dataIndex: 'userLimitAll',
            width: '7.5%',
            align: 'center',
            render: ( text, record, index ) => <>{text}<EditCountBt rowRecord={rowRecord} getNewData={getNewData} getChangeLimit={getChangeLimit} /></>,
          },
          {
            title: '온라인',
            dataIndex: '',
            width: '5%',
            align: 'center',
            render: item => item?.userLimitAll - item?.userLimitOff,
          },
          {
            title: '오프라인',
            dataIndex: 'userLimitOff',
            width: '5%',
            align: 'center',
            // render: ( text, record, index ) => <>{text}<EditCountBt rowRecord={rowRecord} getNewData={getNewData} /></>,
          },
        ]
      },
      {
        title:'신청 인원',
        children: [
           {
        title: '총',
        dataIndex: '',
        width: '5%',
        align: 'center',
        render: e => e.entryCountOn + e.entryCountOff,
      },
      {
        title: '온라인',
        dataIndex: 'entryCountOn',
        width: '5%',
        align: 'center',
      },
      {
        title: '오프라인',
        dataIndex: 'entryCountOff',
        width: '7.5%',
        align: 'center',
        render: ( text, record, index ) => <>{text}<OffUserAddBt rowRecord={rowRecord} getNewData={getNewData} getoffuser={getoffuser} /></>,
      }
        ]},
      {
        title: <span>수강생<br />목록</span>,
        dataIndex: 'age',
        width: '5%',
        align: 'center',
        render:() => <UserListPrint rowRecord={rowRecord} getNewData={getNewData} />,
      },
      {
        title: <span>수강<br />신청서</span>,
        dataIndex: 'age',
        width: '5%',
        align: 'center',
        render:() => <UserApplyPrint rowRecord={rowRecord} getNewData={getNewData} />,
      },
    ];

    
    const [ state, setState ] = useState({
      dataSource: programData,
          count: totalCnt,
      });
      
      useEffect(() => {
        console.log(rowRecord)
        if(programData != null){
            setState({
              dataSource: programData,
              count: totalCnt,
              })
        }
      }, [programData]);

    return (
      <div>
        { changeLimit !== null ? successLimit() : offuserData !== null ? success() : null }
        <Table
          className="program-manage-table"
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={state?.dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
          onRow={(record) => {
            return{
            // onMouseEnter: () => setRowRecord(record)
            onClick: () => setRowRecord(record)
            }
          }}
        >
        </Table>
      </div>
    );
  
}

export default StatusTable;
