import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { SearchOutlined, EditOutlined, UserAddOutlined, FileSearchOutlined, SwapOutlined} from '@ant-design/icons';
import { Table, Input, Modal, Popconfirm, Button, Tag, Form, Select, Alert } from 'antd';
import UserMemo from './UserMemo';
import {defaultApiAddress, refreshToken} from '../../services/api.helpers';

const { Option } = Select;

const HistoryCheckTable = (props) => {

    const { programData, totalCnt, getNewData, loading } = props;
    const [ rowRecord, setRowRecord ] = useState(null);
    const [ userStatus, setUserStatus ] = useState(null);
    const [ chgValue, setChgValue ] = useState(null);

    useEffect(() => {
        let ignore = false;
        const changeStatus = async (_idToken) => {
            if(chgValue !== null){
                let data = {
                    status: chgValue,
                };
                console.log(data);
                await axios.put(`${defaultApiAddress}/courseEntry/${userStatus}`, data, {headers: {
                        "Authorization" : _idToken,
                        "Content-Type" : "application/json",
                        "Access-Control-Allow-Origin" : "*",
                    }})
                    .then(res => {
                        if(!ignore){
                            console.log(res.data);
                            getNewData(res?.data);
                            setChgValue(null);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }
        refreshToken().then((_idToken)=>{changeStatus(_idToken)});
        return () => { ignore = true}
    }, [chgValue]);

    const columns = [
        {
            title: '번호',
            dataIndex: '',
            width: '3%',
            align: 'center',
            render: list => list ? dataSource.indexOf(list)+1 : '',
        },
        {
            title: '신청일',
            dataIndex: '',
            width: '4%',
            align: 'center',
            render: list => list ? moment(list?.createdAt).format('YY/MM/DD') : '',
        },
        {
            title: '강좌명',
            dataIndex: 'courseInfo',
            width: '15%',
            align: 'center',
            render: text => text?.title?.length > 20 ? text?.title?.slice(0, 20)+'...' : text?.title,
        },
        {
            title: <span>신규/<br />기존</span>,
            dataIndex: '',
            width: '4%',
            align: 'center',
            render: list => '',
        },
        {
            title: '수강생',
            dataIndex: 'entryUser',
            width: '6%',
            align: 'center',
            render: list => list?.userNameReal,
        },
        {
            title: '보호자',
            dataIndex: '',
            width: '4%',
            align: 'center',
            render: list => '',
        },
        {
            title: <span>생년<br />월일</span>,
            dataIndex: '',
            width: '3%',
            align: 'center',
            render: list => '',
        },
        {
            title: <span>수강생<br />연락처</span>,
            dataIndex: 'entryUser',
            width: '5%',
            align: 'center',
            render: list => list?.phoneNumber,
        },
        {
            title: '수강료',
            dataIndex: '',
            width: '5%',
            align: 'center',
            render: list => list?.payPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        },
        {
            title: <span>결제<br />방법</span>,
            dataIndex: '',
            width: '3%',
            align: 'center',
            render: list => (list?.payMethod != null ? (list?.payMethod == 'CREDIT' ? '카드' : (list?.payMethod == 'CASH' ? '현금' : '계좌')) : ''),
        },
        {
            title: '일반회원',
            dataIndex: 'entryUser',
            width: '5%',
            align: 'center',
            render: list => (list?.ym == true ? '일반회원' : ''),
        },
        {
            title: <span>환불/<br />변경</span>,
            dataIndex: '',
            width: '4%',
            align: 'center',
            render: list => (list && list?.status == 'REFUNDED') ? moment(list?.updatedAt).format('YY/MM/DD') : '',
        },
        {
            title: <span>환불<br />금액</span>,
            dataIndex: '',
            width: '3%',
            align: 'center',
            render: list => '',
        },
        {
            title: '메모',
            dataIndex: '',
            width: '8%',
            align: 'center',
            render: list => (list?.memo != null ? list?.memo : ''),
        }
    ];
    const [ state, setState ] = useState({
        dataSource: programData,
        count: totalCnt,
    });


    useEffect(() => {
        if(programData != null){
            setState({
                dataSource: programData,
                count: totalCnt,
            })
        }
    }, [programData, getNewData]);

    console.log(rowRecord)

    const { dataSource } = state;
    return (
        <div>
            <Table
                className="program-manage-table historychk-table"
                rowClassName={() => 'editable-row'}
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                pagination={{
                    pageSize:12,
                    size: 'small',
                }}
            >
            </Table>
        </div>
    );

}

export default HistoryCheckTable;
