import React, { useState, useEffect, useRef } from "react";
import { Link, Switch, Route, useHistory, useParams, useLocation } from 'react-router-dom'
// import GalleryList from "../components/gallery/GalleryList";
import ListValue from "../components/gallery/ListValue";
import GalleryDetail from "../components/gallery/GalleryDetail";
import GalleryWrite from "../components/gallery/GalleryWrite";
import '../assets/styles/galleryboard.css';

export default function Gallery(props) {
  const {userObj, setUserObj} = props;
  const type = 'gallery'
      return(
        <>
          <Switch>
              <Route exact path="/gallery/:cate/write/">
                  <GalleryWrite userObj={userObj} type={type} />
              </Route>
              <Route path="/gallery/:cate/write/:bbsId">
                  <GalleryWrite userObj={userObj} type={type} />
              </Route>
              <Route path="/gallery/:cate/detail/:bbsId">
                  <GalleryDetail userObj={userObj} type={type} />
              </Route>
              <Route path="/gallery/:cate/">
                  <ListValue userObj={userObj} type={type} />
              </Route>
          </Switch>
        </>
      );
}