import React, { useState, useEffect } from 'react';
import moment from "moment";
import axios from "axios";
import { Table, Popconfirm, Tag, Select, Alert } from 'antd';
import UserMemo from './UserMemo';
import {defaultApiAddress, refreshToken} from '../../services/api.helpers';

const { Option } = Select;

const ProgramApplyTable = (props) => {

  const { programData, totalCnt, getNewData, page, loading } = props;
  const [ rowRecord, setRowRecord ] = useState(null);
  const [ offuserData, setOffuserData ] = useState(null);
  const [ userStatus, setUserStatus ] = useState(null);
  const [ chgValue, setChgValue ] = useState(null);
  const [ currentState, setCurrentState ] = useState(null);
  const [ popOpen, setPopOpen ] = useState({
    display: ''
  });

  const success = () => {
    setTimeout(() => {
      setOffuserData(null);
    },[4000])
      return (
        <Alert
            className="alert-result-true"
            message="변경 완료"
            description="상태가 정상적으로 변경되었습니다."
            type="success"
            showIcon
            closable={true}
            />
        )
  };
    
    useEffect(() => {
      let ignore = false;
      const changeStatus = async (_idToken) => {
        // console.log(`변경할 ID값은 : ${userStatus} 입니다.`);
        // console.log(`변경할 상태값은 : ${chgValue} 입니다.`);
        if(chgValue !== null){
          let data = {
            status: chgValue,
          };
          console.log(data);
           await axios.put(`${defaultApiAddress}/courseEntry/${userStatus}`, data, {headers: {
                   "Authorization" : _idToken,
                   "Content-Type" : "application/json",
                   "Access-Control-Allow-Origin" : "*",
               }})
        .then(res => {
         if(!ignore){
          console.log(res.data);
          getNewData(res?.data);
          success();
          setPopOpen({
            display: 'none'
          })
          setChgValue(null);
         }
        })
        .catch(err => {
          console.log(err);
        })
        }
      }
        refreshToken().then((_idToken)=>{changeStatus(_idToken)});
      return () => { ignore = true}
    }, [chgValue]);

  const popcornDiv = () => {
    switch(currentState){
      case 'WAITING': 
      return (
        <div className="popcorn-content">
          <p className="status-chgbt"><Tag className="popcorn-bt">입금대기</Tag></p>
          <a className="status-chgbt" onClick={(e) => setChgValue('done')}><Tag className="popcorn-bt" color="green">입금완료</Tag></a>
          <a className="status-chgbt" onClick={(e) => setChgValue('canceled')}><Tag className="popcorn-bt" color="orange">취소</Tag></a>
          <p className="status-chgbt"><Tag className="popcorn-bt">환불</Tag></p>
      </div>
      ); break;
      case 'DONE': return (
        <div className="popcorn-content">
          <a className="status-chgbt" onClick={(e) => setChgValue('waiting')}><Tag className="popcorn-bt" color="blue">입금대기</Tag></a>
          <p className="status-chgbt"><Tag className="popcorn-bt">입금완료</Tag></p>
          <p className="status-chgbt"><Tag className="popcorn-bt">취소</Tag></p>
          <a className="status-chgbt" onClick={(e) => setChgValue('refunded')}><Tag className="popcorn-bt" color="volcano">환불</Tag></a>
      </div>
      ); break;
      case 'CANCELED': return (
        <div className="popcorn-content">
          <p className="status-chgbt"><Tag className="popcorn-bt">입금대기</Tag></p>
          <p className="status-chgbt"><Tag className="popcorn-bt">입금완료</Tag></p>
          <p className="status-chgbt"><Tag className="popcorn-bt">취소</Tag></p>
          <p className="status-chgbt"><Tag className="popcorn-bt">환불</Tag></p>
      </div>
      ); break;
      case 'REFUNDED': return (
        <div className="popcorn-content">
          <p className="status-chgbt"><Tag className="popcorn-bt">입금대기</Tag></p>
          <a className="status-chgbt" onClick={(e) => setChgValue('done')}><Tag className="popcorn-bt" color="green">입금완료</Tag></a>
          <p className="status-chgbt"><Tag className="popcorn-bt">취소</Tag></p>
          <p className="status-chgbt"><Tag className="popcorn-bt">환불</Tag></p>
      </div>
      ); break;
    }


    return (
      <div className="popcorn-content">
          <a className="status-chgbt" onClick={(e) => setChgValue('waiting')}><Tag className="popcorn-bt" color="blue">입금대기</Tag></a>
          <a className="status-chgbt" onClick={(e) => setChgValue('done')}><Tag className="popcorn-bt" color="green">입금완료</Tag></a>
          <a className="status-chgbt" onClick={(e) => setChgValue('canceled')}><Tag className="popcorn-bt" color="orange">취소</Tag></a>
          <a className="status-chgbt" onClick={(e) => setChgValue('refunded')}><Tag className="popcorn-bt" color="volcano">환불</Tag></a>
      </div>
    )
  }

const [payMethodLoading, setPayMethodLoading] = useState(false);

  // 결제 정보 변경하는 함수
  const changePayMethod = (value) => {
    console.log(value, rowRecord);
    setPayMethodLoading(true);
    refreshToken().then((_idToken)=>{changeEvent(value, _idToken)});
  }

  const changeEvent = (value, _idToken) => {
    console.log(value);
    let data;
    data = JSON.stringify({payMethod: value});
    console.log(data);
    return axios.put(`${defaultApiAddress}/courseEntry/${rowRecord?.id}`, data, {headers: {
            "Authorization" : _idToken,
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        }})
        .then(res => {
            console.log(res);
            getNewData(res);
            if(!!loading){
              setPayMethodLoading(false);
            }
        })
        .catch(err => {
            console.error(err);
            setPayMethodLoading(false);
        });
    };


   const columns = [
      {
        title: '강좌명',
        dataIndex: 'courseInfo',
        width: '20%',
        align: 'center',
        render: text => text?.title?.length > 20 ? text?.title?.slice(0, 20)+'...' : text?.title,
      },
      {
        title: '강사명',
        dataIndex: 'courseInfo',
        width: '8%',
        align: 'center',
        render: list => list?.instructor,
      },
      {
        title: '수강생 이름',
        dataIndex: 'entryUser',
        width: '8%',
        align: 'center',
        render: list => list?.userNameReal,
      },
      {
        title: '수강생 연락처',
        dataIndex: 'entryUser',
        width: '10%',
        align: 'center',
        render: list => list?.phoneNumber,
      },
      {
        title: '수강료',
        dataIndex: 'payPrice',
        width: '8%',
        align: 'center',
        render: i => i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+"원",
      },
      {
        title: '신청상태',
        dataIndex: '',
        width: '8%',
        align: 'center',
        render: (e) => {
          return (
            <Popconfirm 
              placement="right"
              className="status-popcorn"
              title={popcornDiv}
              icon={false}
              overlayStyle={popOpen}
            >
                { e?.status === 'WAITING' ?                
                    <a onClick={() => {
                      setPopOpen({
                        display: ''
                      })
                      setUserStatus(e.id);
                      setCurrentState('WAITING');
                      }
                    }><Tag className="status-tag" color="blue">입금대기</Tag></a> :
                  e?.status === 'DONE' ?  
                    <a onClick={() => {
                      setPopOpen({
                        display: ''
                      })
                      setUserStatus(e.id);
                      setCurrentState('DONE');
                      }
                    }><Tag className="status-tag" color="green">입금완료</Tag></a> :
                  e?.status === 'CANCELED' ?  
                      <a onClick={() => {
                        setPopOpen({
                          display: ''
                        })
                        setUserStatus(e.id);
                        setCurrentState('CANCELED');
                        }
                      }><Tag className="status-tag" color="orange">취소</Tag></a> :
                  e?.status === 'REFUNDED' ?  
                      <a onClick={() => {
                        setPopOpen({
                          display: ''
                        })
                        setUserStatus(e.id);
                        setCurrentState('REFUNDED');
                        }
                      }><Tag className="status-tag" color="volcano">환불</Tag></a> :
                null }
            </Popconfirm>
          )
         }
      },
      {
        title: '결제정보',
        dataIndex: '',
        width: '10%',
        align: 'center',
        render: (info) => (
          <>
            <Select className="roleSelect"
                   value={info?.payMethod === "WIRE" ? "계좌" : info?.payMethod === "CASH" ? "현금" : info?.payMethod === "CREDIT" ? "카드" : null}
                      onChange={(value) => {
                        changePayMethod(value);
                         }
                      }
                      >
                <Option value="WIRE">계좌</Option>
                <Option value="CASH">현금</Option>
                <Option value="CREDIT">카드</Option>
            </Select>
          </>
        ),
      },
      {
        title: '신청일',
        dataIndex: 'createdAt',
        align: 'center',
        width: '12%',
        render: (i) => moment(i).format('YY/MM/DDㅤHH:mm'),
      },
      {
        title: '메모',
        dataIndex: 'memo',
        width: '17.5%',
        align: 'center',
        render: (i) => {
          if ( i == null) {
            return <UserMemo rowRecord={rowRecord} getNewData={getNewData} memovalue={i} />
          } else {
            return <UserMemo rowRecord={rowRecord} getNewData={getNewData} memovalue={i.trim()} />
          }
        }
      },
    ];
    const [ state, setState ] = useState({
      dataSource: programData,
          count: totalCnt,
      });
      
  
      useEffect(() => {
        if(programData != null){
            setState({
              dataSource: programData,
              count: totalCnt,
              })
        }
      }, [programData, getNewData]);
      
      console.log(programData)

    const { dataSource } = state;
    return (
      <div>
        { offuserData !== null ? success() : null }
        <Table
          className="program-manage-table"
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          onRow={(record, recordIndex) => ({
            onClick: event => { setRowRecord(record) }
            // onClick: event => { console.log( record )} 
          })}
          // rowKey={rowIndex => rowIndex.id}
          pagination={false}
          >
        </Table>
      </div>
    );
  
}

export default ProgramApplyTable;
