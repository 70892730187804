import React, { useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom'
import {Space, Button, Typography, Row, Col, Form, Image, Skeleton, Alert } from 'antd';
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import { ArrowLeftOutlined, RightCircleFilled } from '@ant-design/icons';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import DeleteModalBtn from "../layout/DeleteModalBtn";
import moment from 'moment';
import axios from "axios";

const { Title, Text } = Typography;

export default function ProgramDetail(props) {
    
    const { userObj } = props;
    let bbsNum = useParams();
    const bbsId = bbsNum?.bbsId;
    const history = useHistory();
    const [ loading, setLoading ] = useState(true);
    const [ detailData, setDetailData ] = useState({
        id: null,
        title: null,
        mediaUrl:null,
        category:null,
        startDate:null,
        endDate:null,
        time:null,
        userLimitAll:null,
        userLimitOff:null,
        description: null,
        instructor: null,
        info: {
            day:null,
            startTime:null,
            endTime:null,
            lectureRoom: null,
            programFee:null,
            }
    });

    // date 포맷 state
    const [ duration, setDuration ] = useState(null);
    const [ dayFormat, setDayFormat ] = useState(null);
    const [localToken, setLocalToken] = useState();

    const getDataAPI = () => {
        axios.get(`${defaultApiAddress}/courses/${bbsId}`)
        .then(res => {
            console.log(res.data);
            setDetailData(res.data);
            setDayFormat(res.data?.info?.day.join(', '));
            numberWithComma(res.data?.info?.programFee);
            if(res.data?.mediaUrl === null){
                setLoading(true);
            }else{
                setLoading(false);
            }
        })
        .catch(err => console.error(err));
    };

  // 정규식을 통한 숫자 3자리 마다 콤마 삽입.
  const [fee, setFee] = useState(0);
  // 정규식을 통한 숫자 3자리 마다 콤마 삽입.
  function numberWithComma(x){
    return setFee(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
}

    useEffect(() => {
        if (!bbsId) {
            <Alert
                message="Error"
                description="유효하지 않은 접근입니다."
                type="error"
                showIcon
            />
            return history.push('/program/');
        }else{
            refreshToken().then((_idToken)=>{setLocalToken(_idToken)});
            getDataAPI();
        }
    },[]);

    const onHandleBack = () => {
        history.push('/program/');
    };

    const courseDeleteConfirm = async (event) => {
        console.log(event);
        console.log(event.target.value);
        await deleteCourse(event.target.value);
        return history.push({
          pathname:'/program/',
          state: { delete: true,
                   id: bbsId
          }
         });
    };

    const deleteCourse = () => {
       return axios.delete(`${defaultApiAddress}/courses/${bbsId}`, {headers: {
               "Authorization" : localToken,
               "Content-Type" : "application/json",
               "Access-Control-Allow-Origin" : "*",
           }})
            .then(() => {
                console.log('board deleted');
            });
    };

    const updateBoard = () => {
        history.push(`/program/write/${bbsId}`);
    };

  useEffect(() => {
        if(detailData.startDate === null){
          return null;
      }else{
          // const coursePeriod = detailData.
        let day1 = moment(detailData.startDate).format('YYYY-MM-DD');
        let day2 = moment(detailData.endDate).format('YYYY-MM-DD');
        function durationWeeks(dayOne, dayTwo){
            return dayTwo.diff(dayOne, 'weeks', true);
        }

        let resultDuration = durationWeeks(moment(day1), moment(day2));
        setDuration(Math.round(resultDuration));
    }
  }, [detailData?.startDate])


   const [ category, setCategory ] = useState(null);

    useEffect(() => {
        if(detailData.category != null){
            switch(detailData.category) {
                case 'LIST01': return setCategory('미술.서예'); break;
                case 'LIST02': return setCategory('악기·음악'); break;
                case 'LIST03': return setCategory('댄스·무용'); break;
                case 'LIST04': return setCategory('생활교양'); break;
                case 'LIST05': return setCategory('어린이'); break;
                case 'LIST06': return setCategory('야간'); break;
            }
        }
    },[detailData?.category]);

    return(
      <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
        <Row className="detail-program-MainInfoWrap">
            <Col span={2}>
                <Button onClick={onHandleBack} type="text">
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
            </Col>
            <Col span={2} offset={18}>
                <Button onClick={updateBoard} className="editBt" type="text">수정하기</Button>
            </Col>
            <Col span={2} offset={0}>
                <DeleteModalBtn boardDeleteConfirm={courseDeleteConfirm} />
            </Col>
        </Row>
        <Row className="programDetailInfoWrap">
            <Col className="info-first-col" span={24} lg={24} xl={8} xxl={8}>
               <Space align="center" direction="vertical">
                   { loading === true ? 
                        <Skeleton.Image style={{width:"315px", height: "315px" }} active /> : 
                                <Image 
                                    // width="100%"
                                    src={defaultApiAddress+detailData.mediaUrl} 
                                    alt="thumbnail"
                                    preview={false}
                                />
                                    }
              </Space>
            </Col>
            <Col className="info-second-col" span={24} lg={24} xl={16} xxl={16}>
                    <Row style={{height:"25%"}}>
                        <Col span={24}>
                                <Title level={1}>{detailData.title}</Title>
                        </Col>
                        <Col span={24}>
                                <span className="userLimitTag">강좌인원</span><span className="userLimitTxt">ㅤ신청 가능 인원(온라인) :ㅤ{detailData?.userLimitAll - detailData?.userLimitOff}명ㅤㅤ/ㅤㅤ모집 인원 :ㅤ{detailData.userLimitAll}명</span>
                        </Col>
                    </Row>
                   
                    <Row style={{height:"75%"}}>
                        <Col className="listCol" span={12}>
                            <ul>
                                <li><span className="listTitle">카테고리</span><span className="listData">{category}</span></li>
                                <li><span className="listTitle">강사명</span><span className="listData">{detailData.instructor}</span></li>
                                <li><span className="listTitle">수강료</span><span className="listData">{detailData?.info?.programFee}원</span></li>
                                <li><span className="listTitle">강의실</span><span className="listData">{detailData?.info?.lectureRoom}</span></li>
                            </ul>
                        </Col>
                        <Col className="listCol" span={12}>
                            <ul>
                                <li><span className="listTitle">시작일</span><span className="listData">{moment(detailData.startDate).format('YY/MM/DD')}</span></li>
                                <li><span className="listTitle">수업일</span><span className="listData">{dayFormat}</span></li>
                                <li><span className="listTitle">교육시간</span><span className="listData">{detailData?.info?.startTime}~{detailData?.info?.endTime}</span></li>
                                <li><span className="listTitle">교육기간</span><span className="listData">{ duration !== null ? duration : <Skeleton.Avatar active size="small" /> }ㅤ주</span></li>
                            </ul>
                        </Col>
                    </Row>
            </Col>
        </Row>
        <div style={{width: '100%', maxWidth:1000, margin:"30px auto 0", textAlign:'left'}}>
            <RightCircleFilled style={{fontSize:14, color:"#FFD560", marginRight:10}} /><Text strong style={{fontSize:16}}>강좌소개</Text>
        </div>
        <Row className="qulii-body-row" align="middle" justify="center" style={{padding:"0 24px 24px"}} >
            {detailData ? (
                <>
                    <ReactQuill
                        value={detailData.description}
                        readOnly={true}
                        theme={"bubble"}
                    />
                    <Col span={24} style={{margin:"25px 0"}}>
                        { detailData.body }
                    </Col>
                </>
            ) : (
                <>
                        <div>로딩중...</div>
                </>
            )}
        </Row>
      </Row>
    );
}
