import React from "react";
import { useParams } from 'react-router-dom'
import GalleryDetailText from "./GalleryDetailText";
import GalleryDetailImage from "./GalleryDetailImage";
import 'react-quill/dist/quill.bubble.css';

export default function GalleryDetail(props) {
    const { cate, bbsId } = useParams();
    const { userObj, type } = props;
    console.log(cate)
    const MatchDetail = () => {
        switch(cate) {
            case 'e-culrture': return <GalleryDetailText userObj={userObj} type={type} name='행사안내ㅤ/ㅤ문화행사' />; break;
            case 'e-academic': return <GalleryDetailText userObj={userObj} type={type} name='행사안내ㅤ/ㅤ학술행사' />; break;
            case 'gallery': return <GalleryDetailImage userObj={userObj} type={type} name='행사안내ㅤ/ㅤ갤러리' />; break;
            case 'cultural': return <GalleryDetailImage userObj={userObj} type={type} name='성북학ㅤ/ㅤ문화유산' />; break;
            case 'hfigure': return <GalleryDetailImage userObj={userObj} type={type} name='성북학ㅤ/ㅤ인물'  />; break;
        }
    }
  return (
      <>
      { MatchDetail() }
      </>
  );
}
