import React, { useState, useEffect } from "react";
import NaverLogin from 'react-login-by-naver';
import axios from "axios";
import { defaultApiAddress, keys } from "../../services/api.helpers";
import { authService, firebaseInstance } from "../../middleware/fb";

const client_id = keys.naverClientId;
// const client_secret = keys.naverClientSecret;

const NaverSignin = ({ userObj, setUserObjFn, setIsLoginedFn, setErrorModalFn }) => {

    function requestToken (res) {
        const uid = 'naver:' + res.id;
        const displayName = res.name;
        const email = res.email;
        const photoUrl = res.profile_image;

        let value = {
            uid: uid ? uid : '',
            displayName: displayName ? displayName : '',
            email: email ? email : '',
            photoUrl: photoUrl ? photoUrl : '',
            provider: 'custom',
        }
        setUserObjFn(() => {
            return {...value};
        });

        const instance = axios.create({
            baseURL: defaultApiAddress,
            headers: {
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 10000,
        });

        try {
            const response = instance.post(
                'auth/',
                value
            ).then(response => {
                let customToken = response.data.idToken;
                setIsLoginedFn(true);     // 로그인 됨

                authService.signInWithCustomToken(customToken)
                    .then(async (res) => {
                        const instance = await axios.create({
                            baseURL: defaultApiAddress,
                            headers: {
                                "Authorization" : res.user._lat,
                                "Content-Type" : "application/json",
                                "Access-Control-Allow-Origin" : "*",
                            },
                            timeout: 5000,
                        });
                        const response = await instance.get(
                            'users/' + res.user.uid
                        ).then(async response => {
                            if (response.data.userNameReal && response.data.phoneNumber) {
                                setUserObjFn(() => {
                                    let _userObj = {...userObj};
                                    _userObj.userNameReal = response.data.userNameReal;
                                    _userObj.phoneNumber = response.data.phoneNumber;
                                    _userObj.provider = response.data.provider;
                                    _userObj.uid = response.data.id;
                                    _userObj.email = response.data.email;
                                    _userObj.displayName = response.data.userName;
                                    _userObj.photoURL = response.data.photoUrl;
                                    return _userObj;
                                });
                            } else {
                                setUserObjFn(() => {
                                    let _userObj = {...userObj};
                                    _userObj.provider = response.data.provider;
                                    _userObj.uid = response.data.id;
                                    _userObj.email = response.data.email;
                                    _userObj.displayName = response.data.userName;
                                    _userObj.photoURL = response.data.photoUrl;
                                    return _userObj;
                                });
                            }
                        }).catch(async (error) => {
                            setErrorModalFn(true);
                        });
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log(error.response.data);
                        }
                    });
            });
        } catch (error) {
            if (error.response) {
                console.log(error.response.data);
            }
        }
    }

    return(
        <NaverLogin
            clientId={client_id}
            callbackUrl={window.location.origin + "/#"}
            onSuccess={requestToken}
            onFailure={(res) => console.error(res)}
            isPopup={false}
            render={({ onClick }) => {
                return (
                    <button
                        className='before naverBtn'
                        onClick={(e) => {
                            e.preventDefault();
                            onClick();
                        }}
                    >네이버 간편 로그인
                    </button>
                );
            }}
        />
    );
};

export default NaverSignin
