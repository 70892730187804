import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import axios from "axios";
import moment from 'moment';
import { useRecoilValue } from "recoil";
import { idState, replyState } from "../../recoil-state/inquiryState";
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import {Space, Button, Typography, Row, Col, Modal, Form, Divider, List, Input } from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined, EyeOutlined } from '@ant-design/icons';
import DeleteModalBtn from "../layout/DeleteModalBtn";
import InquiryComment from './InquiryComment';

const { Title, Text } = Typography;
const { TextArea } = Input;
export default function InquiryDetail(props) {
    const { userObj } = props;
    const {bbsId} = useParams();
    const history = useHistory();

    const [ detailData, setDetailData ] = useState({
        id: null,
        status: false,
        title: null,
        type: null,
        description: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        createdUserId: null,
        updatedUserId: null,
        answerCount:null,
    });
    const replyStatus = useRecoilValue(replyState);
    const [localToken, setLocalToken] = useState();

    // recoil State (리스트에서 클릭한 게시판 id)
    const listId = useRecoilValue(idState);
    const [ status, setStatus ] = useState(false);
    // date표기법 포맷 state
    const [ formatDate, setFormatDate ] = useState('');

    const getDataAPI = async (_idToken) => {
        await axios.get(defaultApiAddress + `/inquiries/${bbsId}`, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
        .then(res => {
            setDetailData(res.data);
            console.log(res.data);
            setFormatDate(res.data.createdAt);
            if(res?.data?.answerCount >= 1){
                setStatus(true);
                }
            });
        }

   const getNewComment = state => {
       if(state === true){
           setStatus(true)
       }else{
        setStatus(false)
       }
   }

    useEffect(() => {
        refreshToken().then((_idToken)=>{getDataAPI(_idToken);setLocalToken(_idToken)});
    }, [])

    console.log(detailData?.answerCount);

    useEffect(() => {
        if(detailData?.answerCount >= 1){
            setStatus(true);
        }else if(detailData?.answerCount === 0){
            setStatus(false);
        }
    }, [status])

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

    //  삭제 후 동기처리 안되는 구간. 수정해야함.
  const boardDeleteConfirm = () => {
      axios.delete(defaultApiAddress + `/inquiries/${bbsId}`, {headers: {
              "Authorization" : localToken,
              "Content-Type" : "application/json",
              "Access-Control-Allow-Origin" : "*",
          }})
          .then(() => {
              console.log('board deleted');
          });
      history.push(`/inquiry/`);
  };

  const boardDeleteCancled = () => {
      setIsModalVisible(false);
  };

  const onHandleBack = () => {
      history.push(`/inquiry/`);
  };

//   const lineBreak = () => {
//     const content = detailData?.description;
//     console.log(content.replace(/\r\n/g, "<br />"));
//     // console.log(textRepc)
//     // return textRepc;
//   }

return(
       <>
{ detailData !== null ? 
    <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
        <Row className="detailMainInfoWrap">
            <Col span={2}>
                <Button onClick={onHandleBack} type="text">
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
            </Col>
            <Col span={2} offset={20}>
                <DeleteModalBtn boardDeleteConfirm={boardDeleteConfirm} />
            </Col>
            </Row>
        <Row span={24} className="border-grid" justify="start">
            <Col span={4} className="border-grid-title">
            <span>제목</span>
            </Col>
            <Col span={20} className="border-grid-content">
                  <span>ㅤ{ detailData?.title?.slice(0, 100) }</span> 
            </Col>
        </Row>
        <Row span={24} className="border-grid-row" justify="start">
            <Col span={4} className="border-grid-title">
            <span>이름</span>
            </Col>
            <Col span={10} className="border-grid-content">
                  <span>ㅤ{ detailData?.createdBy?.userName }</span> 
            </Col>
            <Col span={4} className="border-grid-title" style={{borderLeft: '1px solid #e0e0e0'}}>
            <span>작성일</span>
            </Col>
            <Col span={4} className="border-grid-content">
                <span>{ moment(formatDate).format('YY/MM/DD') }</span>
            </Col>
        </Row> 
        <Row span={24} className="border-grid-row" justify="start">
            <Col span={4} className="border-grid-title">
            <span>문의유형</span>
            </Col>
            <Col span={10} className="border-grid-content">
                  <span>{ detailData.type }</span>
            </Col>
            <Col span={4} className="border-grid-title" style={{borderLeft: '1px solid #e0e0e0'}}>
            <span>답변상태</span>
            </Col>
            <Col span={4} className="border-grid-content">
                  <span>{ status === true ? '답변완료' : '미완료'}</span>
            </Col>
        </Row> 
        <Row span={24} className="border-grid" justify="start" style={{paddingLeft:0, paddingRight:0}}>
            <Col span={24} className="border-grid-title" style={{borderBottom:'1px solid #e0e0e0', borderLeft:'none', borderRight:'none'}}>
                <span>문의내용</span>
            </Col>
            <Col span={24} className="border-grid-content" style={{padding:'20px 30px', minHeight:400,}}>
               {/* { lineBreak() }  */}
                  {/* { detailData.description } */}
                  <TextArea className="textAreaView" value={detailData?.description} disabled></TextArea>
            </Col>
        </Row>
        <Row className="comment-row">
            <Col span={24} className="border-grid-title" style={{borderLeft:'none', borderRight:'none'}}>
                {/* <span>문의답변</span> */}
            </Col>
            <InquiryComment 
                    bbsId={bbsId}
                    userObj={userObj}
                    detailData={detailData}
                    history={history}
                    status={status}
                    getNewComment={getNewComment}
                />
        </Row>
      </Row>
    
    
    : null }
    </>
    );
}
