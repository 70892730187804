import React from "react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { Input, InputNumber, Table, Button, Typography, Form, Row, Col, Spin } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;
class ApplyPrint extends React.Component {
  render() {
    console.log(this.props.rowRecord);
    console.log(this.props.listData);
    let courseData = this.props.rowRecord;
    let fee ;
    numberWithComma(courseData?.info?.programFee);
      // 정규식을 통한 숫자 3자리 마다 콤마 삽입.
      function numberWithComma(x){
        return fee = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    return (
    <div className="print-bg">
      <div className="print-page">
          <div>
              <div className="title">수강신청서(영수증)<sub>접수번호(<input type="text"/>)</sub></div>
              <form action="">
              <table className="">
                  <colgroup>
                      <col width="20%"/>
                      <col width="28%"/>
                      <col width="20%"/>
                      <col width="28%"/>
                  </colgroup>
                  <tr>
                      <td className="bg-medium-gray">강좌명</td>
                      <td>{courseData?.title}</td>
                      <td>수강료</td>
                      <td>{fee}원</td>
                  </tr>
                  <tr>
                      <td className="bg-medium-gray">성명</td>
                      <td></td>
                      <td>생년월일</td>
                      <td></td>
                  </tr>
                  <tr>
                    <td>보호자</td>
                    <td></td>
                    <td className="bg-medium-gray">연락처</td>
                    <td></td>
                </tr>
                <tr>
                    <td>주소</td>
                    <td colspan="3"></td>
                </tr>
                <tr>
                    <td>회원구분</td>
                    <td colspan="3" className="checkboxTd">
                        <label><input type="checkbox" name="member" value=""/>일반회원 (舊정회원)</label>
                        <label><input type="checkbox" name="member" value=""/>기존회원</label>
                        <label><input type="checkbox" name="member" value=""/>신규회원</label>
                    </td>
                </tr>
                <tr>
                    <td className="bg-medium-gray">결제방법</td>
                    <td colspan="3" className="checkboxTd">
                        <label><input type="checkbox" name="member" value=""/>현금</label>
                        <label><input type="checkbox" name="member" value=""/>계좌</label>
                        <label><input type="checkbox" name="member" value=""/>카드</label>
                    </td>
                </tr>
                <tr>
                    <td>차량소지자</td>
                    <td className="border-bottom" colspan="3">
                        <label>차랑명<input type="text"/></label>
                        <label>차랑번호<input type="text"/></label>
                        <label>색상<input type="text"/></label>
                    </td>
                </tr>
                <tr>
                    <td className="bg-medium-gray">개인정보</td>
                    <td colspan="3" className="checkboxTd">
                        <label><input type="checkbox" name="member" value=""/>동의</label>
                        <label><input type="checkbox" name="member" value=""/>동의 안 함</label>
                    </td>
                </tr>
              </table>
            </form>
          </div>
          <div className="text">
                  <strong> &#8251; 개인정보 수집에 동의하십니까?</strong><br/>
                    개인정보보호법 제15조 1항(개인정보의 수집·이용)에 의거하여 본인의 개인정보 수집에 동의합니다.<br/>
                    * 동의를 거부할 수 있으며, 이 경우 문화강좌 및 행사정보 수신에 제약이 있을 수 있습니다.<br/>
                    * 제공받은 개인정보는 위 목적 외에 다른 용도로 사용하지 않습니다.<br/>
                    <ul>
                        <li>개인정보의 수집·이용 목적: 수강신청, 강좌 운영 및 관리, 문화원 사업 및 강좌 정보 제공</li>
                        <li>수집하려는 개인정보 항목: 성명(보호자 성명), 생년월일, 주소, 연락처</li>
                        <li>개인정보의 보유·이용 기간: 프로그램 신청일로부터 프로그램 종료 후 5년까지 보유 및 이용</li>
                    </ul>

                </div>
                <div className="text">
                    <ul>
                        <div style={{textAlign: 'center', fontSize: '18px', marginBottom: '8px'}}><strong>유의사항 및 환불규정</strong></div>
                        <li>수강료는 12주(3개월)기준이며, 법정공휴일로 인한 휴강은 보강하지 않습니다.</li>
                        <li>적정 수강 인원에 미달될 경우 폐강될 수 있습니다.</li>
                        <li>환불 및 변경은 방문 접수만 가능하며, 전화 접수는 불가합니다.</li>
                        <li>전액 환불은 폐강 또는 개강 전 신청 시에만 가능하며, 개인사정으로 인한 미수강 시에는 환불되지 않습니다.<br/>
                            <sup>&#8251; 카드 환불 시 결제 카드와 카드영수증을 지참하셔야 하며, 카드 환불은(3개월)만 가능합니다.</sup>
                        </li>
                        <li>개강 후 출석 후에는 당월을 제외한 잔여 달만 환불이 가능하며, 환불 금액은 환불신청서를 작성한 월말에 본인 계좌로 지급됩니다.</li>
                        <br/>
                        <sub style={{textAlign: 'center', display: 'block'}}>&#8251; 주차 공간이 협소하오니 대중교통을 이용해주시기 바랍니다.</sub>
                    </ul>
                    <div className="sign">
                        <strong>위 문화강좌 수강료를 영수합니다.</strong>
                        <input type="text"/>
                        <strong>(인)</strong>
                    </div>
              </div>
        </div>
      </div>
    );
  }
}

class PrintApplyTable extends React.Component{
  render() {
    
    return (
      <div>
            <Text>총 신청 인원 : {this.props.rowRecord.entryCountOff + this.props.rowRecord.entryCountOn}명</Text>
            <div className="buttonWrap">
            <Title level={3} className="print-title">수강 신청서</Title>
              <Button className="printBt-cancel" key="back" onClick={this.props.printCancel}>
                취 소
              </Button>
              <ReactToPrint
                style={{position: 'relative'}}
                trigger={() => 
                <Button type="primary" className="printBt">
                  인 쇄
                  {/* <PrinterOutlined /> */}
                </Button>}
                content={() => this.componentRef}
              />
          </div>
        <ApplyPrint ref={(el) => (this.componentRef = el)} rowRecord={this.props.rowRecord} listData={this.props.listData} />
      </div>
    );
  }
}

export default PrintApplyTable;
