import React, { Component } from 'react';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import axios from "axios";
import { authService, firebaseInstance } from "../../middleware/fb";

class WriteEditor extends React.Component{
    constructor(props){
        super(props);
    }
    handleChange(html) {
        this.setState({ editorHtml: html });
    }

    // quill 게시판용 이미지 핸들러
    imgHandler = function imageHandler() {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            // console.log(file);

            await refreshToken().then(async (_idToken)=>{
                // Save current cursor state
                const range = this.quill.getSelection(true);

                const instance = axios.create({
                    baseURL: defaultApiAddress,
                    headers: {
                      "Authorization": _idToken,
                      "Content-Type": "multipart/form-data", // 파일 업로드를 위해 multipart/form-data로 변경
                      "Access-Control-Allow-Origin": "*",
                    },
                    timeout: 10000,
                  });
                  
                  // FormData 객체 생성 및 파일 추가
                    const res = new FormData();
                    res.append('image', file);
                    res.append('type', 'event');
                  
                  // Axios를 사용하여 파일 업로드
                  instance.post('images/', res)
                    .then(async (response) => {
                      // 응답으로 받은 이미지 URL 처리
                      let _imgUrl = defaultApiAddress + response.data.url; // await는 필요 없음
                      this.quill.insertEmbed(range.index, 'image', _imgUrl);
                      this.quill.setSelection(range.index + 1);
                    })
                    .catch(error => {
                      if (error.response) {
                        // 오류 응답 처리
                        console.log(error.response.data);
                      }
                    });
            })
        };
    }

    urlHandler = function urlHandler() {
        if (this.quill.getSelection().length === 0) {
            alert("링크가 삽입될 문구를 선택하고 다시 시도해 주세요.");
            return;
        }
        let url = prompt("URL주 소를 입력해주세요.");
        let _urlResult;
        console.log(this.quill.getSelection().length);

        function urlRegex(url) {
            return (/^([a-z][a-z0-9\*\-\.]*):\/\/(?:(?:(?:[\w\.\-\+!$&'\(\)*\+,;=]|%[0-9a-f]{2})+:)*(?:[\w\.\-\+%!$&'\(\)*\+,;=]|%[0-9a-f]{2})+@)?(?:(?:[a-z0-9\-\.]|%[0-9a-f]{2})+|(?:\[(?:[0-9a-f]{0,4}:)*(?:[0-9a-f]{0,4})\]))(?::[0-9]+)?(?:[\/|\?](?:[\w#!:\.\?\+=&@!$'~*,;\/\(\)\[\]\-]|%[0-9a-f]{2})*)?$/).test(url);
        }

        if (urlRegex(url)) { // http나 https가 붙은 url
            _urlResult = url;
        } else { // http나 https가 안붙은 url
            _urlResult = 'https://' + url;
        }

        this.quill.format('link',_urlResult);
    }

    vidHandler = function videoHandler() {
        let url = prompt("유투브 영상 주소를 입력해주세요.");

        function vidRegex1(_url){
            return (_url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/));
        }
        function vidRegex2(_url){
            return (_url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/));
        }
        function vidRegex3(_url){
            return (_url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/));
        }
        function vidRegex4(_url){
            return (_url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/));
        }

        let match = vidRegex1(url) || vidRegex2(url) || vidRegex3(url);

        if (match && match[2].length === 11) {
            url = ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
        }
        if (match = vidRegex4(url)) { // eslint-disable-line no-cond-assign
            url = (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
        }

        let range = this.quill.getSelection();
        if (url != null) {
            this.quill.insertEmbed(range.index, 'video', url);
            this.quill.setSelection(range.index+1);
        }
    }

    modules = {
    toolbar: {
        container: [
            //[{ 'font': [] }],
            // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            ['clean']
        ],
        handlers: {
            image: this.imgHandler,
            video: this.vidHandler,
            link: this.urlHandler
        }
    }
}

    formats = [
        //'font',
        // 'size',
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        // 'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'align', 'color', 'background',
    ]

  onChange(value){
    this.setState({ text: value })
  }

render(){
    const { value, onChange } = this.props;
    return(
        <div>
            <ReactQuill
                ref={el => {
                    this.quill = el;
                }}
                // onChange={this.handleChange}
                style={{height: "56vh", paddingBottom:42}}
                theme="snow"
                modules={this.modules} 
                formats={this.formats}
                value={value || ''}
                onChange={(content, delta, source, editor) => onChange(editor.getHTML())}
            />
        </div>
    )
  }
}
export default WriteEditor;
