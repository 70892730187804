import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import axios from "axios";
import { Space, Button, Input, Table, Tag  } from 'antd';
import ProgramApply from "../components/program-manage/ProgramApply";
import ProgramStatus from "../components/program-manage/ProgramStatus";
import HistoryCheck from "../components/program-manage/HistoryCheck";
import '../assets/styles/manage.css';


export default function ProgramManage(props) {
const {userObj, setUserObj} = props;
    return(
      <>
        <Switch>
            <Route path="/program-manage/status">
                <ProgramStatus userObj={userObj} />
            </Route>
            <Route path="/program-manage/historychk">
                <HistoryCheck userObj={userObj} />
            </Route>
            <Route path="/program-manage/">
                <ProgramApply userObj={userObj} />
            </Route>
        </Switch>
      </>
    );
}
