import React, { useState, useEffect }  from 'react';
import axios from "axios";
import { EditOutlined } from '@ant-design/icons';
import { Input, InputNumber, Modal, Button, Typography, Form, Row, Col, Spin } from 'antd';
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";

const { Field, useForm } = Form;
const { Text, Title } = Typography;

const EditCountBt = (props) =>{
    const { rowRecord, getNewData, getChangeLimit } = props;
    const [ editLimitModal, setEditLimitModal ] = useState(false);
    const [ editLoading, setEditLoading] = useState(false);
    const [ ettMessage, setErrorMessage ] = useState({
        title: '',
        subtitle: ''
    });

    // console.log(`rowRecord is: ${rowRecord}`);

    const [ state, setState ] = useState({
        userLimitAll: null,
        userLimitOff: null,
    })
    const [form]= useForm();
    const [ errMsg, setErrorMsg ] = useState(false);

    useEffect(() => {
        form.setFieldsValue(rowRecord);
    }, [rowRecord])


const onEditLimit = () => {
    form.resetFields();
    setEditLimitModal(true);
    };

const editLimitCancel = () => {
    form.resetFields();
    setErrorMsg(false);
    setEditLimitModal(false);
};

const colseModal = () => {
    setTimeout(() => {
        setEditLoading(false)
    }, 1700);
}

const onEditSubmit = (value) => {
    console.log(value);
    setEditLoading(true);
    refreshToken().then((_idToken)=>{submitEvent(value, _idToken)});
  }


const submitEvent = (value, _idToken) => {
    let values = Object.assign(value);
    if(values.userLimitAll == undefined && values.userLimitOff == undefined){
        return  setErrorMsg(true);
    }else{
        setErrorMsg(false);
        let chgData = JSON.stringify(values);
    return axios.put(`${defaultApiAddress}/courses/${rowRecord?.id}`, chgData, {headers: {
            "Authorization" : _idToken,
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        }})
        .then(res => {
            setEditLoading(false);
            console.log(res);
            setState({
                userLimitAll: res?.data?.userLimitAll,
                userLimitOff: res?.data?.userLimitOff
            })
            getChangeLimit(res);
            editLimitCancel();
            getNewData(res);
        })
        .catch(err => {
            console.error(err.response.data);
            setErrorMessage({
                title:err?.response?.data?.message,
                subtitle: ''
            })
            setErrorMsg(true);
            setEditLoading(false);
        });
    };
}

  const EditFinishFaild = (err) => {
      console.log(err)
  };

    return (
        <>
        <a className="editText" onClick={onEditLimit}><EditOutlined /></a>
        <Modal
            visible={editLimitModal}
            footer={null}
        >
        <Title level={3} style={{textAlign: 'center', marginBottom:25}}>모집 인원 수정</Title>
          <Row className="edit-table-row" justify="center" align="middle">
              <Row>
                <Col span={8} className="edit-header-title">
                <Text>강의명 : </Text>
                </Col>
                <Col span={14} className="edit-header-content">
                    <Text>{ rowRecord?.title.length > 20 ? rowRecord?.title.slice(0, 20)+'...' : rowRecord?.title }</Text>
                </Col>
            </Row>
            <Row>
                <Col span={8} className="edit-header-title">
                <Text>총 신청인원: </Text>
                </Col>
                <Col span={14} className="edit-header-content">
                    <Text>{ rowRecord?.entryCountOn + rowRecord?.entryCountOff }ㅤ명</Text>
                </Col>
            </Row>
            <Row>
                <Col span={8} className="edit-header-title">
                <Text>신청인원 (오프라인) : </Text>
                </Col>
                <Col span={14} className="edit-header-content">
                    <Text>{ rowRecord?.entryCountOff }ㅤ명</Text>
                </Col>
            </Row>
            <Row>
                <Col span={8} className="edit-header-title">
                <Text>신청인원 (온라인) : </Text>
                </Col>
                <Col span={14} className="edit-header-content">
                    <Text>{ rowRecord?.entryCountOn }ㅤ명</Text>
                </Col>
            </Row>
            <Form
              labelCol={{ span: 6 }}
              name="editForm"
              form={form}
              layout="vertical"
              className="editForm"
              onFinish={onEditSubmit}
              onFinishFailed={EditFinishFaild}
              initialValues={rowRecord}
              >
                <Row style={{maxWidth:"90%", margin: "25px auto", minWidth: "200px"}}>
                    <Col className="editModalCol" span={12}>
                        <Form.Item
                            label="총 모집 인원"
                            name="userLimitAll"
                        >
                            <InputNumber
                                size="large"
                                value={state.userLimitAll}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="editModalCol" span={12} style={{paddingBottom:'15px'}}>
                        <Form.Item
                            label="오프라인 모집인원"
                            name="userLimitOff"
                        >
                            <InputNumber
                                size="large"
                                value={state.userLimitOff}
                            />

                        </Form.Item>
                    </Col>
                    <Col span={24} style={{textAlign: "center", margin:0}}>
                        { errMsg ? <Text type="danger" style={{fontWeight:900 }}>{ettMessage?.title}</Text> : null }
                    </Col>
                    <Form.Item style={{margin:"40px 0", width: "100%"}}>
                        <Button className="applyModalBtn" key="back" onClick={editLimitCancel}>
                        취ㅤ소
                        </Button>
                        <Button
                            className="applyModalBtn"
                            key="submit"
                            htmlType="submit"
                            style={{backgroundColor:"#FFD560", marginLeft:20}}
                            type="primary" loading={editLoading}>
                        수ㅤ정
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
          </Row>
        </Modal>
        </>
    )
};

export default EditCountBt;
