import React, { useState, useRef }  from 'react';
import axios from "axios";
import { EditOutlined, UserAddOutlined } from '@ant-design/icons';
import { Input, InputNumber, Modal, Button, Typography, Form, Row, Col, Radio } from 'antd';
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";

const { Text, Title } = Typography;
const { useForm } = Form;

const OffUserAddBt = (props) =>{
    const { rowRecord, getNewData, getoffuser } = props;
    const [ addUserModal, setaddUserModal ] = useState(false);
    const [ AddLoading, setAddLoading] = useState(false);
    const [ confirm, setConfirm ] = useState(false);
    const [ userData, setUserData ] = useState(null);
    const [ errMsg, setErrorMsg ] = useState(false);
    const [ ettMessage, setErrorMessage ] = useState({
        title: '',
        subtitle: ''
    });

    const [ state, setState ] = useState({
        userNameReal: '',
        phoneNumber: '',
    })

const [form]= useForm();

// Modal Open, Close 
const onAddOffline = () => {
    form.resetFields();
    setaddUserModal(true);
    setUserData(null);
    };

// Cancel Button 
const editLimitCancel = () => {
    form.resetFields();
    setConfirm(false);
    setErrorMsg(false);
    setAddLoading(false);
    setaddUserModal(false);
};

// Button Loading Control
const loadingOff = () => {
    setTimeout(() => {
     return setAddLoading(false)
    }, 1000)
}

// user Search
const onUserSubmit = async(value) => {
    setAddLoading(true);
    await refreshToken().then((_idToken)=>{submitEvent(value, _idToken)});
  }

// entry Course
const entryCourse = async (_idToken) => {
    
    let courseId = rowRecord?.id;
    let data = {};
    data.type = 'OFFLINE';
    const offuserdata = Object.assign(data, userData);
    let dataitem = JSON.stringify(offuserdata);
    if(courseId != null){
        await axios.post(`${defaultApiAddress}/courses/${courseId}/entry`, dataitem , {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
        .then(res => {
            setAddLoading(false);
            getoffuser(res);
            getNewData(res);
            editLimitCancel();
            // getNewData(res);
        })
        .catch(err => {
            console.error(err.response.data);
            setConfirm(false);
            setErrorMessage({
                title:err?.response?.data?.message,
                subtitle: ''
            })
            setErrorMsg(true);
            setAddLoading(false);
        });
   }
}

// axios off user add event
const addUser = (data, _idToken) => {
    setConfirm(false);
    let userId = {};
    let values = JSON.stringify(data);
    axios.post(`${defaultApiAddress}/usersOffline`, values, {headers: {
            "Authorization" : _idToken,
            "Content-Type" : "application/json",
            "Access-Control-Allow-Origin" : "*",
        }})
    .then(res => {
        setErrorMsg(false);
        setConfirm(true);
        userId = (res.data);
        userId.userId = res.data.id;
        return setUserData(userId);
        // userConfirm();
    })
    .catch(err => console.error(err));
}

// axios search (조회) event
  const submitEvent = (value, _idToken) => {
        let tel = value.phoneNumber;
      if(value.userNameReal == null || value.phoneNumber == null){
          return false;
        }else{
            axios.get(`${defaultApiAddress}/usercheck?search=${tel}`, {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
            .then(res => {
                switch(res.data.results){
                    case 'onlineUser' : return (
                        setErrorMessage({
                            title: `${res?.data?.info?.provider} (으)로 가입한 온라인 회원입니다.`,
                            subtitle: `온라인으로 신청해 주세요.`,
                        }),
                        setErrorMsg(true)
                    ) 
                    break;
                    case 'offlineUser' : return setUserData(res?.data?.info), offlineUserUpdate(res?.data?.info.userId, value, _idToken), setErrorMsg(false); break;
                    case 'nonUser' : return addUser(value, _idToken); break;
                }
                
            })
            loadingOff();
        };
    }

    //오프라인 유저의 경우 업데이트를 통해 일반회원 여부 갱신
    const offlineUserUpdate = async (userId, data, _idToken) => {
        setConfirm(false);
        let values = await JSON.stringify(data);
        await axios.put(`${defaultApiAddress}/users/${userId}`, values ,{headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }}).then(res=>{
            setConfirm(true);
        });
    }
// 조회 or 신청 버튼 매칭 event
  const modalBtMatch = () => {
      if(!confirm){
          return (
            
                <Button
                    className="applyModalBtn"
                    // onClick={onUserSubmit}
                    htmlType="submit"
                    style={{backgroundColor:"#FFD560", marginLeft:20}}
                    disabled={AddLoading}
                    type="primary" loading={AddLoading}
                >
                조ㅤ회
                </Button>
            
          )
      }else{
        return ( 
        
            <Button
                className="applyModalBtn" 
                onClick={()=>{
                    setAddLoading(true);
                    refreshToken().then((_idToken)=>{entryCourse(_idToken)});}
                }
                style={{backgroundColor:"#FFD560", marginLeft:20}}
                disabled={AddLoading}
                type="primary" loading={AddLoading}>
                등ㅤ록
            </Button>
        
            )
      }
  }
    return (
        <>
        <a className="editText" onClick={onAddOffline}><UserAddOutlined /></a>
        <Modal
            visible={addUserModal}
            footer={null}    
        >
        <Title level={3} style={{textAlign: 'center', margin:'15px auto'}}>{ !confirm ? '오프라인 회원 추가' : '오프라인 회원 강좌 신청' }</Title>
          <Row className="add-table-row" justify="center" align="middle">
                <Row style={{maxWidth:"90%", margin: "25px auto", minWidth: "200px", position:"relative" }}>
                    <Form
                        form={form}
                        onFinish={onUserSubmit}
                    >
                    <Col className="useraddModalCol" span={24}>
                        <Form.Item
                            label="일반회원"
                            name="ym"
                            className="offuserformItem"
                            rules={[{ required: true, message: '일반회원 여부를 입력해 주세요.'}]}
                            >
                            <Radio.Group
                                disabled={confirm}
                            >
                                    <Radio value="true">YES</Radio>
                                    <Radio value="false">NO</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                       <Col className="useraddModalCol" span={24}>
                            <Form.Item
                                label="이름"
                                name="userNameReal"
                                className="offuserformItem"
                                rules={[{ required: true, message: '이름을 입력하지 않았습니다.'}]}
                                >
                                <Input 
                                    value={state.userNameReal}
                                    disabled={confirm}
                                    type="text"
                                    size="large"
                                    placeholder="이름을 입력해 주세요." />
                            </Form.Item>
                        </Col>
                        <Col className="useraddModalCol" span={24} style={{paddingBottom:'35px'}}>  
                            <Form.Item
                                label="연락처"
                                name="phoneNumber"
                                className="offuserformItem"
                                rules={[
                                        { pattern:'[0-9]{3}[0-9]{3,4}[0-9]{4}', message:'연락처를 정확히 입력해 주세요.'},
                                        { required: true, message: '연락처를 입력해 주세요.' }]}
                            >
                                <Input 
                                    value={state.phoneNumber}
                                    type="tel"
                                    disabled={confirm}
                                    size="large"
                                    maxLength={11}
                                    placeholder="연락처를 입력해 주세요." />
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{textAlign:"center", margin:0, position:"absolute", bottom: "75px", right:'0', width:'100%'}}>
                        { errMsg ?
                        <>
                                <Text type="danger" style={{fontWeight:900 }}>{ettMessage?.title}</Text><br />
                                <Text type="secondary" style={{fontWeight:600 }}>{ettMessage?.subtitle}</Text>
                        </>
                            : null }
                        
                        </Col>
                        <Col span={24} style={{textAlign: "center", margin:0, position:"absolute", bottom: "85px", right: "25%"}}>
                            { confirm ? 
                                <Text type="success" style={{fontWeight:900 }}>강의 신청이 가능한 사용자입니다.</Text>
                                : null
                            }
                        </Col>
                        <Form.Item style={{margin:"20px 0"}}>
                            <Button className="applyModalBtn" key="back" onClick={editLimitCancel}>
                            취ㅤ소
                            </Button>
                        { modalBtMatch() }
                        </Form.Item>
                    </Form>
                </Row>
          </Row>
        </Modal>
        </>
    )
};

export default OffUserAddBt;
