import React, { useState, useEffect }  from 'react';
import axios from "axios";
import { FileSearchOutlined, CloseOutlined } from '@ant-design/icons';
import PrintUserTable from './PrintUserTable';
import { Modal, Form } from 'antd';
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";

const UserListPrint = (props) =>{

    const { rowRecord, getNewData } = props;
    const [ addUserModal, setaddUserModal ] = useState(false);
    const [ listData, setListData ] = useState(null);
    const [ loading, setLoading ] = useState(true);


const onClickList = () => {
    if(listData !== null){
        setListData(null);
    }
    setaddUserModal(true);
    };

const printCancel = () => {
    setaddUserModal(false);
};

const closePrint = (value) => {
    setaddUserModal(value);
}

useEffect(() => {
    let ignore = false;
    let courseId = rowRecord?.id;
     const getDataList = async(_idToken) => {
        if(courseId != null){
             await axios.get(`${defaultApiAddress}/courses/${courseId}/entry?sort=name&odby=asc`, {headers: {
                     "Authorization" : _idToken,
                     "Content-Type" : "application/json",
                     "Access-Control-Allow-Origin" : "*",
                    }})
                    .then(res => {
                        if(!ignore){
                            setListData(res.data.results);
                            setLoading(false);
                        }
                    })
                    .catch(err => console.error(err));
                }
     }
    if (addUserModal != null && addUserModal != false) {
        refreshToken().then((_idToken)=>{getDataList(_idToken)});
    }
    return () => { ignore = true }
}, [addUserModal])

    return (
        <>
        <a className="printIcon" onClick={onClickList}><FileSearchOutlined /></a>
        <Modal
            centered
            closeIcon={<CloseOutlined />}
            visible={addUserModal}
            footer={null}
            closable={true}
            className="printModal"
        >
            <PrintUserTable rowRecord={rowRecord} closePrint={closePrint} listData={listData} printCancel={printCancel} loading={loading} />
        </Modal>
        </>
    )
};

export default UserListPrint;
