import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import axios from "axios";
import { Space, Button, Input, Table, Tag  } from 'antd';
import MainBanner from "../components/banner-manage/MainBanner";
import BookOfMonth from "../components/banner-manage/BookOfMonth";
import '../assets/styles/manage.css';

export default function BannerManage(props) {
    const {userObj, setUserObj} = props;
    return(
        <>
            <Switch>
                <Route path="/banner/main">
                    <MainBanner userObj={userObj} />
                </Route>
                {/*<Route path="/banner/book">*/}
                {/*    <BookOfMonth userObj={userObj} />*/}
                {/*</Route>*/}
            </Switch>
        </>
    );
}
