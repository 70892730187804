import React from "react";
import { useLocation, Link } from 'react-router-dom'
import { Breadcrumb, Layout, Typography } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

const { Header } = Layout;
const { Text, Title } = Typography
const HeaderComponent = (props) => {
    let location = useLocation();
    const locaInfo = location.state;

    const headerStyle = {
        height:"110px",
        width:"100%",
        padding:"25px 0",
        // background: 'transparent',
    }

    const headerNavStyle = {
        textAlign:"left",
        
    }
    return (
        <Header style={headerStyle} className="site-layout-background" >
           <Title level={3} >{ location.pathname === '/' ? '성북문화원 관리자 페이지' : locaInfo?.title } </Title>
           <Breadcrumb>
            <Breadcrumb.Item>
                    <Link to ="/">메인</Link>
                </Breadcrumb.Item>
                { locaInfo?.gruop != null ? 
                    <>
                     <Breadcrumb.Item>{locaInfo?.gruop}</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link 
                            to={{
                            pathname:locaInfo?.link,
                            state: {
                                group: locaInfo?.gruop,
                                title: locaInfo?.title,
                                link: locaInfo?.link
                            }
                            }}
                            
                        >{locaInfo?.title}</Link>
                    </Breadcrumb.Item>
                    </>
                : 
                <>
                <Breadcrumb.Item>
                    <Link 
                        to={{
                        pathname:locaInfo?.link,
                        state: {
                            group: locaInfo?.gruop,
                            title: locaInfo?.title,
                            link: locaInfo?.link
                        }
                        }}
                        
                    >{locaInfo?.title}</Link>
                </Breadcrumb.Item>
                </>
                }
                 
            </Breadcrumb>
        </Header>
    )
}

export default HeaderComponent;