import React from "react";
import KakaoLogin from "react-kakao-login";
import axios from "axios";
import {defaultApiAddress, keys} from "../../services/api.helpers";
import {authService} from "../../middleware/fb";

const client_token = keys.kakaoClientToken;

const KakaoSignin = ({ userObj, setUserObjFn, setIsLoginedFn, setErrorModalFn }) => {

    function requestToken (res) {
        const accessToken = res.response.access_token;
        const refreshToken = res.response.refresh_token;
        const uid = 'kakao:' + res.profile.id;
        const displayName = res.profile.properties.nickname;
        const email = res.profile.kakao_account.email;
        const photoUrl = res.profile.kakao_account.profile.thumbnail_image_url || res.profile.properties.thumbnail_image;

        let value = {
            uid: uid ? uid : '',
            displayName: displayName ? displayName : '',
            email: email ? email : '',
            photoUrl: photoUrl ? photoUrl : '',
            provider: 'custom',
        }
        setUserObjFn(() => {
            return {...value};
        });

        const instance = axios.create({
            baseURL: defaultApiAddress,
            headers: {
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 10000,
        });

        try {
            const response = instance.post(
                'auth/',
                value
            ).then(response => {
                let customToken = response.data.idToken;
                setIsLoginedFn(true);     // 로그인 됨

                authService.signInWithCustomToken(customToken)
                    .then(async (res) => {
                        const instance = await axios.create({
                            baseURL: defaultApiAddress,
                            headers: {
                                "Authorization" : res.user._lat,
                                "Content-Type" : "application/json",
                                "Access-Control-Allow-Origin" : "*",
                            },
                            timeout: 5000,
                        });
                        const response = await instance.get(
                            'users/' + res.user.uid
                        ).then(async response => {
                            if (response.data.userNameReal && response.data.phoneNumber) {
                                setUserObjFn(() => {
                                    let _userObj = {...userObj};
                                    _userObj.userNameReal = response.data.userNameReal;
                                    _userObj.phoneNumber = response.data.phoneNumber;
                                    _userObj.provider = response.data.provider;
                                    _userObj.uid = response.data.id;
                                    _userObj.email = response.data.email;
                                    _userObj.displayName = response.data.userName;
                                    _userObj.photoURL = response.data.photoUrl;
                                    return _userObj;
                                });
                            } else {
                                setUserObjFn(() => {
                                    let _userObj = {...userObj};
                                    _userObj.provider = response.data.provider;
                                    _userObj.uid = response.data.id;
                                    _userObj.email = response.data.email;
                                    _userObj.displayName = response.data.userName;
                                    _userObj.photoURL = response.data.photoUrl;
                                    return _userObj;
                                });
                            }
                        }).catch(async (error) => {
                            setErrorModalFn(true);
                        });
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log(error.response.data);
                        }
                    });
            });
        } catch (error) {
            if (error.response) {
                console.log(error.response.data);
            }
        }
    }

    return(
        <KakaoLogin
            token={client_token}
            onSuccess={requestToken}
            onFail={console.error}
            onLogout={console.info}
            render={({ onClick }) => {
                return (
                    <KakaoLogin
                        token={client_token}
                        onSuccess={requestToken}
                        onFail={console.error}
                        onLogout={console.info}
                        render={({ onClick }) => {
                            return (
                                <button
                                    className='before kakaoBtn'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClick();
                                    }}
                                >카카오 간편 로그인
                                </button>
                            );
                        }}
                    />
                );
            }}
        />
    );
};

export default KakaoSignin
