import React from "react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { Input, InputNumber, Table, Button, Typography, Form, Row, Col, Spin } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;
const columns = [
  {
    title: 'No',
    dataIndex: '',
    align: 'center',
    width: '5%',
    render: (text, record, index) => index+1
  },
  {
    title: '이름',
    dataIndex: 'entryUser',
    width: '13%',
    align: 'center',
    key: 'userNameReal',
    render: i => i?.userNameReal.length > 7 ? <span>{i?.userNameReal.slice(0,7)}...</span> : i?.userNameReal
  },
  {
    title: '연락처',
    dataIndex: 'entryUser',
    width: '17%',
    align: 'center',
    key: 'phoneNumber',
    render: i => i?.phoneNumber,
  },
  {
    title: '신청방법',
    dataIndex: 'type',
    width: '10%',
    align: 'center',
    key: 'type',
    render: i => {
      if(i === 'ONLINE'){
        return '온라인';
      }else if(i === 'OFFLINE'){
        return '오프라인';
      }
    }
  },
  {
    title: '메모',
    dataIndex: 'memo',
    width: '40%',
    key: 'memo',
    // render: i => i.length > 20 ? <span>{i.slice(0,20)}...</span> : i
  },
];

class ComponentToPrint extends React.Component {

  render() {
    // console.log(this.props.rowRecord);
    // console.log(this.props.listData);
    let courseData = this.props.rowRecord;
    return (
      <div className="printTableWrap">
        <Row className="table-header">
          {/* 1줄 */}
          <Row className="text-row">
          {/* <Row style={{padding: '10px 20px'}}> */}
            <Row className="table-header-col">
              <Col span={6} className="left-col"><Text className="table-header-text">강좌명</Text></Col>
              <Col span={18} className="right-col"><Text className="table-header-text">ㅤ{courseData?.title}</Text></Col>
            </Row>
            <Row className="table-header-col" style={{borderLeft:'1px solid #b2b2b2'}}>
              <Col span={6} className="left-col"><Text className="table-header-text">수업 기간</Text></Col>
              <Col span={18} className="right-col"><Text className="table-header-text">ㅤ{moment(courseData?.startDate).format('YY/MM/DD')} - {moment(courseData?.endDate).format('YY/MM/DD')}</Text></Col>
            </Row>
          </Row>
          {/* 2줄 */}
          <Row className="text-row">
          <Row className="table-header-col">
              <Col span={6} className="left-col"><Text className="table-header-text">강사명</Text></Col>
              <Col span={18} className="right-col"><Text className="table-header-text">ㅤ{courseData?.instructor}</Text></Col>
            </Row>
            <Row className="table-header-col" style={{borderLeft:'1px solid #b2b2b2'}}>
              <Col span={6} className="left-col"><Text className="table-header-text">수업 시간</Text></Col>
              <Col span={18} className="right-col"><Text className="table-header-text">ㅤ{courseData?.info?.startTime} - {courseData?.info?.endTime}</Text></Col>
            </Row>
          </Row>
          {/* 3줄 */}
          <Row className="text-row" style={{borderBottom:'none'}}>
            <Row className="table-header-col">
              <Col span={6} className="left-col"><Text className="table-header-text">강의실</Text></Col>
              <Col span={14} className="right-col"><Text className="table-header-text">ㅤ{courseData?.info?.lectureRoom}</Text></Col>
            </Row>
            <Row className="table-header-col" style={{borderLeft:'1px solid #b2b2b2'}}>
              <Col span={6} className="left-col"><Text className="table-header-text">총 인원</Text></Col>
              <Col span={6} className="right-col"><Text className="table-header-text"> {courseData?.userLimitAll}</Text></Col>
              <Col span={6} className="left-col" style={{borderLeft:'1px solid #b2b2b2'}}><Text className="table-header-text" >신청 인원</Text></Col>
              <Col span={6} className="right-col"><Text className="table-header-text">ㅤ{courseData?.entryCountOff + courseData?.entryCountOn}</Text></Col>
            </Row>
          </Row>
        </Row>
        <Table
            className="printTable"
            dataSource={this.props.listData}
            // dataSource={this.props.rowRecord}
            columns={columns}
            pagination={false}
            rowKey="index"
            bordered
            size="small"
        />
      </div>
    );
  }
}

class PrintUserTable extends React.Component{
  render() {
    
    return (
      <div>
            <div className="buttonWrap">
            <Title level={3} className="print-title">수강생 목록</Title> 
              <Button className="printBt-cancel" key="back" onClick={this.props.printCancel}>
                취 소
              </Button>
              <ReactToPrint
                style={{position: 'relative'}}
                trigger={() => 
                <Button type="primary" className="printBt">
                  인 쇄
                  {/* <PrinterOutlined /> */}
                </Button>}
                content={() => this.componentRef}
              />
          </div>
        <ComponentToPrint ref={(el) => (this.componentRef = el)} rowRecord={this.props.rowRecord} listData={this.props.listData}/>
      </div>
    );
  }
}

export default PrintUserTable;
