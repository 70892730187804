import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import { Layout, Button, Space, Table, Alert, message } from "antd";
import { defaultApiAddress } from "../../services/api.helpers";
import LoadingSpin from "../layout/LoadingSpin";

const columns = [
    {
      title: '번호',
      width:'7.5%',
      dataIndex:'listNo',
      align: 'center',
      render: (i = 0) => i++, 
      key:'listNo',
    },
    {
    title: '제목',
    dataIndex:'title',
    width:'55%',
    key:'title',
    render: text => text.length > 20 ? <a style={{color: '#000000'}}>{text.slice(0,30)}...</a> : text,
    },
    {
    title: '작성일시',
    dataIndex:'createdAt',
    width:"15%",
    align: 'center',
    key:'createdAt',
    render: item => moment(item).format('YYYY/MM/DD'),
    }
];

export default function GalleryListText(props) {
  const { type } = props;
  const [ locationState, setLocationState ] = useState(null);
  const { cate } = useParams();
  const history = useHistory();
  const [ data, setData ] = useState(null);
  const [ totalCount, setTotalCount ] = useState(null);

  const success = () => {
    setTimeout(() => {
      setLocationState(null);
    },[4000])
    if(locationState.bbsId == null){}
      return (
        <Alert
            className="alert-result-true"
            message="등록 완료"
            description="정상적으로 등록되었습니다."
            type="success"
            showIcon
            closable={true}
            />
        )
  };



  const successDelete = () => {
    setTimeout(() => {
      setLocationState(null);
    },[4000])
      return (
        <Alert
            className="alert-result-true"
            message="삭제 완료"
            description="정상적으로 삭제되었습니다."
            type="success"
            showIcon
            closable={true}
            />
        )
  };

  const getDataAPI = () => {
      setData(null);
      axios.get(`${defaultApiAddress}/boards?type=${type}&cate=${cate}`)
      .then(res => {
          setData(res.data.results);
          const result = res.data.results;
          const total = res.data.totalCount;
          let newData = [];

          const reverseArray = result.reverse();
          console.log(reverseArray);

          reverseArray.map((i, index) => {
              let listData;
              return listData = Object.assign({...i, 'listNo': index + 1}),
                  newData.push(listData)
          })
          const dataSort = newData.reverse();
          if(dataSort !== null){
              setData(dataSort);
          }
      }).catch((error)=>{
          if (error.response) {
              message.error(error.response.data.message);
          }
      });
  };

    useEffect(() => {
        getDataAPI();
        setLocationState(history?.location?.state);
        console.log(history?.location?.state)
    }, [history.location]);

  const onRow = (rowData, rowIndex) => {
      return {
          onClick: () => {
              history.push(`/${type}/${cate}/detail/${rowData.id}`);
          }
      }
  }

  const onClickWrite = () => {
    history.push(`/${type}/${cate}/write/`);
  }

  return (
      <>
          { data == null ? <LoadingSpin /> :
              <div>
                   { locationState?.update ? success() : locationState?.delete ? successDelete() : null }
                  <div className="List" style={{ maxWidth:"1200px", margin:"24px auto 0", padding:"0 24px 24px"}}>
                      <Space align="end" size="small" direction="vertical">
                          <Button onClick={onClickWrite} type="primary" style={{fontWeight:600}}>글쓰기</Button>
                          <Table
                              tableLayout="fixed"
                              expandRowByClick={true}
                              columns={columns}
                              dataSource={data}
                              onRow={onRow}
                              rowKey={totalCount}
                              pagination={{
                                  pageSize:12
                              }}
                          />
                      </Space>
                  </div>
              </div>
          }
      </>
  );
}
