import React, { useEffect, useState } from 'react';
import { DatePicker, Space, Skeleton } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ko_KR';


const { RangePicker } = DatePicker;



const DatePickerAd = (props) => {

  const {getDate, loading, mode, datePick } = props;
  const [ startData, setStartData ] = useState('2021-00-00');
  const [ endData, setEndData ] = useState('2021-00-00');
  const dateFormat = 'YYYY-MM-DD';
  
  useEffect(() => {
    if(mode.type !== 'new'){
      if(loading === false){
        if(datePick.startDate !== null){
          setStartData(moment(datePick.startDate, 'YYYY-MM-DD'));
          setEndData(moment(datePick.endDate, 'YYYY-MM-DD'));
        }
      }
    }
  }, [datePick])
  

const EditMode = () => {
  if(startData === '2021-00-00'){
    if( datePick.startDate !== null ){
     return <Skeleton.Input style={{ width: 335 }} active size />
    }else if( datePick.startDate == null ){
      <RangePicker
        onChange={onChange}
        format={dateFormat}
        locale={locale}
      /> 
    }
  }else{
    return (
    <RangePicker
          onChange={onChange}
          defaultValue={[moment(startData, 'YYYY-MM-DD'), moment(endData, 'YYYY-MM-DD')]}
          format={dateFormat}
          locale={locale}
    />
    );
  }
  };

const WriteMode = () => {
  return (
    <RangePicker
          onChange={onChange}
          format={dateFormat}
          locale={locale}
        /> 
  )
}


  function onChange(value, dateString) {
    console.log(value);
    console.log('Formatted Selected Time: ', dateString);
    getDate(dateString);
  }
  
  return (
    <>
     { mode.type === 'new' ? WriteMode() : EditMode() }
    </>
  );
}

export default DatePickerAd;