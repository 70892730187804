import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams} from 'react-router-dom'
import axios from "axios";
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import WriteEditor from "../board/WriteEditor";
import LoadingSpin from "../layout/LoadingSpin";
import WarningModalBtn from "../layout/WarningModalBtn";
import { Button, Row, Col, Form, Input, Select, Typography } from 'antd';
import ThumbUploadImage from './ThumbUploadImage';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Title, Text} = Typography;

export default function GalleryWriteImage(props) {

    const { userObj, type, name } = props;
    const { cate, bbsId } = useParams();
    const [ mode, setMode ] = useState({
        type: null
    });
    const [ loading, setLoading ] = useState(true);
    const history = useHistory();
    const [ detailData, setDetailData ] = useState({
        id: null,
        type: type,
        title: null,
        category:null,
        description: null,
        mediaUrl:null,
        fileUrl:null,
        url:null,
        createdAt:null,
        updatedAt:null,
        deletedAt:null,
        writer:null,
        depth1:null,
        depth2:null,
    });

    const [form] = Form.useForm();


    const getDataAPI = () => {
        axios.get(`${defaultApiAddress}/boards/${bbsId}`)
                .then((response) => {
                    console.log(response.data);
                    response.data.description = response.data?.description?.replace(/(\t)/gi,"ㅤ").replace(/\s{2}/g,"ㅤ");      // escape character 탭을 그에 걸맞는 공백기호로 변경
                    form.setFieldsValue(response.data);
                    setDetailData(response.data);
                })
                .catch((error) => console.log(error))
        };


    useEffect(() => {
    if (!bbsId) {
        setLoading(false);
        console.log('param 없다 - 신규 작성 모드');
        return setMode({type:'new'});
    } else {
        getDataAPI();
        return setMode({type:bbsId});
    }
    }, []);

    // 업로드된 썸네일 url 가져오는거.
    const [thumbUrlData, setthumbUrlData] = useState(null);

    const getThumbData = (media) => {
            setthumbUrlData(media);
            console.log(media);
        }



  const handleGoBack = () => {
    history.push(`/${type}/${cate}/`);
  };

  const [desc, setDesc] = useState('');


  function onEditorChange(value) {
      setDesc(value)
  }

useEffect(() => {
        if(mode.type !== 'new'){
            setTimeout(() => {
                setLoading(false);
            }, 1200);
        }
  }, [detailData])

  const [ imgErr, setImgErr ] = useState(false);

  useEffect(() => {
      if(thumbUrlData == null ){
          setImgErr(true);
      }else{
          setImgErr(false);
      }
  }, [thumbUrlData]);

  const onSubmit = (values) => {
      values.description = values?.description?.replace(/(\t)/gi,"ㅤ").replace(/\s{2}/g,"ㅤ");      // escape character 탭을 그에 걸맞는 공백기호로 변경 이렇게 하면 기존게 안걸림
      console.log(mode.type)
    if(imgErr == false){
        values.type = type;
        values.category = cate;
        values.mediaUrl = thumbUrlData;
        console.log(values)
    if (mode.type === 'new') {
        refreshToken().then((_idToken)=>{postDataApi(values, _idToken)});
        } else if(mode.type !== 'new') {
        refreshToken().then((_idToken)=>{putDataApi(values, _idToken)});
        }
    }
  };

    const postDataApi = (values, _idToken)=>{
        axios.post(`${defaultApiAddress}/boards/`, values, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then((res) => {
                form.setFieldsValue(res.data.id);
                console.log('board created');
                console.log(values);
                setLoading(true);
                history.push({
                    pathname:`/${type}/${cate}/`,
                    state: {update: true}
                });
            })
            .catch((err) => console.log(err))
    }

    const putDataApi = (values, _idToken)=>{
        axios.put(`${defaultApiAddress}/boards/${mode.type}`, values, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(function (response){
                form.setFieldsValue(response.data.id);
                console.log('board updated');
                console.log(values);
                history.push({
                    pathname:`/${type}/${cate}/`,
                    state: {update: true,
                        id: bbsId
                    }
                });
            })
            .catch(function (err) {
                if(err.response){
                    console.log(err.response.data);
                }
            })
    }

    const HandleFinishFaild = (errorInfo) => {
      console.log(errorInfo);
  }

  return (
      <>
     { loading && <LoadingSpin /> }
    <Row justify="center" className="writeWrap">
        <Col span={3}>
            <Button onClick={handleGoBack} type="text">
                <ArrowLeftOutlined
                    style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                />
                <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
            </Button>
        </Col>
        <Col span={18}>
            <Text keyboard style={{fontSize:'1.2rem'}}>{name}</Text>
            <Title level={3} style={{margin:'10px auto 50px'}}>{ (mode.type === 'new') ? '새로운 게시글 등록' : '기존 게시글 수정' }</Title>
        </Col>
        <Col span={3}>
        </Col>
        <Form
            labelCol={{ span: 12 }}
            name="writeForm"
            form={form}
            layout="vertical"
            className="writeForm"
            onFinish={onSubmit}
            onFinishFailed={HandleFinishFaild}
            >

            <div className="mainInfo">
            <Form.Item
                label="제목"
                name="title"
                placeholder="제목을 입력해 주세요."
                rules={[{ required: true, message: '제목을 입력하지 않았습니다.' }]}
                >
                <Input />
            </Form.Item>
            <Row justify="start" className="galleryWriteImg">
            { cate === 'cultural' || cate === 'hfigure'?
                <>
                <Col className="thumbCol" span={24} xs={24} sm={24} md={12} lg={10} xl={10} xxl={10}>
                    <Form.Item
                        label="썸네일 이미지"
                        name="mediaUrl"
                        valuePropName="mediaUrl"
                        value={detailData.mediaUrl}
                        >
                        <ThumbUploadImage
                            mode={mode}
                            thumbUrlData={thumbUrlData}
                            value={ detailData.mediaUrl }
                            getThumbData={getThumbData}
                            detailData={detailData}
                            />
                    </Form.Item>
                    { imgErr ? <Text type="danger" className="imgErrMsg">이미지를 등록해 주세요.</Text> : null }
                </Col>
                <Col className="thumbCol" span={24} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                            className="leftColG"
                            label="시대별"
                            name="depth1"
                            // rules={[{ required: true, message: '분류1을 선택하지 않았습니다.' }]}
                        >
                        { cate === 'cultural' ?
                                <Select>
                                    <Option value="고대">고대</Option>
                                    <Option value="고려시대">고려시대</Option>
                                    <Option value="조선시대">조선시대</Option>
                                    <Option value="일제강점기">일제강점기</Option>
                                    <Option value="대한민국">대한민국</Option>
                                </Select>
                                : cate === 'hfigure' ?
                                <Select>
                                    <Option value="고대">고대</Option>
                                    <Option value="고려시대">고려시대</Option>
                                    <Option value="조선시대">조선시대</Option>
                                    <Option value="일제강점기">일제강점기</Option>
                                    <Option value="대한민국">대한민국</Option>
                                </Select>
                                : null }
                        </Form.Item>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <Form.Item
                                className="rightColG"
                                label={ cate === 'cultural' ? "종목별" : "지역별" }
                                name="depth2"
                                // rules={[{ required: true, message: '분류1을 선택하지 않았습니다.' }]}
                                >
                                { cate === 'cultural' ?
                                    <Select>
                                        <Option value="국보">국보</Option>
                                        <Option value="보물">보물</Option>
                                        <Option value="사적">사적</Option>
                                        <Option value="명승">명승</Option>
                                        <Option value="천연기념물">천연기념물</Option>
                                        <Option value="국가무형유산">국가무형유산</Option>
                                        <Option value="국가민속문화유산">국가민속문화유산</Option>
                                        <Option value="국가등록문화유산">국가등록문화유산</Option>
                                        <Option value="시도유형문화유산">시도유형문화유산</Option>
                                        <Option value="시도무형유산">시도무형유산</Option>
                                        <Option value="시도기념물">시도기념물</Option>
                                        <Option value="시도민속문화유산">시도민속문화유산</Option>
                                        <Option value="시도등록문화유산">시도등록문화유산</Option>
                                        <Option value="문화유산자료">문화유산자료</Option>
                                        <Option value="서울미래유산">서울미래유산</Option>
                                        <Option value="국가지정기록물">국가지정기록물</Option>
                                    </Select>
                                : cate === 'hfigure' ?
                                    <Select>
                                        <Option value="길음동">길음동</Option>
                                        <Option value="돈암동">돈암동</Option>
                                        <Option value="동선동">동선동</Option>
                                        <Option value="보문동">보문동</Option>
                                        <Option value="삼선동">삼선동</Option>
                                        <Option value="석관동">석관동</Option>
                                        <Option value="성북동">성북동</Option>
                                        <Option value="안암동">안암동</Option>
                                        <Option value="월곡동">월곡동</Option>
                                        <Option value="장위동">장위동</Option>
                                        <Option value="정릉동">정릉동</Option>
                                        <Option value="종암동">종암동</Option>
                                    </Select>
                                : null }
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
               </>
               :
               <>
               <Col span={24}>
                   <Form.Item
                       label="썸네일 이미지"
                       name="mediaUrl"
                       valuePropName="mediaUrl"
                       value={detailData.mediaUrl}
                       >
                       <ThumbUploadImage
                           mode={mode}
                           thumbUrlData={thumbUrlData}
                           value={ detailData.mediaUrl }
                           getThumbData={getThumbData}
                           detailData={detailData}
                           />
                   </Form.Item>
                   { imgErr ? <Text type="danger" className="imgErrMsgWidth">이미지를 등록해 주세요.</Text> : null }
               </Col>
               </>
               }
            </Row>
            </div>
            <Form.Item
                style={{marginBottom:40}}
                label="내용"
                name="description"
                placeholder="내용을 입력해 주세요."
                rules={[{ required: true, message: '내용을 입력하지 않았습니다.' }]}
                >
                <WriteEditor value={desc} onChange={onEditorChange} />
            </Form.Item>
            <Form.Item>
                <WarningModalBtn handleGoBack={handleGoBack} />
                <Button type="primary" htmlType="submit" className="write-submit-button">
                    { mode.type === 'new' ? '등록' : '저장' }
                </Button>
            </Form.Item>
        </Form>
      </Row>
    </>
  );
}
