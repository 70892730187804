import React, { useState } from 'react';
import { Modal, Button, Result } from 'antd';
import { DeleteTwoTone, setTwoToneColor } from '@ant-design/icons';

export default function DeleteModalBtn(props) {
    const { boardDeleteConfirm } = props;
    const [ isModalVisible, setIsModalVisible ] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
      };

    const boardDeleteCancled = () => {
        setIsModalVisible(false);
    };

    setTwoToneColor('#F55F4A');
    // getTwoToneColor();
    
      return (
        <>
          <Button onClick={showModal} danger type="text">삭제하기</Button>
            <Modal 
                visible={isModalVisible} 
                onOk={boardDeleteConfirm} 
                onCancel={boardDeleteCancled}
                okText="확인"
                cancelText="취소"
                okButtonProps={{size:'large', danger:'true' }}
                cancelButtonProps={{ size: 'large' }}
                >
                 <Result
                  icon={<DeleteTwoTone />}
                  title="해당 게시글이 삭제됩니다."
                  subTitle="게시글을 삭제하겠습니까?"
                />
            </Modal>
        </>
      );
};