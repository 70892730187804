import React, { useState, useEffect, useRef } from "react";
import {Link, useHistory, useParams} from 'react-router-dom'
import axios from "axios";
import WriteEditor from "../board/WriteEditor";
import LoadingSpin from "../layout/LoadingSpin"
import WarningModalBtn from "../layout/WarningModalBtn";
import { Button, Row, Col, Form, Input, Upload, message } from 'antd';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";

export default function AttachWrite(props) {

    const [fileList, setFileList] = useState([]);
    const [localToken, setLocalToken] = useState(null);

    const config = {
        customRequest: ({file, onSuccess, onError}) => {
            let formData = new FormData();
            formData.append('file', file)
            axios.post(`${defaultApiAddress}/uploads/`, formData, {headers:{
                    "Authorization" : localToken,
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then(res => {
                    console.log(res);
                    onSuccess(res.data.url);    // fileList에 각 배열의 response에 url 반환
                })
                .catch(error => {
                    console.log(error);
                    onError({event:error});
                    if(error.response){
                        console.log(error.response);
                        if (error.response.data.errorCode.code === 'FILE_SIZE_OVER') {
                            message.error(`업로드 하려는 파일 용량이 20MB 이상입니다.`);
                            onError();
                        }
                    }
                });
        },
        listType: 'text',
        maxCount: 3,    // 최대 업로드 가능 파일 수
        onChange: function(files) {
            setFileList(files.fileList);
        },
        fileList: fileList,
        onPreview: (e)=>{   // 파일을 클릭했을때, 주소를 받아서 api 주소로 재조합->클릭(안하면 호스트 주소대로 다운로드 됨)
            console.log(e);
            axios({
                url: defaultApiAddress+e.response, //your url
                headers:{
                        "Authorization" : localToken,
                        "Access-Control-Allow-Origin" : "*",
                    },
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                let filename = e.name;
                let data = response.data;
                let blob = new Blob([data]);
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    let elem = window.document.createElement('a');
                    elem.href = window.URL.createObjectURL(blob);
                    elem.download = filename;
                    document.getElementsByTagName('body')[0].appendChild(elem);
                    elem.click();
                    document.getElementsByTagName('body')[0].appendChild(elem);
                }
            });
        },
        showUploadList: {
            showDownloadIcon: true,
            downloadIcon: '다운로드 ',
            showRemoveIcon: true,
            // removeIcon: <StarOutlined onClick={e => console.log(e, 'custom removeIcon event')} />,
        }
    }

    const { userObj, type } = props
    const history = useHistory();
    const { cate, bbsId } = useParams();
    const [ mode, setMode ] = useState({
        type: null
    });
    const [ loading, setLoading ] = useState(true);
    const [ detailData, setDetailData ] = useState({
        id: null,
        type:type,
        category:cate,
        title: null,
        writer: '성북문화원',
        createdAt:null,
        description: null,
        fileUrl: []
    });

    useEffect(() => {
        refreshToken().then((_idToken)=>{setLocalToken(_idToken)});
        if (!bbsId) {
            setLoading(false);
            console.log('param 없다 - 신규 작성 모드');
            return setMode({type:'new'});
        } else {
            getDataAPI();
            return setMode({type:bbsId});
        }
        }, []);

    const getDataAPI = () => {
        axios.get(defaultApiAddress+`/boards/${bbsId}`)
            .then((response) => {
                form.setFieldsValue(response.data);
                setDetailData(response.data);
                setFileList(response.data.fileUrl.fileList);    // filelist는 별도로 받아서 뿌려주기
            })
            .catch((error) => console.log(error));
    };

  const handleGoBack = () => {
    history.push(`/${type}/${cate}/`);
  };

  const [desc, setDesc] = useState('');

  function onEditorChange(value) {
      setDesc(value)
  }

  const [form] = Form.useForm();

  useEffect(() => {
     bbsId&&form.setFieldsValue({
            id: detailData.id,
            type:type,
            category:cate,
            title: detailData.title,
            writer: '성북문화원',
            createdAt: detailData.createdAt,
            description: detailData.description ? detailData?.description?.replace(/(\t)/gi,"ㅤ").replace(/\s{2}/g,"ㅤ") : '',
            fileUrl: fileList
          })
     // loading spinner setting
     if(mode.type !== 'new'){
        setTimeout(() => {
            setLoading(false);
        }, 1200);
    }
  }, [detailData])

  const HandleFinish = (values) => {
      values.description = values?.description?.replace(/(\t)/gi,"ㅤ").replace(/\s{2}/g,"ㅤ");      // escape character 탭을 그에 걸맞는 공백기호로 변경 이렇게 하면 기존게 안걸림

      // submit시 fileList에서 불필요한 정보 제거 후 db에 저장
      let _fileList = [];
      fileList.forEach((e)=>{
          if (!!e.response) {   // e.response가 있는것만 == 성공한 것만
              let _wrap = {"uid": e.uid, "response": e.response, "name": e.name}; // onSuccess(res.data.url) 에서 받아온 url값 ex) "/uploads/58d56dc2-202f-472a-b309-c21e74f05a4c/1%EF%BF%BD%EF%BF%BD03.wtc"
              _fileList.push(_wrap);
          }
      });
      values.fileUrl = {'fileList':_fileList};      // fileList에 각 파일당 .url을 할당해야함. .response에서 던진 url을 세팅

    values.type = type;
    values.category = cate;

      if (mode.type === 'new') {
          refreshToken().then((_idToken)=>{postDataApi(values, _idToken)});
      }else{
          refreshToken().then((_idToken)=>{putDataApi(values, _idToken)});
      }
  }

    const postDataApi = (values, _idToken)=>{
        axios.post(`${defaultApiAddress}/boards/`, values, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then((response) => {
                form.setFieldsValue(response.data);
                console.log('board created');
                console.log(values);
                history.push({
                    pathname:`/${type}/${cate}/`,
                    state: {update: true,
                        id: bbsId
                    }
                });
            });
    }

    const putDataApi = (values, _idToken)=>{
        axios.put(`${defaultApiAddress}/boards/${mode.type}`, values, {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then((response) => {
                form.setFieldsValue(response.data);
                console.log(response.data);
                console.log('board updated');
                console.log(values);
                history.push({
                    pathname:`/${type}/${cate}/`,
                    state: {update: true,
                        id: bbsId
                    }
                });
            })
            .catch(function (err) {
                if(err.response){
                    console.log(err.response.data);
                }
            })
    }

  const onHandleBack = () => {
    history.push(`/${type}/${cate}/`);
};

  const HandleFinishFaild = (errorInfo) => {
      console.log(errorInfo);
  }

  return (
    <>
    { loading && <LoadingSpin /> }
    <Row justify="center" className="detailMainInfoWrap">
        <Form
            name="writeForm"
            form={form}
            layout="vertical"
            className="writeForm"
            onFinish={HandleFinish}
            onFinishFailed={HandleFinishFaild}
        >
        <Col span={2} offset={2}>
            <Button onClick={onHandleBack} type="text">
                <ArrowLeftOutlined
                    style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                />
                <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
            </Button>
        </Col>
            <Row className="detailMainInfoWrap">
                <Col span={16} offset={3}>
                    <h2>게시판 글쓰기</h2>
                </Col>
                <Col span={3}>
                    <Form.Item
                        label="작성자"
                        name="writer"
                        initialValue="성북문화원"
                        style={{paddingLeft:20, marginTop:5}}
                        >
                        <Input value="성북문화원" style={{color:'#999999', fontSize:16, marginLeft:"-10px", marginTop:"-10px"}} disabled bordered={false} />
                    </Form.Item>
                </Col>
            </Row>
            <Row  justify="center">
                <Col span={18}>
                    <Form.Item
                        label="제목"
                        name="title"
                        placeholder="제목을 입력해주세요."
                        rules={[{ required: true, message: '제목을 입력하지 않았습니다.' }]}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                </Col>

                <Col span={18}>
                    <Form.Item
                        label="파일 첨부"
                    >
                        <Upload {...config} >
                            <Button icon={<UploadOutlined  />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        style={{marginBottom:40}}
                        label="내용"
                        name="description"
                        placeholder="내용을 입력해주세요."
                        rules={[{ required: true, message: '내용을 입력하지 않았습니다.' }]}
                    >
                        <WriteEditor value={desc} onChange={onEditorChange} />
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item>
                        <WarningModalBtn handleGoBack={handleGoBack} />
                        <Button type="primary" htmlType="submit" className="write-submit-button">
                            { mode.type === 'new' ? '등록' : '저장' }
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
      </Row>
    </>
  );
}
