import React, { useEffect } from "react";
import {useHistory} from "react-router-dom";
import '../assets/styles/Layout.css';
import Auth from "../components/common/Auth";
import {authService} from "../middleware/fb";

export default function Main({userObj, setUserObjFn, setIsAdminFn, setInitFn, isAdmin, setErrorModalFn}) {

    const history = useHistory();
    const onLogoutClick = () => {
        authService.signOut();
        history.push("/");
    };

    useEffect(()=>{
        // 익플 막기 - 익플 폴리필 미적용으로 이쪽으로 들어올 확률은 낮고, public/index.html에서 막지만, 혹시 몰라서 추가로 확인차 막아둠 by Sarc
        if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            window.location = 'microsoft-edge:' + window.location;

            setTimeout(function() {
                window.location = 'https://go.microsoft.com/fwlink/?linkid=2135547';
            }, 100);
        } else {
            // console.log('브라우져-',navigator.userAgent);
        }
    },[]);

    return(
        <>
            { userObj && isAdmin ? (
                <>
                    <span>안녕하세요.</span>
                    <br />
                    <span>{userObj.displayName} 님</span>
                    <br /><br />

                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setIsAdminFn(false);
                            setUserObjFn(null);
                            setInitFn(false);
                            onLogoutClick();
                        }}
                    >로그아웃</a>
                    <Auth userObj={userObj} setUserObjFn={setUserObjFn} setErrorModalFn={setErrorModalFn}/>
                </>
            ) : (
                <>
                    <h1 style={{ margin:'10vh auto 2vh', fontWeight:'bold' }}>성북문화원 관리자 페이지</h1>
                    <br /><br />
                    <Auth userObj={userObj} setUserObjFn={setUserObjFn} setErrorModalFn={setErrorModalFn}/>
                </>
            )}
        </>
    );
}
