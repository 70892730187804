import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, Route, Switch, useParams } from "react-router-dom";
import axios from "axios";
import { Space, Button, Input, Table, Tag  } from 'antd';
import BasicList from "../components/basic/BasicList";
import BasicDetail from "../components/basic/BasicDetail";
import BasicWrite from "../components/basic/BasicWrite";


export default function Basic(props) {
const {userObj, setUserObj} = props;
const type = 'basic'
    return(
      <>
        <Switch>
            <Route exact path="/basic/:cate/write/">
                <BasicWrite userObj={userObj} type={type} />
            </Route>
            <Route path="/basic/:cate/write/:bbsId">
                <BasicWrite userObj={userObj} type={type} />
            </Route>
            <Route path="/basic/:cate/detail/:bbsId">
                <BasicDetail userObj={userObj} type={type} />
            </Route>
            <Route path="/basic/:cate/">
                <BasicList userObj={userObj} type={type} />
            </Route>
        </Switch>
      </>
    );
}
