import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams} from 'react-router-dom'
import axios from "axios";
import {Space, Button, Input, Table, Tag, Pagination, Breadcrumb, message, Alert} from 'antd';
import LoadingSpin from "../layout/LoadingSpin";
import moment from 'moment';
import {defaultApiAddress} from "../../services/api.helpers";

const columns = [
    {
        title: 'No',
        dataIndex:'listNo',
        width:'10%',
        key:'listNo',
        align: 'center',
    },
    {
        title: '제목',
        dataIndex:'title',
        width:'60%',
        key:'title',
        render: text => text.length > 20 ? <a style={{color: '#000000'}}>{text.slice(0,30)}...</a> : text,
    },
    {
        title: '작성자',
        dataIndex:'writer',
        width:"10%",
        key:'writer',
        align: 'center',
        value:'성북문화원',
    },
    {
        title: '작성일시',
        dataIndex:`createdAt`,
        width:"12%",
        align: 'center',
        key:'createdAt',
        className:"grayTxt",
        render: item => moment(item).format('YYYY/MM/DD'),
    },
];

export default function BasicList(props) {

    const { userObj, type } = props;
    let { cate } = useParams();
    console.log(type, cate)
    const history = useHistory();
    const [ locationState, setLocationState ] = useState(null);

    const onClickWrite = () => {
        history.push(`/${type}/${cate}/write/`);
      }
    
      const [data, setData] = useState([]);
      
      const getDataAPI = async () => {
          setData(null);
          axios.get(defaultApiAddress+`/boards?type=${type}&cate=${cate}`)
              .then(res => {

                  setData(res.data.results);
                  const result = res.data.results;
                  const total = res.data.totalCount;
                  let newData = [];

                  const reverseArray = result.reverse();
                  console.log(reverseArray);

                  reverseArray.map((i, index) => {
                      let listData;
                      return listData = Object.assign({...i, 'listNo': index + 1}),
                          newData.push(listData)
                  })
                  const dataSort = newData.reverse();
                  if(dataSort !== null){
                      setData(dataSort);
                  }
              }).catch((error)=>{
              if (error.response) {
                  message.error(error.response.data.message);
              }
          });
      };
    console.log(moment(data?.createdAt).format('YYYY/MM/DD'));
    const success = () => {
        return (
            <>
                <Alert
                    className="alert-result-true"
                    message="등록 성공"
                    description="게시글이 정상적으로 등록되었습니다."
                    type="success"
                    showIcon
                />
            </>
        )
    };

    const submitResultFalse = () => {
        return (
            <>
                <Alert
                    className="alert-result-false"
                    message="등록 실패"
                    description="게시글 등록에 실패하였습니다."
                    type="error"
                    showIcon
                />
            </>
        )
    };

    const setUpdate = () => {
        setLocationState(null);
        success();
        // return window.location.reload();
    }

    const successDelete = () => {
        message.success({
            content: '게시글이 삭제되었습니다.',
            className: 'custom-class',
            style: {
                marginTop: '25vh',
            },
        });

    };

    const setDelete = () => {
        setLocationState(null);
        successDelete();
    }

    useEffect(() => {
        locationState?.update&&setUpdate();
        locationState?.delete&&setDelete();
    },[locationState])

    useEffect(() => {
        getDataAPI();
        // console.log(history?.location?.state);
        setLocationState(history?.location?.state);
    }, [history.location]);

    const onRow = (rowData, rowIndex) => {
        // const currentData = rowData;
          return {
              onClick: (event) => {
                  //  rowData: row의 data
                  //  rowIndex: row의 인덱스
                  //  event: event prototype
                  history.push({
                    pathname: `/${type}/${cate}/detail/${rowData.id}`,
                    state: { rowData }
                });
            }
        }
    }

return(
    <>
        { data == null ? <LoadingSpin /> :
            <div>
                <div className="List" style={{ maxWidth:"1200px", margin:"24px auto 0", padding:"0 24px 24px"}}>
                    <Space align="end" size="small" direction="vertical">
                        <Button onClick={onClickWrite} type="primary" style={{fontWeight:600}}>글쓰기</Button>
                        <Table
                            tableLayout="fixed"
                            expandRowByClick={true}
                            columns={columns}
                            dataSource={data}
                            onRow={onRow}
                            rowKey={data.id}
                            pagination={{
                                pageSize:12
                            }}
                        />
                    </Space>
                </div>
            </div>
        }
    </>

  );
}
