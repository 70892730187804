import React from "react";
import { Route, Switch} from "react-router-dom";
import InquiryList from "../components/inquiry/InquiryList";
import InquiryDetail from "../components/inquiry/InquiryDetail";

export default function Inquiry(props) {
const {userObj, setUserObj} = props;
    return(
      <>
        <Switch>
            <Route path="/inquiry/detail/:bbsId">
                 <InquiryDetail userObj={userObj} />
            </Route>
            <Route path="/inquiry/">
                <InquiryList userObj={userObj} />
            </Route>
        </Switch>
      </>
    );
}
