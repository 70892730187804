import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, useLocation, useHistory, useParams } from "react-router-dom";
import ProgramManage from "./ProgramManage";
import Inquiry from "./Inquiry";
import Basic from "./Basic";
import Gallery from "./Gallery";
import Program from "./Program";
import Attach from "./Attach";
import UserManage from "./UserManage";
import { Layout, Space } from 'antd';
import '../assets/styles/Layout.css';
import '../assets/styles/Board.css';
import BannerManage from "./BannerManage";


const { Header, Content, Footer } = Layout;

export default function Routes(props) {
    const {userObj} = props;

return (
        <>
        <Switch>
            <Route path="/program/">
                <Program userObj={userObj} />
            </Route>
            <Route path="/inquiry/">
                <Inquiry userObj={userObj} />
            </Route>
            <Route path="/basic/">
                <Basic userObj={userObj} />
            </Route>
            <Route path="/gallery/">
                <Gallery userObj={userObj}  />
            </Route>
            <Route path="/attach/">
                <Attach userObj={userObj}  />
            </Route>
            <Route path="/program-manage/">
                <ProgramManage userObj={userObj} />
            </Route>
            <Route path="/user/">
                <UserManage userObj={userObj} />
            </Route>
            <Route path="/banner/">
                <BannerManage userObj={userObj} />
            </Route>
        </Switch>
        </>
 );

}
