import React from "react";
import { useParams } from 'react-router-dom'
import GalleryWriteText from "./GalleryWriteText";
import GalleryWriteImage from "./GalleryWriteImage";

export default function GalleryWrite(props) {
    const { cate, bbsId } = useParams();
    const { userObj, type } = props;
    
    const MatchCate = () => {
        switch(cate) {
            case 'e-culrture': return <GalleryWriteText userObj={userObj} type={type} name='행사안내ㅤ/ㅤ문화행사' />; break;
            case 'e-academic': return <GalleryWriteText userObj={userObj} type={type} name='행사안내ㅤ/ㅤ학술행사' />; break;
            case 'gallery': return <GalleryWriteImage userObj={userObj} type={type} name='행사안내ㅤ/ㅤ갤러리' />; break;
            case 'cultural': return <GalleryWriteImage userObj={userObj} type={type} name='성북학ㅤ/ㅤ문화유산' />; break;
            case 'hfigure': return <GalleryWriteImage userObj={userObj} type={type} name='성북학ㅤ/ㅤ인물'  />; break;
        }
    }
  return (
      <>
      { MatchCate() }
      </>
  );
}
