import React, { useState, useEffect } from 'react';
import { TimePicker, Space, Skeleton } from 'antd';
import moment from 'moment';
import locale from 'antd/es/time-picker/locale/ko_KR';


const TimePickerAd = (props) => {

  const {getTime, timePick, loading, mode } = props;
  const [ startTime, setStartTime ] = useState('00-00');
  const [ endTime, setEndTime ] = useState('00-00');
  const format = 'HH:mm';

  function onChange(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    getTime(dateString);
  }

    function onOk(value) {
    console.log('onOk: ', value);   
  }
  
  useEffect(() => {
    if(mode.type !== 'new'){
       if(loading === false){
        if(timePick && timePick.startTime !== null){
            setStartTime(moment(timePick.startTime, 'HH:mm'));
            setEndTime(moment(timePick.endTime, 'HH:mm'));
        }
      }
    }
  }, [timePick]);

 const EditMode = () => {
    if(startTime === '00-00'){
      if( timePick && timePick.startTime !== null){
        return <Skeleton.Input style={{ width: 335 }} active size />
      }else if(timePick && timePick.startTime == null){
        return (
        <TimePicker.RangePicker
          onChange={onChange}
          format={format}
          locale={locale}
          placeholder={['시작 시간',' 종료 시간']}
        />
        )
      }
    }else{
      return (
        <TimePicker.RangePicker
            format={format}
            onChange={onChange}
            defaultValue={[moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')]}
            locale={locale}
            popupStyle={{ width: 120 }}
        />
      );
    }
};

const WriteMode = () => {
return (
    <TimePicker.RangePicker
        onChange={onChange}
        format={format}
        locale={locale}
        placeholder={['시작 시간',' 종료 시간']}
      /> 
  )
}
  return (
    <>
     { mode.type === 'new' ? WriteMode() : EditMode() }
    </>
  );
}

export default TimePickerAd;