import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from 'react-router-dom'
import {Space, Button, Typography, Row, Col, Modal, Form, Image, Skeleton, Divider, List } from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined, EyeOutlined } from '@ant-design/icons';
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import LoadingSpin from "../layout/LoadingSpin"
import DeleteModalBtn from "../layout/DeleteModalBtn";
import axios from "axios";
import moment from 'moment';
import '../../assets/styles/galleryboard.css';

const { Title, Text } = Typography;

const GalleryDetailText = (props) => {
	const { userObj, type } = props;
    const history = useHistory();
    const { bbsId, cate } = useParams();
    const [ detailData, setDetailData ] = useState(null)
    const [ loading, setLoading ] = useState(true);
    const [localToken, setLocalToken] = useState();

    const [ prevLink, setprevLink] = useState({
        link:null,
        title:null,
    });

    const [ nextLink, setnextLink] = useState({
        link:null,
        title:null,
    });

const getDataAPI = () => {
    axios.get(`${defaultApiAddress}/boards/${bbsId}?cate=${cate}`)
        .then(res => {
            setDetailData(res.data);
            console.log(res.data);
            return setTimeout(() => {
                setLoading(false);
            }, 200);
            })
        .catch(err => console.error(err));
};

useEffect(() => {
    if (!bbsId) {
        console.log('게시글 정보가 없습니다. : None bbsId');
        return history.push(`/${type}/${cate}/`)
    }else{
        getDataAPI();
        refreshToken().then((_idToken)=>{setLocalToken(_idToken)});
    }
},[]);

const onHandleBack = () => {
    history.push(`/${type}/${cate}/`);
};

const updateBoard = () => {
    history.push(`/${type}/${cate}/write/${bbsId}`);
};

const boardDeleteConfirm = async (event) => {
    await deletePost(event.target.value);
    return history.push({
      pathname:`/${type}/${cate}/`,
      state: { delete: true,
               id: bbsId
      }
     });
};

const deletePost = () => {
  return axios.delete(`${defaultApiAddress}/boards/${bbsId}`, {headers: {
          "Authorization" : localToken,
          "Content-Type" : "application/json",
          "Access-Control-Allow-Origin" : "*",
      }})
        .then(() => {
            console.log('board deleted');
        });
}

const curpath = `/${type}/${cate}/detail/`

const prevFunction = () => {
    if(detailData?.nearRow[0] !== null){
        setprevLink({
            link:curpath + detailData?.nearRow[0].id,
            title:detailData?.nearRow[0].title
        })
    }else if(detailData?.nearRow[0] == null){
        setprevLink({
            link:null,
            title:'이전 게시물이 존재하지 않습니다.'
        })
    }
    return () => {};
}
const nextFunction = () => {
    if(detailData?.nearRow[1] !== null){
        setnextLink({
            link:curpath + detailData?.nearRow[1].id,
            title:detailData?.nearRow[1].title
        })
       }else if(detailData?.nearRow[1] == null){
           setnextLink({
               link:null,
               title:'다음 게시물이 존재하지 않습니다.'
           })
       }
    return () => {};
}


useEffect(() => {
    if(detailData !== null){
        nextFunction();
        prevFunction();
    }else return () => {};
},[detailData?.nearRow]);

// 조회수 아이콘.
    // const IconText = ({ icon, text }) => (
    //     <Space>
    //       {React.createElement(icon)}
    //       {text}
    //     </Space>
    //   );


    return(
		<>
        { loading && <LoadingSpin /> }
          <Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
            <Row className="detailMainInfoWrap">
                <Col span={2}>
                    <Button onClick={onHandleBack} type="text">
                        <ArrowLeftOutlined
                            style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                        />
                        <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                    </Button>
                </Col>
                <Col span={2} offset={18}>
                    <Button onClick={updateBoard} className="editBt" type="text">수정하기</Button>
                </Col>
                <Col span={2} offset={0}>
                    <DeleteModalBtn boardDeleteConfirm={boardDeleteConfirm} />
                </Col>
                <Col span={24} style={{margin:"50px 0 25px", padding:"0 20px"}}>
                    <Title className="titleText">
                        { detailData?.title?.slice(0, 100) }
                    </Title>
                    <Text type="secondary" className="secondaryText">
                        <Space>
                        성북문화원
                        {moment(detailData?.createdAt).format('YY/MM/DD')}
                        </Space>  
                    </Text>
                    <Divider className="titleDivider" />
                </Col>
            </Row>
            <Row align="middle" justify="center" style={{padding:"0 24px 24px"}} >
                <div className='g-b-container'>
                    <div className='boardTitleBox'>
                        </div>
                        <hr/>
                        <div className='g-b-table'>
                        <table className="tbl">
                            <tbody><tr>
                                <td className="tb_img" rowSpan="4" style={{width:'50%', overflow:'hidden', paddingRight:20}}>
                                    <div >
                                    { loading === true ? 
                                            <Skeleton.Image style={{width:"400px", height: "400px" }} /> : 
                                                <Image 
                                                    width="100%"
                                                    style={{maxWidth:"70%", margin:'0 auto'}}
                                                    src={defaultApiAddress+detailData.mediaUrl} 
                                                    alt="thumbnail"
                                                    preview={false}
                                                />
                                        }
                                    </div>
                                </td>
                                <th>행사명</th>
                                <td>{detailData?.info?.eventName}</td>
                            </tr>
                            <tr>
                                <th>일시</th>
                                <td>{detailData?.info?.eventDate}</td>
                            </tr>
                            <tr>
                                <th>장소</th>
                                <td>{detailData?.info?.eventLocation}</td>
                            </tr>
                            <tr>
                                <th>공연정보</th>
                                <td>
                                    {detailData?.info?.eventDescription}				
                                </td>
                            </tr>
                        </tbody></table>
                        </div>
                </div>
            </Row>
                <Row align="middle" justify="center" className="detailFooterWrap">
                    <Divider className="titleDivider" />
                    <Col span={24} style={{margin:"0 0 25px"}}>
                        <List
                            className="prevnextList"
                            itemLayout="horizontal"
                        >
                            <List.Item
                            actions={[<a key="list-loadmore-edit" href={nextLink.link}><UpOutlined />ㅤ다음글</a>, <a key="list-loadmore-more" href={nextLink.link}>{nextLink.title}</a>]}
                            />
                            <List.Item
                            actions={[<a key="list-loadmore-edit" href={prevLink.link}><DownOutlined />ㅤ이전글</a>, <a key="list-loadmore-more" href={prevLink.link}>{prevLink.title}</a>]}
                            />
                        </List>
                    </Col>
                </Row>
            </Row>
			</>
    )
}

export default GalleryDetailText;
