import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu} from 'antd';
import {
  LayoutFilled,
  UserOutlined,
  TeamOutlined,
  FormOutlined,
  FileSearchOutlined,
  AppstoreOutlined,
  PieChartOutlined
} from '@ant-design/icons';
const { SubMenu } = Menu;
const { Sider } = Layout;

export default function SiderNavigation (){

  return (
      <Sider
          width={240}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}>
        <Link to={{
          pathname: "/",
          state: {
            link: "/"
          }
        }}>
          <div className="logo">
            <span style={{fontSize: 16, fontWeight:500, color: 'white', lineHeight:"46px"}}><LayoutFilled />ㅤ성북문화원</span>
            {/* <img src="/images/logo.png" /> */}
          </div>
        </Link>
        <Menu theme="dark" mode="inline">
          <SubMenu icon={<FormOutlined />} key="sub1" title="게시판 관리" className="mainCategory">
            <SubMenu key="sub2" title="문화강좌">
              <Menu.Item key="1">
                <Link to={{
                  pathname:"/program/" ,
                  state: {
                    group: "문화강좌",
                    title: "프로그램",
                    link: "/program/"
                  }
                }}>프로그램</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" title="행사안내">
              <Menu.Item key="2">
                <Link to={{
                  pathname:"/gallery/e-culrture/" ,
                  state: {
                    group: "행사안내",
                    title: "문화행사",
                    link: "/gallery/e-culrture/"
                  }
                }}>문화행사</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={{
                  pathname:"/gallery/e-academic/" ,
                  state: {
                    group: "행사안내",
                    title: "학술행사",
                    link: "/gallery/e-academic/"
                  }
                }}>학술행사</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={{
                  pathname:"/gallery/gallery/",
                  state: {
                    group: "행사안내",
                    title: "갤러리",
                    link: "/gallery/gallery/"
                  }
                }}>갤러리</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub4" title="성북학">
              <Menu.Item key="5">
                <Link to={{
                  pathname:"/gallery/cultural/",
                  state: {
                    group: "성북학",
                    title: "문화유산",
                    link: "/gallery/cultural/"
                  }
                }}>문화유산</Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to={{
                  pathname:"/gallery/hfigure/" ,
                  state: {
                    group: "성북학",
                    title: "인물",
                    link: "/gallery/hfigure/"
                  }
                }}>인물</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub5" title="커뮤니티">
              <Menu.Item key="7">
                <Link to={{
                  pathname:"/attach/notice/",
                  state: {
                    group: "커뮤니티",
                    title: "공지사항",
                    link: "/attach/notice/",
                  }
                }}>공지사항</Link>
              </Menu.Item>
              <Menu.Item key="8">
                <Link to={{
                  pathname:"/inquiry/",
                  state: {
                    group: "커뮤니티",
                    title: "문의내역",
                    link: "/inquiry/"
                  }
                }}>문의내역</Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to={{
                  pathname:"/attach/news/",
                  state: {
                    group: "커뮤니티",
                    title: "보도자료",
                    link: "/attach/news/"
                  }
                }}>보도자료</Link>
              </Menu.Item>
              <Menu.Item key="10">
                <Link to={{
                  pathname:"/attach/reference/",
                  state: {
                    group: "커뮤니티",
                    title: "자료실",
                    link: "/attach/reference/"
                  }
                }}>자료실</Link>
              </Menu.Item>
              <Menu.Item key="11">
                <Link to={{
                  pathname:"/attach/public/",
                  state: {
                    group: "커뮤니티",
                    title: "정보공개",
                    link: "/attach/public/"
                  }
                }}>정보공개</Link>
              </Menu.Item>
            </SubMenu>
          </SubMenu>
          <SubMenu icon={<TeamOutlined />} key="sub6" title="회원관리">
            <Menu.Item key="12">
              <Link to={{
                pathname:"/user/",
                state: {
                  title: "회원관리",
                  link: "/user/"
                }
              }}>회원관리</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu icon={<FileSearchOutlined />} key="sub7" title="강좌관리">
            <Menu.Item key="13">
              <Link to={{
                pathname: "/program-manage/",
                state: {
                  group: "강좌관리",
                  title: "강좌 신청 관리",
                  link: "/program-manage/"
                }
              }}>강좌 신청 관리</Link>
            </Menu.Item>
            <Menu.Item key="14">
              <Link to={{
                pathname: "/program-manage/status",
                state: {
                  group: "강좌관리",
                  title: "강좌 현황 관리",
                  link: "/program-manage/status"
                }
              }}>강좌 현황 관리</Link>
            </Menu.Item>
            <Menu.Item key="15">
              <Link to={{
                pathname:"/program-manage/historychk",
                state: {
                  group: "강좌관리",
                  title: "강좌 이력 조회",
                  link: "/program-manage/historychk"
                }
              }}>강좌 이력 조회</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu icon={<AppstoreOutlined />} key="sub8" title="배너관리">
            <Menu.Item key="16">
              <Link to={{
                pathname:"/banner/main",
                state: {
                  title: "메인 슬라이드 배너",
                  link: "/banner/main"
                }
              }}>메인 슬라이드 배너</Link>
            </Menu.Item>
            {/*<Menu.Item key="17">*/}
            {/*  <Link to={{*/}
            {/*    pathname: "/banner/book",*/}
            {/*    state: {*/}
            {/*      title: "이달의 도서 배너",*/}
            {/*      link: "/banner/book"*/}
            {/*    }*/}
            {/*  }}>이달의 도서 배너</Link>*/}
            {/*</Menu.Item>*/}
          </SubMenu>
        </Menu>
      </Sider>
  )
};
