import React, { useState, useEffect, useRef} from 'react';
import moment from "moment";
import {Table, Select, Spin, message, Button, Popconfirm} from 'antd';
import axios from "axios";
import {authService} from "../../middleware/fb";
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import {useHistory} from "react-router-dom";

const { Option } = Select;

const StatusTable = (props) => {
  const { userData, setRoleChange, setYmChange, loadingSpin, getUserAllFn } = props;
  const [ users, setUsers ] = useState(null);
    const history = useHistory();

  useEffect(() => {
    if(userData != null){
      setUsers(userData);
    };
  });

  console.log(users)

  const [ roleChangeUid, setRoleChangeUid ] = useState(null);

  const onYmChange = (value) => {
    console.log(value, roleChangeUid);
    if(roleChangeUid !== null){
      console.log(value, roleChangeUid);
      return setYmChange({ value, roleChangeUid })
    }
  }

  const onRoleChange = (value) => {
    console.log(value, roleChangeUid);
    if(roleChangeUid !== null){
  }

    console.log(value, roleChangeUid);
    return setRoleChange({ value, roleChangeUid })
}

    const retireUser = async (userId, idToken) => {
        console.log('revoke userId - '+userId);

        const instance = await axios.create({
            baseURL: `${defaultApiAddress}`,
            headers: {
                "Authorization" : idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
            timeout: 5000,
        });
        const response = await instance.get(
            `/users/${userId}/retire`
        ).then((res) => {
            console.log(res.data.results);
            message.success('탈퇴 처리완료');
            getUserAllFn(idToken);
        }).catch((error)=>{
                if (error.response) {
                    message.error(error.response.data.message);
                }
        });};

    function cancel(e) {
        console.log(e);
        message.error('탈퇴 취소되었습니다.');
    }

   const columns = [
      {
        title: '이름',
        key:'id',
        dataIndex: 'userNameReal',
        width: '12.5%',
        align: 'center',
      },
      {
        title: '연락처',
        dataIndex: 'phoneNumber',
        width: '12.5%',
        align: 'center',
      },
      {
        title: 'e-mail',
        dataIndex: 'email',
        width: '17.5%',
        align: 'center',
      },
      {
        title: '계정연동 정보',
        dataIndex: 'provider',
        width: '12.5%',
        align: 'center',
        editable: true,
      },
      {
        title: '가입일',
        dataIndex: '',
        align: 'center',
        width: '10%',
        render: (i) => moment(i.createdAt).format('YY/MM/DD'),
      },
       {
           title: '회원탈퇴',
           dataIndex: '',
           align: 'center',
           width: '10%',
           render: (i) => <>
               <Popconfirm
                   placement="right"
                   title="탈퇴 처리를 진행합니다."
                   overlayClassName="customPopconfirm"
                   onConfirm={()=>{
                       refreshToken().then((_idToken)=>{
                           retireUser(i.id, _idToken);
                       })}}
                   onCancel={cancel}
                   okText="예"
                   cancelText="아니오"
                   icon={false}
               >
                   <a href="#" style={{fontSize:"1.2em",color:"red"}}>x</a>
               </Popconfirm>
           </>
       },
       {
        title: '일반회원',
        dataIndex: '',
        width: '10%',
        align: 'center',
        key:'ym',
        render:(item) => (
        <>
           <Select className="roleSelect"
                   value={item.ym ? "true" : "false"}
                      onFocus={() => {
                        setRoleChangeUid(item.id);
                      }}
                      onChange={(value) => {
                             onYmChange(value)
                         }
                      }
                      >
                <Option value="true">YES</Option>
                <Option value="false">NO</Option>
            </Select>
        </>
        )
      },
      {
        title: '권한',
        dataIndex: '',
        width: '10%',
        align: 'center',
        key:'id',
        render:(item) => (
        <>
           <Select className="roleSelect"
                      value={item.role}
                      onFocus={() => {
                        setRoleChangeUid(item.id);
                      }}
                      onChange={(value) => {
                             onRoleChange(value)
                         }
                      }
                      >
                <Option value="USER">사용자</Option>
                <Option value="ADMIN">관리자</Option>
            </Select>
        </>
        )
      },
    ];

    return (
        <>
            {
                loadingSpin != false ?
                    <Spin size="large" tip="" delay="300"/> :
                    <div>
                        <Table
                            className="table"
                            bordered
                            dataSource={users}
                            columns={columns}
                            pagination={{
                                defaultCurrent:1,
                                pageSize: 12,
                                size: 'small',
                            }}
                        >
                        </Table>
                    </div>
            }
        </>
    );

};

export default StatusTable;
