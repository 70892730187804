import { atom, selector, useRecoilState } from 'recoil';

export const idState = atom({
    key: 'idState',
    default: null,
});

export const replyState = atom({
    key: 'replyState',
    default: false,
});

export const replyId = atom({
    key: 'replyId',
    default: null,
});