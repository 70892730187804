import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import '../../assets/styles/galleryboard.css';
import GalleryListText from './GalleryListText';
import GalleryList from './GalleryList';

const ListValue = (props) => {
    const { userObj } = props;
    const { cate } = useParams();
    const type = 'gallery'

  const matchViewComponent =(item) => {
    switch (item) {
      case 'e-culrture' : return <GalleryListText userObj={userObj} type={type} />; break;
      case 'e-academic' : return <GalleryListText userObj={userObj} type={type} />; break;
      case 'gallery' : return <GalleryListText userObj={userObj} type={type} />; break;
      case 'cultural' : return <GalleryList userObj={userObj} type={type} />; break;
      case 'hfigure' : return <GalleryList userObj={userObj} type={type} />; break;
    }
  };

  return (
    <>
      { matchViewComponent(cate) }
    </>
    );
  }
  
  export default ListValue;
