import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from "axios";
import {Space, Button, Typography } from 'antd';
import WithoutSkeleton from '../board/WithoutSkeleton';
import WithSkeleton from '../board/WithSkeleton';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import {defaultApiAddress} from "../../services/api.helpers";
import 'react-quill/dist/quill.bubble.css';

const { Title, Text } = Typography;

export default function BasicDetail(props) {
    const { userObj, type } = props;
    const history = useHistory();
    const { cate, bbsId } = useParams();
    const [loading, setLoading] = useState(true);
    const [ detailData, setDetailData ] = useState(null);

    const getDataAPI = async () => {
        if (!bbsId) {history.push(`/${type}/`)}     // bbsId값 없이 들어오면 전페이지로 튕겨내기
         await axios.get(defaultApiAddress+`/boards/${bbsId}?cate=${cate}`)
        .then(res => {
            console.log(res.data);
            setDetailData(res.data);
            return setLoading(false)
            })
        return () => {};
        }

  useEffect(() => {
      getDataAPI();
  }, [])

    return(
        <>
         { loading ?
        <WithSkeleton detailData={detailData} loading={loading} /> :
            <WithoutSkeleton
                history={history}
                detailData={detailData}
                loading={loading}
                cate={cate}
                bbsId={bbsId}
                type={type}
                userObj={userObj}
                /> }
        </>
    );
}
