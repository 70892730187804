import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from "axios";
import WriteEditor from "../board/WriteEditor";
import LoadingSpin from "../layout/LoadingSpin";
import WarningModalBtn from "../layout/WarningModalBtn";
import { Button, Row, Col, Form, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";

export default function BasicWrite(props) {
    const { userObj, type } = props
    const history = useHistory();
    const { cate, bbsId } = useParams();
    const [ mode, setMode ] = useState({
        type: null
    });

    const [ loading, setLoading ] = useState(true);
    const [ detailData, setDetailData ] = useState({
        id: null,
        type:type,
        category:cate,
        title: null,
        writer: '성북문화원',
        createdAt:null,
        description: null
    });

    useEffect(() => {
        if (!bbsId) {
            setLoading(false);
            console.log('param 없다 - 신규 작성 모드');
            return setMode({type:'new'});
        } else {
            getDataAPI();
            return setMode({type:bbsId});
        }
        }, []);


    const getDataAPI = () => {
        axios.get(defaultApiAddress+`/boards/${bbsId}`)
                .then((response) => {
                    console.log(response.data);
                    form.setFieldsValue(response.data);
                    setDetailData(response.data);
                })
                .catch((error) => console.log(error))
        };

  const handleGoBack = () => {
    history.push(`/${type}/${cate}/`);
  };

  const [desc, setDesc] = useState('');

  function onEditorChange(value) {
      setDesc(value)
  }

  const [form] = Form.useForm();
  useEffect(() => {
    bbsId&&form.setFieldsValue({
            id: detailData.id,
            type:type,
            category:cate,
            title: detailData.title,
            writer: '성북문화원',
            createdAt: detailData.createdAt,
            description: detailData.description
          })
      // loading spinner setting
      if(mode.type !== 'new'){
        setTimeout(() => {
            setLoading(false);
        }, 1200);
    }
  }, [detailData])
  
  const HandleFinish = (values) => {                
    values.type = type;
    values.category = cate;
    console.log(values);
      if (mode.type === 'new') {
          refreshToken().then((_idToken)=>{postDataApi(values, _idToken)});
    }else{
          refreshToken().then((_idToken)=>{putDataApi(values, _idToken)});
      }
  }

  const postDataApi = (values, _idToken)=>{
      axios.post(defaultApiAddress+'/boards/', values, {headers: {
              "Authorization" : _idToken,
              "Content-Type" : "application/json",
              "Access-Control-Allow-Origin" : "*",
          }})
          .then((response) => {
              form.setFieldsValue(response.data);
              console.log(response.data);
              console.log('board created');
              history.push({
                  pathname:`/${type}/${cate}/`,
                  state: {update: true}
              });
          })
  }

  const putDataApi = (values, _idToken)=>{
      axios.put(defaultApiAddress+'/boards/'+mode.type, values, {headers: {
              "Authorization" : _idToken,
              "Content-Type" : "application/json",
              "Access-Control-Allow-Origin" : "*",
          }})
          .then((response) => {
              form.setFieldsValue(response.data);
              console.log(response.data);
              console.log('board updated');
              history.push({
                  pathname:   `/${type}/${cate}/`,
                  state: {update: true,
                      id: bbsId }
              });
          })
  }

  const onHandleBack = () => {
    history.push(`/${type}/${cate}/`);
};

  const HandleFinishFaild = (errorInfo) => {
      console.log(errorInfo);
  }

  return (
      <>
      { loading && <LoadingSpin /> }
        <Row justify="center" className="detailMainInfoWrap">
            <Form
                name="writeForm"
                form={form}
                layout="vertical"
                className="writeForm"
                // onValuesChange={onRequiredTypeChange}
                onFinish={HandleFinish}
                onFinishFailed={HandleFinishFaild}
            >
            <Col span={2}>
                <Button onClick={onHandleBack} type="text">
                    <ArrowLeftOutlined
                        style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                    />
                    <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
                </Button>
            </Col>
                <Row className="detailMainInfoWrap">
                    <Col span={18} offset={3}>
                        <h2>게시판 글쓰기</h2>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="작성자"
                            name="writer"
                            initialValue="성북문화원"
                            style={{paddingLeft:20, marginTop:5}}
                            >
                            <Input value="성북문화원" style={{color:'#999999', fontSize:16, marginLeft:"-10px", marginTop:"-10px"}} disabled bordered={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item
                        label="제목"
                        name="title"
                        placeholder="제목을 입력해주세요."
                        rules={[{ required: true, message: '제목을 입력하지 않았습니다.' }]}
                        >
                        <Input maxLength={50} />
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom:40}}
                        label="내용"
                        name="description"
                        placeholder="내용을 입력해주세요."
                        rules={[{ required: true, message: '내용을 입력하지 않았습니다.' }]}
                        >
                        <WriteEditor value={desc} onChange={onEditorChange} />
                    </Form.Item>
                    <Form.Item>
                        {/* <Button type="primary" danger onClick={showModal} style={{marginRight:30}}>취소</Button>
                        <Modal title="🚫ㅤ주의ㅤ🚫" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>작성한 게시글이 저장되지 않습니다.</p>
                        <p>목록으로 돌아겠습니까?</p>
                        </Modal> */}
                        <WarningModalBtn handleGoBack={handleGoBack} />
                        <Button type="primary" htmlType="submit" className="write-submit-button">
                            { mode.type === 'new' ? '등록' : '저장' }
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Row>
    </>
  );
}
