import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import {Space, Button, Typography, Row, Col, Modal, Form, Image, Skeleton, Divider, List } from 'antd';
import { DownOutlined, UpOutlined, ArrowLeftOutlined, EyeOutlined } from '@ant-design/icons';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import LoadingSpin from "../layout/LoadingSpin"
import DeleteModalBtn from "../layout/DeleteModalBtn";
import moment from 'moment';
import axios from "axios";

const { Title, Text } = Typography;

export default function GalleryDetailImage(props) {

    const { userObj, type } = props;
    const { cate, bbsId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const [ loading, setLoading ] = useState(true);
    const [ detailData, setDetailData ] = useState(null);
    const [localToken, setLocalToken] = useState();

    const [ prevLink, setprevLink] = useState({
        link:null,
        title:null,
    });

    const [ nextLink, setnextLink] = useState({
        link:null,
        title:null,
    });

    const getDataAPI = () => {
        axios.get(`${defaultApiAddress}/boards/${bbsId}?cate=${cate}`)
            .then(res => {
                setDetailData(res.data);
                console.log(res.data);
                return setTimeout(() => {
                    setLoading(false);
                }, 200);
                })
            .catch(err => console.error(err));
    };

    useEffect(() => {
        if (!bbsId) {
            console.log('param 없다');
            return history.push(`/${type}/${cate}/`)
        }else{
            getDataAPI();
            refreshToken().then((_idToken)=>{console.log('idToken',_idToken);setLocalToken(_idToken)});
        }
    },[]);

    const onHandleBack = () => {
        history.push(`/${type}/${cate}/`);
    };

    const curpathpoint = location.pathname.indexOf('/detail/');
    const curpath = location.pathname.slice(0, curpathpoint + 8);

    const boardDeleteConfirm = async (event) => {
          await deletePost(event.target.value);
          return history.push({
            pathname:`/${type}/${cate}/`,
            state: { delete: true,
                     id: bbsId
            }
           });
      };

      const deletePost = () => {
        return axios.delete(`${defaultApiAddress}/boards/${bbsId}`, {headers: {
                "Authorization" : localToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
              .then(() => {
                  console.log('board deleted');
              });
      }

    const updateBoard = () => {
            history.push(`/${type}/${cate}/write/${bbsId}`);
    };

    const prevFunction = () => {
        if(detailData !== null){
                 if(detailData?.nearRow[0] !== null){
            setprevLink({
                link:curpath + detailData?.nearRow[0].id,
                title:detailData?.nearRow[0].title
            })
            }
        if(detailData?.nearRow[0] === null){
            setprevLink({
                link:null,
                title:'이전 게시물이 존재하지 않습니다.'
            })
        }
    }
}

const nextFunction = () => {
    if(detailData !== null){
             if(detailData.nearRow[1] !== null){
            setnextLink({
               link:curpath + detailData.nearRow[1].id,
               title:detailData.nearRow[1].title
           })
       }else{
           setnextLink({
               link:null,
               title:'다음 게시물이 존재하지 않습니다.'
           })
       }
    }
}

  useEffect(() => {
    prevFunction()
    nextFunction()
    return () => null;
    },[detailData?.nearRow]);

// 조회수 아이콘
  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

    return(
<>
<Row wrap align="middle" justify="start" style={{overflow:"hidden"}}>
    <Row className="detailMainInfoWrap">
        <Col span={2}>
            <Button onClick={onHandleBack} type="text">
                <ArrowLeftOutlined
                    style={{color:"#BDBDBD", fontSize: 20, fontWeight:"bold"}}
                />
                <span style={{color:"#828282", fontWeight:600, fontSize:16}}>목록</span>
            </Button>
        </Col>
        <Col span={2} offset={18}>
            <Button onClick={updateBoard} className="editBt" type="text">수정하기</Button>
        </Col>
        <Col span={2} offset={0}>
            <DeleteModalBtn boardDeleteConfirm={boardDeleteConfirm} />
        </Col>
        <Col span={24} style={{margin:"50px 0 25px", padding:"0 20px"}}>
            <Title className="titleText">
                { detailData?.title?.slice(0, 100) }
            </Title>
            <Text type="secondary" className="secondaryText">
                 <Space>
                   성북문화원
                   {moment(detailData?.createdAt).format('YY/MM/DD')}
                 </Space>
            </Text>
            <Divider className="titleDivider" />
        </Col>
    </Row>
    {/*<Row align="middle" justify="center" style={{padding:"0 24px 24px"}} >*/}
    {/*        { loading === true ? */}
    {/*            <Skeleton.Image style={{width:"400px", height: "400px" }} /> : */}
    {/*                <Image*/}
    {/*                    width="100%"*/}
    {/*                    style={{maxWidth:"70%", margin:'0 auto'}}*/}
    {/*                    src={defaultApiAddress+detailData.mediaUrl} */}
    {/*                    alt="thumbnail"*/}
    {/*                    preview={false}*/}
    {/*                />*/}
    {/*        }*/}
    {/*</Row>*/}
    <Row align="middle" justify="center" style={{padding:"0 24px 24px"}} >
        {detailData ? (
            <>
                <ReactQuill
                    value={detailData.description}
                    readOnly={true}
                    theme={"bubble"}
                />
            </>
        ) : (
            <>
                    <div>로딩중...</div>
            </>
        )}
        {/*아래가 게시글 내용 보여주는 방식*/}
    </Row>
    <Row align="middle" justify="center" className="detailFooterWrap">
        <Divider className="titleDivider" />
        <Col span={24} style={{margin:"0 0 25px"}}>
            <List
                className="prevnextList"
                itemLayout="horizontal"
            >
                <List.Item
                actions={[<a key="list-loadmore-edit" href={nextLink.link}><UpOutlined />ㅤ다음글</a>, <a key="list-loadmore-more" href={nextLink.link}>{nextLink.title}</a>]}
                />
                <List.Item
                actions={[<a key="list-loadmore-edit" href={prevLink.link}><DownOutlined />ㅤ이전글</a>, <a key="list-loadmore-more" href={prevLink.link}>{prevLink.title}</a>]}
                />
            </List>
        </Col>
    </Row>
      <Form.Item>

      </Form.Item>
  </Row>
</>
    );
}
