import React, { useEffect, useState } from 'react';
import { DatePicker, Space, Skeleton } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ko_KR';


const { RangePicker } = DatePicker;

const DatePickerAdReport = (props) => {

    const {getDate, loading, datePick } = props;
    const [ startData, setStartData ] = useState('2021-00-00');
    const [ endData, setEndData ] = useState('2021-00-00');
    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        if(loading === false){
            if(datePick.startDate !== null){
                setStartData(moment(datePick.startDate, 'YYYY-MM-DD'));
                setEndData(moment(datePick.endDate, 'YYYY-MM-DD'));
            }
        }
    }, [datePick])

    const WriteMode = () => {
        return (
            <RangePicker
                onChange={onChange}
                format={dateFormat}
                locale={locale}
            />
        )
    }

    function onChange(value, dateString) {
        // console.log(value);
        // console.log('Formatted Selected Time: ', dateString);
        getDate(dateString);
    }

    return (
        <>
            {WriteMode()}
        </>
    );
}

export default DatePickerAdReport;
