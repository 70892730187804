import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import axios from "axios";
import ProgramApplyTable from "./ProgramApplyTable";
import { Form, Pagination, Select, Radio, Row, Col, Spin, Input } from 'antd';

const { Option } = Select;
const { Search } = Input;

export default function ProgramApply() {
    const [ page, setPage ] = useState(1);
    const [ data, setData ] = useState(null);
    const [ totalCnt, setTotalCnt ] = useState(0);
    const [ value, setValue ] = useState('program-all');
    const [ loading, setLoading ] = useState(true);
    const [ radioValue, setRadioValue ] = useState('waiting');

    const [ search , setSearch ] = useState(null);
    const onSearch = search => { 
      setSearch(search);
    };

    const apiAllNoneSearch = `${defaultApiAddress}/courses/entry?&rpp=12&page=${page}`;
    const apiAllSearch = `${defaultApiAddress}/courses/entry?search=${search}&rpp=12&page=${page}`;
    const apiNoneSearch = `${defaultApiAddress}/courses/entry?status=${radioValue}&rpp=12&page=${page}`;
    const apiSearch = `${defaultApiAddress}/courses/entry?status=${radioValue}&search=${search}&rpp=12&page=${page}`;


  // 강좌 전체 && 접수 상태 전체
  const getAllapply = (adress) => {
    refreshToken().then((_idToken)=>{
        setTimeout(()=>{
            axios.get( adress , {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then(res => {
                    setData(res?.data?.results);
                    if(res?.data?.totalCount === 0 ){
                        setTotalCnt(1);
                    }else{
                        setTotalCnt(res?.data?.totalCount);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setData(null);
                    setLoading(false);
                })
        },100);
    });
  };

  const [ limitData, setLimitData ] = useState(null);

  const getNewData = (data) => {
  let obj = Object.assign(data);
    setLoading(true);
  return setLimitData(obj);
  }

  useEffect(() => {
      if(radioValue === 'all'){
        if(search === null){
          return getAllapply(apiAllNoneSearch);
        }else{
          return getAllapply(apiAllSearch);
        }
      }else if(radioValue !== 'all'){
        if(search === null){
          return getAllapply(apiNoneSearch);
        }else{
          return getAllapply(apiSearch);
        }
      }else return setData(null);
  }, [ value, page, radioValue, limitData, search ]);


  useEffect(() => {
    if( page !== 1 ){
      setPage(1);
    }
  },[value, radioValue]);

    const onRadioChange = e => {
      setLoading(true);
      setRadioValue(e.target.value);
    };

    useEffect(() => {
      if(data !== null){
        setData(data);
      }
    }, [data])
    return(
     <>
      <div className="table-board">
       <Row className="table-board-row">
            <Col span={14}>
              <Form.Item
                label="신청 상태ㅤ"
                className="table-board-form"
                >
                <Radio.Group onChange={onRadioChange} value={radioValue} defaultValue='waiting'>
                  <Radio value={'all'}>전체</Radio>
                  <Radio value={'waiting'}>입금대기</Radio>
                  <Radio value={'done'}>입금완료</Radio>
                  <Radio value={'canceled'}>ㅤ취소ㅤ</Radio>
                  <Radio value={'refunded'}>ㅤ환불ㅤ</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={10} className="searchBoxWrapProgram">
              <Search 
                id="search-box"
                className="searchBox"
                placeholder="수강생의 이름을 입력하세요." 
                allowClear
                loading={loading}
                onSearch={onSearch}
                style={{ height:'45px' }}
                />
            </Col>
          </Row>
          </div>
      <ProgramApplyTable programData={data} totalCnt={totalCnt} getNewData={getNewData} page={page} loading={loading} />
        <Pagination        
              responsive={false}
              showQuickJumper={false}
              showSizeChanger={false}
              defaultCurrent={1}
              total={totalCnt}
              current={page}
              pageSize={12}
              defaultPageSize={12}
              onChange={(current) => setPage(current)}
              size='small'
              style={{marginTop:50}}
            />
      </>
    )
};
