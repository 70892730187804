// import "./styles.css";
// import { EventsSection } from "./EventsSection";

import React, { useState, useEffect } from 'react';
import { defaultApiAddress, refreshToken } from "../../services/api.helpers";
import axios from "axios";
import HistoryCheckTable from "./HistoryCheckTable";
import {Button, Row, Col, Form, Input, Select, Checkbox, Typography, Alert, Upload} from 'antd';
import {FileExcelTwoTone, SearchOutlined} from '@ant-design/icons';
import xlsx from 'xlsx';
import qs from 'query-string';
import moment from 'moment';
import DatePickerAdReport from "./DatePickerAdReport";

const { Option } = Select;
const { Text, Title } = Typography;

export default function HistoryCheck() {
    const [ page, setPage ] = useState(1);
    const [ data, setData ] = useState(null);
    const [ totalCnt, setTotalCnt ] = useState(0);
    const [ value, setValue ] = useState('program-all');
    const [ loading, setLoading ] = useState(false);

    const [ datePick, setDatePick ] = useState({
        startDate: null,
        endDate: null
    });
    const getDate = (date) => {
        // console.log(date)
        setDatePick({startDate:date[0], endDate:date[1]});
        // console.log(datePick);
    }

    const [ dateErr, setDateErr ] = useState(false);
    // 체크한 날짜 value값 가져오는거.
    useEffect(() => {
        if(datePick.startDate == "" && datePick.endDate == ""){
            setDateErr(true);
        }else{
            setDateErr(false);
        }
    }, [datePick]);

    const excelDownLoad = async ()=>{
        return new Promise(async (resolve,reject) => {
            let editedXlsData = [];
            let _number = 0;
            for await (let i of data) {
                editedXlsData.push({"번호":_number+1, "신청일": moment(i.createdAt).format('YY/MM/DD'), "강좌명": i.courseInfo.title, "신규/기존": '', "수강생": i.entryUser.userNameFull || i.entryUser.userNameReal, "보호자": '', "생년월일": '', "핸드폰": i.entryUser.phoneNumber, "수강료": (i.payPrice || i.courseInfo.info.programFee), "결제방법": (i.payMethod != null ? (i.payMethod == 'CREDIT' ? '카드' : (i.payMethod == 'CASH' ? '현금' : '계좌')) : ''), "일반회원": (i.entryUser.ym == true ? '일반회원' : ''), "환불/변경": (i.status == 'REFUNDED' ? moment(i.updatedAt).format('YY/MM/DD') : ''), "환불금액": '', "메모": (i.memo != null ? i.memo : '')});
                _number++;
                if (i == data.length-1) {
                    // setXlsData(editedXlsData);
                }
            }

            resolve(editedXlsData);

            reject(false);
        });
    }

    const creatExcel = (xlsDataArr)=>{
        const _now = new Date();
        const fileName = moment(new Date).format('MMDDHHMM');
        // console.log(_now, fileName);

        // console.log('xlsData', xlsDataArr);
        const ws = xlsx.utils.json_to_sheet(xlsDataArr);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, "강좌이력조회 "+fileName+".xlsx");
    }

    // 강좌 전체 && 접수 상태 전체
    const getAllapply = async() => {
        if (!datePick.startDate && !datePick.endDate) { // 날짜가 선택되어 있을때만 검색 가능
            setDateErr(true);
            return false
        }
        setLoading(true);
        const _query = await qs.stringify({//쿼리문...
            fr: datePick.startDate,
            to: datePick.endDate,
            report: 'on',      // null이 아닌 뭔가는 넣어야 됨. api단에서 공백은 신경 안써도 되도록 조치되있음
            rpp: 9999,
            odby: 'ASC'
        });
        await console.log(_query);
        await refreshToken().then((_idToken)=>{
            axios.get( `${defaultApiAddress}/courses/entry?${_query}` , {headers: {
                    "Authorization" : _idToken,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                }})
                .then(res => {
                    console.log(res?.data?.results);
                    setData(res?.data?.results);
                    if(res?.data?.totalCount === 0 ){
                        setTotalCnt(1);
                    }else{
                        console.log(res?.data?.totalCount)
                        setTotalCnt(res?.data?.totalCount);
                    }
                    console.log('1번 effect');
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setData(null);
                    setLoading(false);
                })
        });
    };

    const [ limitData, setLimitData ] = useState(null);

    const getNewData = (data) => {
        let obj = Object.assign(data);
        return setLimitData(obj);
    }

    useEffect(() => {
        if( page !== 1 ){
            setPage(1);
        }
    },[value]);

    const [ selectLoading, setSelectLoading ] = useState(false);
    function selectChange(key) {
        console.log(key);
        setSelectLoading(true);
        setValue(key);
        setTimeout(() => {
            setSelectLoading(false);
        }, 800)
    }

    useEffect(() => {
        if(data !== null){
            setData(data);
        }
    }, [data])

    return(
        <>
            <div className="table-board">
                <Form className="table-board-row">
                    <Row>
                        <Col xs={24} md={24} xl={7}>
                            <Form.Item
                                label="시작일 / 종료일"
                                name="dateTime"
                                value={datePick?.startDate}
                                style={{height:'40px', lineHeight:'40px'}}
                            >
                                <DatePickerAdReport
                                    loading={loading}
                                    datePick={datePick}
                                    getDate={getDate}
                                    value={datePick?.startDate}
                                />
                                { dateErr ? <Text type="danger" className="texErrMsg">시작/종료일을 선택하지 않았습니다.</Text> : null }
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} xl={2}>
                            <Button icon={<SearchOutlined />} onClick={getAllapply} style={{height:40}} >조회</Button>
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                        </Col>
                        <Col xs={24} md={24} xl={3}>
                            <button type="button"
                                    className="excelBt"
                                    onClick={() => {
                                        if(!!data){
                                        excelDownLoad().then(r => {
                                            creatExcel(r);
                                        })}else{return null}
                                    }}><FileExcelTwoTone/><span style={{wordBreak:"keep-all"}}>ㅤ엑셀 다운로드</span>
                            </button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <HistoryCheckTable programData={data} totalCnt={totalCnt} getNewData={getNewData} page={page} loading={loading} />
        </>
    )
};
