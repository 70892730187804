import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from 'react-router-dom'
import { idState } from "../../recoil-state/inquiryState";
import {Space, Button, Input, Table, Alert, message, Tag} from 'antd';
import {defaultApiAddress, refreshToken} from "../../services/api.helpers";
import axios from "axios";
import { useSetRecoilState } from 'recoil';
import moment from 'moment';
import LoadingSpin from "../layout/LoadingSpin";

const columns = [
    {
        title: '답변상태',
        dataIndex:'answerCount',
        width:'10%',
        key:'answerCount',
        align: 'center',
        render: item =>  item >= 1 ? <Tag color="green" className="statusTag" >답변완료</Tag> :  <Tag color="orange" className="statusTag" >미완료</Tag>,
        // sorder:(a, b) => a.answerCount > b.answerCount,
        sortOrder: 'answerCount' === 0 && 'answerCount' === 1 ,
        shouldCellUpdate: (record, prevRecord) => true,
    },
    {
        title: '문의유형',
        dataIndex:'type',
        width:'15%',
        key:'type',
        align: 'center',
    },
    {
        title: '제목',
        dataIndex:'title',
        width:'50%',
        key:'title',
        render: text => text?.length > 20 ? <a style={{color: '#000000'}}>{text.slice(0,30)}...</a> : text,
    },
    {
        title: '작성자',
        dataIndex:'createdBy',
        width:"15%",
        key:'createdBy',
        render: name => (name?.userNameReal) ? (name?.userNameReal) : (name?.userName),
        align: 'center',
    },
    {
        title: '작성일시',
        dataIndex:'createdAt',
        width:"12%",
        align: 'center',
        key:'createdAt',
        className:"grayTxt",
        render: item => moment(item).format('YYYY/MM/DD'),
    },
];

export default function InquiryList(props) {

    // useRecoil을 통한 value, setter 변경.
    const [ selectId, setSelectId ] = useState(null);
    const [ loadingSpin, setLoadingSpin] = useState(true);
    const setListId = useSetRecoilState(idState);
    const { userObj } = props;
    const history = useHistory();
    const [ locationState, setLocationState ] = useState(null);
    const [data, setData] = useState([]);


    const success = () => {
        return (
            <>
                <Alert
                    className="alert-result-true"
                    message="등록 성공"
                    description="문의가 정상적으로 등록되었습니다."
                    type="success"
                    showIcon
                />
            </>
        )
    };

    const submitResultFalse = () => {
        return (
            <>
                <Alert
                    className="alert-result-false"
                    message="등록 실패"
                    description="문의 등록에 실패하였습니다."
                    type="error"
                    showIcon
                />
            </>
        )
    };

    const setUpdate = () => {
        setLocationState(null);
        success();
        // return window.location.reload();
    }

    const successDelete = () => {
        message.success({
            content: '문의가 삭제되었습니다.',
            className: 'custom-class',
            style: {
                marginTop: '25vh',
            },
        });
    };

    const setDelete = () => {
        setLocationState(null);
        successDelete();
    }

    const getDataAPI = (_idToken) => {
        setData(null);
        axios.get(defaultApiAddress + '/inquiries', {headers: {
                "Authorization" : _idToken,
                "Content-Type" : "application/json",
                "Access-Control-Allow-Origin" : "*",
            }})
            .then(res => {
                setData(res.data.results);
                const result = res.data.results;
                const total = res.data.totalCount;
                let newData = [];

                const reverseArray = result.reverse();
                console.log(reverseArray);

                reverseArray.map((i, index) => {
                    let listData;
                    return listData = Object.assign({...i, 'listNo': index + 1}),
                        newData.push(listData)
                })
                const dataSort = newData.reverse();
                if(dataSort !== null){
                    setData(dataSort);
                }
            }).catch((error)=>{
            if (error.response) {
                message.error(error.response.data.message);
            }
        });
    };

    useEffect(() => {
        locationState?.update&&setUpdate();
        locationState?.delete&&setDelete();
    },[locationState])

    useEffect(() => {
        refreshToken().then((_idToken)=>{getDataAPI(_idToken)});
        // console.log(history?.location?.state);
        setLocationState(history?.location?.state);
    }, [history.location]);

    const onRow = (rowData, rowIndex) => {
        // const currentData = rowData;
          return {
              onClick: (event) => {
                  //  rowData: row의 data
                  //  rowIndex: row의 인덱스
                  //  event: event prototype
                  setListId(rowData?.id);
                  history.push({
                    pathname: `/inquiry/detail/${rowData.id}`,
                    // pathname: '/inquiry/detail/',
                    state: { rowData }
                });
            }
        }
    }

return(
    <>
        { data == null ? <LoadingSpin /> :
            <div>
                <div className="List" style={{ maxWidth:"1200px", margin:"24px auto 0", padding:"0 24px 24px"}}>
                    <Space align="end" size="small" direction="vertical">
                        <Table
                            tableLayout="fixed"
                            // loading={ loadingSpin === true ? true : false }
                            expandRowByClick={true}
                            columns={columns}
                            dataSource={data}
                            index={data.id}
                            onRow={onRow}
                            rowKey={data.id}
                            pagination={{
                                pageSize:12
                            }}
                        />
                    </Space>
                </div>
            </div>
        }
    </>
  );
}
