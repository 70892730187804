import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, Route, Switch } from "react-router-dom";
import axios from "axios";
import { Space, Button, Input, Table, Tag  } from 'antd';
import UserStatus from "../components/user-manage/UserStatus";
import '../assets/styles/manage.css';

export default function UserManage(props) {
    const {userObj, setUserObj} = props;
    return(
        <>
            <Switch>
                <Route path="/user/">
                    <UserStatus userObj={userObj} />
                </Route>
            </Switch>
        </>
    );
}
